import "./App.css";
import React, { useState, useContext, useEffect, useMemo } from "react";
import { AppContext } from "./components/AppContext";
import {
  MapLibreMap,
  MlNavigationTools,
  MlLayer,
  useMap,
} from "@mapcomponents/react-maplibre";
import getBackgroundStyle from "./lib/osmbright";
import Sidepane from "./components/Sidepane";
import DataForm from "./components/DataForm";
import { useSelector } from "react-redux";
import Lose from "./components/Lose";
import Navigation from "./components/Navigation";
import Button from "@mui/material/Button";
import Landmarken from "./components/Baumplot/Landmarken";
import SachdatenLayer from "./components/SachdatenLayer";
import EingabeFormularBeenden from "./components/logischePruefungFormular/Alerts/EingabeFormularBeenden";
import Baumplot1Alert from "./components/logischePruefungFormular/Alerts/Baumplot1Alert";
import Baumplot2Alert from "./components/logischePruefungFormular/Alerts/Baumplot2Alert";
import SPAufsucheAlert from "./components/logischePruefungFormular/Alerts/SPAufsucheAlert";
import LoadingLoseAlert from "./components/logischePruefungFormular/Alerts/LoadingLoseAlert";
import WeiserpflanzenAlert from "./components/logischePruefungFormular/Alerts/WeiserpflanzenAlert";
import VerjuengungstransektAlert from "./components/logischePruefungFormular/Alerts/VerjuengungstransektAlert";
import LoadingIndicator from "./components/LoadingIndicator";
import { AuthContext } from "./components/AuthContext";
import Login from "./components/Login";
import Baumplot from "./components/Baumplot/BaumplotCurrent";
import Baumplot2 from "./components/Baumplot/Baumplot2Current";
import { MlGeoJsonLayer } from "@mapcomponents/react-maplibre";
import MlSymbolLayer from "./components/Baumplot/MlSymbolLayer";
import GNSSHandler from "./components/GNSSHandler";
import {
  checkIfEntryExists,
  saveLose,
  checkUploadedData,
  saveTimestamp,
  getTimestamp,
} from "./swCommunication";
import * as turf from "@turf/turf";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { SW_INIT, SW_UPDATE } from "./type";
import Changelog from "./components/Hilfen/Changelog";

import "maplibre-gl/dist/maplibre-gl.css";
import GPSHelp from "./components/Hilfen/GPSHelp";
import LocationSwitchHelp from "./components/Hilfen/LocationSwitchHelp";
import BearbeitungsstandSPsHelp from "./components/Hilfen/BearbeitungsstandSPsHelp";
import PWAAktualisierenHelp from "./components/Hilfen/PWAAktualisierenHelp";

let jwtToken = "";
const markerLayers = [
  "spCurrentData",
  "spData",
  "spNR",
  "lose",
  "rettungspunkteNR",
  "rettungspunkte",
  "rettungswege",
  "kreise",
  "waldflaechen",
];

var backendUrl = "/backend";
if (
  window.location.host === "localhost:3000" ||
  window.location.host === "localhost:8080"
) {
  backendUrl = "https://lfb-dev.wheregroup.com/backend";
}

const channel = new BroadcastChannel("sw-messages");

function App() {
  const appContext = useContext(AppContext);
  const authContext = useContext(AuthContext);
  const mapHook = useMap({ mapId: "map_1" });
  const [dataFormToggle, setDataFormToggle] = useState(false);
  const [alertClose, setHandleAlertClose] = useState(true);
  const [spData, setSpData] = useState(undefined); // Daten Baumplott aus Vorgängerinventur
  const [baumplotData, setBaumplotData] = useState(undefined); // Daten Baumplot
  const [baumplot2Data, setBaumplot2Data] = useState(undefined); // Daten Baumplot 2
  const [lmData, setLmData] = useState(undefined); // Daten Landmarken aus Vorgängerinventur
  const [loseinDB, setLoseinDB] = useState(false);
  const [loadingLose, setLoadingLose] = useState(false);

  useEffect(() => {
    var obj;
    var lose;

    getTimestamp();
    if (authContext.authenticated) {
      setLoadingLose(true);

      fetch(backendUrl + "/api/entities/vlos/getall/2022", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authContext.jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => (obj = data))
        .then(() => (lose = obj.data[0]))
        .then(() => console.log(obj.data[0]))
        //.then(() => appContext.setLose(obj.data[0]))
        .then(() => {
          if (typeof lose !== "undefined" && lose !== "") {
            //saveLose({ data: obj.data[0] });
            console.log("here");
            saveLose({ data: lose }); // save Lose in DB
            appContext.setLose(lose);
            setLoseinDB(!loseinDB);
          }
          setLoseinDB(!loseinDB);
        });
    }
  }, [authContext.authenticated]);

  // listens to sw messages on the lose channel
  channel.onmessage = function (event) {
    //console.log("lose channel");
    //console.log(event);
    if (typeof event.data !== "undefined" && event.data !== "") {
      if (event.data?.timestamp && event.data.timestamp !== "") {
        appContext.setTimestamp(event.data.timestamp);
      } else {
        let currentTimestamp = Math.round(Date.now() / 1000);
        appContext.setTimestamp(currentTimestamp);
        saveTimestamp(currentTimestamp);
      }
    }
  };

  useEffect(() => {
    checkUploadedData();
  }, [appContext.currentSP]);
  const [checkTimeStamp, setCheckTimeStamp] = useState(true);
  const [updateTimeStamp, setUpdateTimeStamp] = useState(false);

  useEffect(() => {
    if (appContext.refreshSPs) {
      fetch(
        backendUrl +
          "/api/entities/vlos/getall/2022/" +
          (appContext.timestamp ? appContext.timestamp : ""),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authContext.jwtToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Test");
          console.log(data);
          appContext.setTimeStampedLose(data);
          setUpdateTimeStamp(true);
        });
      setCheckTimeStamp(true);
    }
    appContext.setRefreshSPs(false);
  }, [appContext.refreshSPs, appContext.timestamp]);

  useEffect(() => {
    if (updateTimeStamp) {
      let newTimestamp = Math.round(Date.now() / 1000);
      saveTimestamp(newTimestamp);
      appContext.setTimestamp(newTimestamp);
    }
    setUpdateTimeStamp(false);
  }, [updateTimeStamp]);

  useEffect(() => {
    if (
      typeof appContext.timeStampedLose !== "undefined" &&
      typeof appContext.lose !== "undefined" &&
      appContext.timeStampedLose.length > 0 &&
      checkTimeStamp
    ) {
      var loseCopy = JSON.parse(JSON.stringify(appContext.lose));
      appContext.lose.features.forEach((sp, index) => {
        appContext.timeStampedLose.data[0].features.forEach((spTimed) => {
          if (
            sp.properties.sp_schluessel === spTimed.properties.sp_schluessel
          ) {
            loseCopy.features[index] = spTimed;
            console.log(index);
          }
        });
      });
      setCheckTimeStamp(false);
      appContext.setLose(loseCopy);
    }
  }, [appContext.lose, appContext.timeStampedLose]);

  useEffect(() => {
    if (!appContext.spInProgress) {
      appContext.setCurrentSP(appContext.geom);
    }
  }, [appContext.spClicked, appContext.geom]);

  const handleDataFormToggle = () => {
    setDataFormToggle(!dataFormToggle);
  };

  const openDataForm = () => {
    setDataFormToggle(true);
  };

  const handleAlertClose = () => {
    setHandleAlertClose(!alertClose);
  };

  useEffect(() => {
    setSpData(appContext.baumplottData);
  }, [appContext.baumplottData]);

  useEffect(() => {
    setBaumplotData(appContext.currentBaumplot);
  }, [appContext.currentBaumplot]);

  useEffect(() => {
    setBaumplot2Data(appContext.currentBaumplot2);
  }, [appContext.currentBaumplot2]);

  useEffect(() => {
    if (typeof appContext.currentSP !== "undefined") {
      mapHook.map.flyTo({
        center: appContext.currentSP.geometry.coordinates,
        zoom: 16,
        speed: 1,
      });
      /* mapHook.map.once("idle", () => {
        mapHook.map.map.fire("click");
      });*/
    }
  }, [appContext.currentSP, mapHook.map]);

  const centerStylePushAlert = {
    flexWrap: "wrap",
    display: "flex",
    flexDirection: "row wrap",
    margin: "0",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: "10000",
    border: "1px solid black",
    width: "30%",
  };

  const centerStylePushAlertDiv = {
    flexWrap: "wrap",
    display: alertClose ? "flex" : "none",
    flexDirection: "row wrap",
    margin: "0",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    bottom: "10px",
    zIndex: "10000",
  };

  const centerStyleDataFormAlertDiv = {
    flexWrap: "wrap",
    display: "flex",
    flexDirection: "row wrap",
    margin: "0",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    bottom: "40%",
    zIndex: "10000",
  };

  //ServiceWorker Notification
  const isServiceWorkerInitialized = useSelector(
    (state) => state.serviceWorkerInitialized
  );

  const serviceWorkerRegistration = useSelector(
    (state) => state.serviceWorkerRegistration
  );


  useEffect(() => {
    if (appContext.updateSW) {
      const registrationWaiting = serviceWorkerRegistration.waiting;

      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: "SKIP_WAITING" });

        registrationWaiting.addEventListener("statechange", (e) => {
          if (e.target.state === "activated") {
            window.location.reload();
          }
        });
      }
    }
  }, [appContext.updateSW]);

  useEffect(() => {
    authContext.getJwtTokenFromServiceWorker();
  }, [isServiceWorkerInitialized]);

  useEffect(() => {
    if (authContext.authenticated) {
      authContext.getLoseFromServiceWorker();
    }
  }, [authContext.authenticated]);

  const [line, setLine] = useState(undefined);
  const [circle, setCircle] = useState(undefined);

  useEffect(() => {
    console.log(appContext.gnssAccuracy);
    if (typeof appContext.gnssLocation !== "undefined") {
      let radius = appContext.gnssAccuracy / 1000;
      console.log(radius);
      let options = { steps: 50, units: "kilometers", color: "green" };
      setCircle(
        turf.circle(
          appContext.gnssLocation.geometry.coordinates,
          radius,
          options
        )
      );
    }
  }, [appContext.gnssLocation]);

  useEffect(() => {
    if (typeof circle !== "undefined") {
      setLine(turf.lineString(...circle.geometry.coordinates));
    }
  }, [circle]);

  /*let initZoom = false;

  useEffect(() => {
    if(typeof appContext.gnssLocation !== "undefined") {
    if(!initZoom) {
    mapHook.map.flyTo({
      center: appContext.gnssLocation.geometry.coordinates,
      zoom: 18,
      speed: 1,
      curve: 1,
    });
    initZoom = true;
  }}
  }, [appContext.gnssLocation, appContext.openGnssPort])*/

  return (
    <>
      <div>
        {(!isServiceWorkerInitialized || authContext.isLoading) && (
          <LoadingIndicator></LoadingIndicator>
        )}
        {(isServiceWorkerInitialized || authContext.isLoading) &&
          !authContext.authenticated && <Login></Login>}
        <Lose></Lose>

        {navigator.serial && <GNSSHandler></GNSSHandler>}
        <MapLibreMap
          mapId="map_1"
          sourceOptions={{
            maxzoom: 14,
            minzoom: 8,
          }}
          options={{
            zoom: 8,
            style: getBackgroundStyle(),
            center: [13.016454869317856, 52.459852681821864],
          }}
        />
        {markerLayers.map((layer, idx) => (
          <MlLayer
            layerId={"marker-" + layer}
            options={{
              display: "none",
            }}
            key={layer}
            {...(idx > 0
              ? {
                  insertBeforeLayer: "marker-" + markerLayers[idx - 1],
                }
              : undefined)}
          />
        ))}
        {typeof appContext.gnssLocation !== "undefined" && (
          <MlGeoJsonLayer
            geojson={appContext.gnssLocation}
            type="circle"
            paint={{
              "circle-radius": 4,
              "circle-color": "blue",
              "circle-stroke-color": "white",
              "circle-stroke-width": 1,
            }}
          />
        )}
        {typeof appContext.gnssLocation !== "undefined" && (
          <MlSymbolLayer
            mapId="map_1"
            options={{
              type: "symbol",
            }}
            geojson={appContext.gnssLocation}
            type="symbol"
            layout={{
              "text-field": "GNSS - Position",
              "text-variable-anchor": ["top", "bottom", "left", "right"],
              "text-radial-offset": 1,
              "text-size": 12,
              "text-justify": "left",
              "text-font": ["Open Sans Regular"],
            }}
          />
        )}

        {typeof appContext.gnssLocation !== "undefined" &&
          typeof circle !== "undefined" && (
            <MlGeoJsonLayer
              mapId="map_1"
              geojson={circle}
              type="fill"
              paint={{
                "fill-color": "green",
                "fill-opacity": 0.2,
              }}
              layerId="centerMarkerRadius"
            />
          )}
        {typeof line !== "undefined" &&
          typeof appContext.gnssLocation !== "undefined" && (
            <MlGeoJsonLayer
              mapId="map_1"
              geojson={line}
              type="line"
              paint={{
                "line-color": "red",
                "line-width": 2,
              }}
              layerId="centerMarkerRadiusBorder"
            />
          )}
        <SachdatenLayer />
        {appContext.currentSP && (
          <Button
            onClick={() => {
              openDataForm();
              appContext.setNextTabIndex(1);
              appContext.setCurrentTab("SP-Aufsuche");
              var checkExisting = {
                type: "GET_ENTRY_FROM_DB",
                id: appContext.currentSP.properties.los_id,
              };

              checkIfEntryExists(checkExisting);
              appContext.setStartEntry(!appContext.startEntry);
              appContext.setSPinProgress(true);

              var loseCopy = JSON.parse(JSON.stringify(appContext.lose));
              appContext.lose.features.forEach((sp, index) => {
                if (
                  sp.properties.sp_schluessel ===
                  appContext.currentSP.properties.sp_schluessel
                ) {
                  // Worklfow kommt künftig korrekt von DB, diese Änderung dann löschen
                  loseCopy.features[index].properties.workflow = 1337;
                }
              });
              saveLose({ data: loseCopy }); // save Lose in DB
              //setLoseinDB(!loseinDB);
              appContext.setLose(loseCopy);
            }}
            style={{
              display: appContext.spInProgress ? "none" : "block",
              height: "40px",
              zIndex: 1011,
              position: "absolute",
              top: "0",
              right: "0",
              marginTop: "5px",
              marginRight: "5px",
              backgroundColor: "#00613A",
            }}
            variant="contained"
            size="small"
          >
            Eingabe SP {appContext.currentSP.properties.sp_schluessel} starten
          </Button>
        )}
        <Sidepane />
        {/* {spData && <Baumplot geojson={spData} />} */}
        {baumplotData && <Baumplot geojson={baumplotData} />}
        {baumplot2Data && <Baumplot2 geojson={baumplot2Data} />}
        {dataFormToggle && <DataForm handleToggle={handleDataFormToggle} />}
        <Navigation />
        <MlNavigationTools mapId="map_1" />
        <div style={centerStylePushAlertDiv}>
          {/* {isServiceWorkerInitialized && (
            <Alert
              severity="success"
              type={SW_INIT}
              style={centerStylePushAlert}
            >
              <AlertTitle>Erfolg</AlertTitle>
              <strong>Die Anwendung funktioniert nun offline.</strong>
              <IconButton
                style={{ position: "absolute", right: "0", top: "0" }}
                onClick={handleAlertClose}
                aria-label="delete"
              >
                <CancelIcon />
              </IconButton>
            </Alert>
          )} */}
          {/*isServiceWorkerUpdated && (
            <Alert
              severity="warning"
              type={SW_UPDATE}
              style={centerStylePushAlert}
            >
              <AlertTitle>Info</AlertTitle>
              <strong>
                Eine neue Version ist verfügbar. Versichern Sie sich, dass Sie
                eine gute Internetverbindung haben, bevor Sie updaten.
              </strong>
              <Button
                style={{
                  color: "red",
                  outline: "0.5px solid black",
                  marginLeft: "10px",
                }}
                onClick={updateServiceWorker}
              >
                Update
              </Button>
              <IconButton
                style={{ position: "absolute", right: "0", top: "0" }}
                onClick={handleAlertClose}
                aria-label="delete"
              >
                <CancelIcon />
              </IconButton>
            </Alert>
          )*/}
        </div>
        <div style={centerStyleDataFormAlertDiv}>
          {appContext.formularBeenden && (
            <EingabeFormularBeenden
              style={centerStylePushAlert}
            ></EingabeFormularBeenden>
          )}
          {appContext.showBaumplot1Alert && (
            <Baumplot1Alert style={centerStylePushAlert}></Baumplot1Alert>
          )}
          {appContext.showBaumplot2Alert && (
            <Baumplot2Alert style={centerStylePushAlert}></Baumplot2Alert>
          )}

          {appContext.showSPAufsucheAlert && (
            <SPAufsucheAlert></SPAufsucheAlert>
          )}

          {appContext.showVerjuengungstransekAlert && (
            <VerjuengungstransektAlert></VerjuengungstransektAlert>
          )}
          {appContext.showWeiserpflanzenAlert && (
            <WeiserpflanzenAlert></WeiserpflanzenAlert>
          )}
          {appContext.loadingLose && <LoadingLoseAlert></LoadingLoseAlert>}
        </div>
        {appContext.showChangelog && <Changelog></Changelog>}
        {appContext.showGPSHelp && <GPSHelp></GPSHelp>}
        {appContext.showLocationSwitch && (
          <LocationSwitchHelp></LocationSwitchHelp>
        )}
        {appContext.showBearbeitungsstandSPsHelp && (
          <BearbeitungsstandSPsHelp></BearbeitungsstandSPsHelp>
        )}
        {appContext.showPWAAktualisierenHelp && (
          <PWAAktualisierenHelp></PWAAktualisierenHelp>
        )}
      </div>
    </>
  );
}

export default App;

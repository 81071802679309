/*
Logische Prüfungen im Formular
*/
import React, { useContext, useEffect } from "react";
import { AppContext } from "../AppContext";

const DataFormCheck = ({ checkData, einmessung }) => {
  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext.setBaumplot1Check(false);
    appContext.setBaumplot2Check(false);
    appContext.setBaumplot1RowCountCheck(true);
    appContext.setBaumplot2RowCountCheck(true);
    appContext.setShowBaumplot1Alert(false);
    appContext.setShowBaumplot2Alert(false);
    appContext.setBaumplotLessThanFive(false);
    appContext.setBaumplotLessThanFive2(false);
    appContext.setBaumplot1ZeroRowsCheck(true);
    appContext.setBaumplot2ZeroRowsCheck(true);
    appContext.setBaumplot1ZeroRows(false);
    appContext.setBaumplot2ZeroRows(false);
    appContext.setBestandsbeschreibungCheck("undefined");
    appContext.setBestockungCheck("undefined");
    appContext.setSchichtOMU("undefined");
    appContext.setSchichtEin("undefined");
    appContext.setSchichtZwei("undefined");
    appContext.setSchichtVerjuengung("undefined");
    appContext.setSchichtZweiU("undefined");
    appContext.setSchichtPlenter("undefined");
    appContext.setSchichtZweiUN("undefined");
    appContext.setTransektinfoCheck("undefined");
    appContext.setBestockungZeilenCheck(false);
    appContext.setShowBestockungAlert(false);
  }, [appContext.currentSP]);

  // Logische Prüfung Baumplot 1
  if (
    appContext.currentSubTab === "BAUMPLOT 1"
    //appContext.currentSubTab === ""
  ) {
    let tempPlotCheck = false;
    //let tempPlotCheck2 = false;
    let tempRowAmount = 0;
    if (typeof checkData.rows !== "undefined" && checkData.rows !== "") {
      checkData.rows.forEach(function (row) {
        if (
          row.distanz !== "" &&
          row.azimut !== "" &&
          row.baumart !== "" &&
          row.bhd !== "" &&
          isNaN(row.distanz) === false &&
          isNaN(row.azimut) === false &&
          isNaN(row.bhd) === false
        ) {
          tempRowAmount++;
          tempPlotCheck = true;
        } else {
          tempPlotCheck = false;
        }
      });
      if (tempRowAmount >= 5) {
        //console.log("tempRowAmount >= 5");
        // if (tempPlotCheck === true) {
        //   appContext.setBaumplot1Check(true);
        // }
        appContext.setBaumplot1RowCountCheck(false);
        appContext.setBaumplot1ZeroRowsCheck(false);
      }
      if (tempRowAmount < 5) {
        //console.log("tempRowAmount < 5");
        appContext.setBaumplot1RowCountCheck(true);
        appContext.setBaumplot1ZeroRowsCheck(false);
      }
      if (tempRowAmount === 0) {
        //console.log("tempRowAmount = 0");
        appContext.setBaumplot1ZeroRowsCheck(true);
      }
      if (
        checkData.azimutText !== "" &&
        typeof checkData.azimutText !== "undefined" &&
        isNaN(checkData.azimutText) === false &&
        tempPlotCheck === true &&
        tempRowAmount >= 5
      ) {
        appContext.setBaumplot1Check(true);
        appContext.setBaumplot1RowCountCheck(false);
      }
      if (
        checkData.azimutText !== "" &&
        typeof checkData.azimutText !== "undefined" &&
        appContext.baumplotLessThanFive === true
      ) {
        let temp = false;
        appContext.setBaumplot1ZeroRowsCheck(false);
        checkData.rows.forEach(function (row) {
          if (
            isNaN(row.distanz) === false ||
            isNaN(row.azimut) === false ||
            row.baumart !== "" ||
            isNaN(row.bhd) === false
          ) {
            if (
              row.distanz !== "" &&
              row.azimut !== "" &&
              row.baumart !== "" &&
              row.bhd !== "" &&
              isNaN(row.distanz) === false &&
              isNaN(row.azimut) === false &&
              isNaN(row.bhd) === false
            ) {
              temp = true;
            } else {
              temp = false;
            }
          }
        });
        if (temp === true) {
          appContext.setBaumplot1Check(true);
        } else {
          appContext.setBaumplot1Check(false);
        }
      }
      if (
        checkData.azimutText !== "" &&
        typeof checkData.azimutText !== "undefined" &&
        appContext.baumplot1ZeroRows === true
      ) {
        appContext.setBaumplot1Check(true);
      }
    }
  }

  // logische Prüfung Baumplot 2
  if (appContext.currentSubTab === "BAUMPLOT 2") {
    let tempRowAmount = 0;
    let tempPlotCheck = false;
    if (typeof checkData.rows !== "undefined" && checkData.rows !== "") {
      checkData.rows.forEach(function (row) {
        if (
          row.distanz !== "" &&
          row.azimut !== "" &&
          row.baumart !== "" &&
          row.bhd !== "" &&
          row.distanz.isNaN !== true &&
          row.azimut.isNaN !== true &&
          row.bhd.isNaN !== true
        ) {
          tempPlotCheck = true;
          tempRowAmount++;
        } else {
          tempPlotCheck = false;
          appContext.setBaumplot2Check(false);
        }
      });
      if (tempRowAmount >= 5) {
        //console.log("tempRowAmount >= 5");
        appContext.setBaumplot2Check(true);
        appContext.setBaumplot2RowCountCheck(false);
        appContext.setBaumplot2ZeroRowsCheck(false);
      } else {
        appContext.setBaumplot2RowCountCheck(true);
        appContext.setBaumplot2ZeroRowsCheck(false);
      }
      if (tempRowAmount === 0) {
        appContext.setBaumplot2ZeroRowsCheck(true);
      }
      if (appContext.baumplotLessThanFive2 === true) {
        let temp = false;
        appContext.setBaumplot2ZeroRowsCheck(false);
        checkData.rows.forEach(function (row) {
          if (
            isNaN(row.distanz) === false ||
            isNaN(row.azimut) === false ||
            row.baumart !== "" ||
            isNaN(row.bhd) === false
          ) {
            if (
              row.distanz !== "" &&
              row.azimut !== "" &&
              row.baumart !== "" &&
              row.bhd !== "" &&
              isNaN(row.distanz) === false &&
              isNaN(row.azimut) === false &&
              isNaN(row.bhd) === false
            ) {
              temp = true;
            } else {
              temp = false;
            }
          }
        });
        if (temp === true) {
          appContext.setBaumplot2Check(true);
        } else {
          appContext.setBaumplot2Check(false);
        }
      }

      if (appContext.baumplot2ZeroRows === true) {
        appContext.setBaumplot2Check(true);
      }
    }
  }

  // logische Prüfung SP-Aufsuche
  if (appContext.currentTab === "SP-AUFSUCHE") {
    let temp;
    if (
      checkData.spAufsucheAufnahmetruppKuerzel !== "" &&
      checkData.spAufsucheGNSS !== ""
    ) {
      temp = true;
    }
    // console.log("Verschiebung: " + checkData.spVerschieben);
    if (
      // typeof einmessung !== "undefined" &&
      // einmessung[0] !== 0 &&
      // einmessung[1] !== 0 &&
      temp === true
    ) {
      appContext.setSpAufsucheCheck(true);
    } else {
      appContext.setSpAufsucheCheck(false);
    }
  }

  // logische Prüfung Verjüngungstransekt
  if (appContext.currentSubTab === "VERJÜNGUNGSTRANSEKT") {
    if (typeof checkData !== "undefined" && checkData !== "") {
      console.log(checkData);
      //console.log("Verjüngungstransekt wird überprüft");
      if (
        typeof checkData !== "undefined" &&
        checkData !== "" &&
        typeof checkData.transektVerjuengung !== "undefined"
      ) {
        let tempCheck1 = false;
        let tempCheck2 = false;
        let tempRowAmount = 0;
        checkData.transektVerjuengung.forEach(function (entry) {
          if (
            entry.verjuengungstransektBaumart !== "" &&
            typeof entry.verjuengungstransektBaumart !== "undefined" &&
            entry.verjuengungstransektHoehenstufe !== "" &&
            typeof entry.verjuengungstransektHoehenstufe !== "undefined"
          ) {
            console.log("temp Check 1 true");
            tempCheck1 = true;
            appContext.setVerjuengungstransekCheck(true);
            tempRowAmount++;
          } else {
            tempCheck1 = false;
            appContext.setVerjuengungstransekCheck(false);
            console.log("temp Check 1 false");
          }
          //console.log("Höhenstufe: " + entry.verjuengungstransektHoehenstufe);
          if (entry.verjuengungstransektHoehenstufe === "über 200") {
            console.log("BHD: " + entry.verjuengungstransektBHD);
            if (
              entry.verjuengungstransektBHD !== "" &&
              typeof entry.verjuengungstransektBHD !== "undefined"
            ) {
              tempCheck2 = true;
              console.log("temCheck2 true");
              appContext.setVerjuengungstransekCheck(true);
            } else {
              tempCheck2 = false;
              console.log("temCheck2 false");
              appContext.setVerjuengungstransekCheck(false);
            }
          }
        });
        //console.log(tempRowAmount);
        if (tempRowAmount === 0) {
          appContext.setVerjuengungstransekZeroRowCheck(true);
        } else {
          appContext.setVerjuengungstransekZeroRowCheck(false);
        }
        if (
          tempCheck1 === true &&
          tempCheck2 === true &&
          checkData.laenge !== "" &&
          typeof checkData.laenge !== "undefined"
        ) {
          console.log(checkData.laenge);
          appContext.setVerjuengungstransekCheck(true);
          appContext.setStartVerjuengungstransektCheck(false);
        }
        if (
          appContext.verjuengungstransekZeroRow === true &&
          checkData.laenge !== "" &&
          typeof checkData.laenge !== "undefined"
        ) {
          console.log(checkData.laenge);
          appContext.setVerjuengungstransekCheck(true);
          appContext.setStartVerjuengungstransektCheck(false);
        }
      }
    }
  }

  // logische Prüfung Weiserpflanzen
  if (appContext.currentSubTab === "WEISERPFLANZEN") {
    if (typeof checkData.weiserpflanzen !== "undefined" && checkData !== "") {
      let kraut =
        parseInt(checkData.weiserpflanzen.weiserpflanzenBrennessel) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenGoldnessel) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenHeidekraut) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenSpringkraut) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenMaigloeckchen) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenWeidenroeschen) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenWaldmeister) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenWaldsauerklee) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenWegerich);
      //console.log("Kraut: " + kraut);

      let gras =
        parseInt(checkData.weiserpflanzen.weiserpflanzenDrahtschmiele) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenFlatterbinse) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenHainrispengras) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenPerlgras) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenPfeifengras) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenSandrohr) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenWaldzwenke) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenWinkelsegge);
      //console.log("Gras: " + gras);

      let beerenstraeucher =
        parseInt(checkData.weiserpflanzen.weiserpflanzenHeidelbeere) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenPreiselbeere);
      //console.log("Beeren: " + beerenstraeucher);

      let großstraeucher =
        parseInt(checkData.weiserpflanzen.weiserpflanzenHimbeere) +
        parseInt(checkData.weiserpflanzen.weiserpflanzenBrombeere);
      //console.log("Groß: " + großstraeucher);

      if (
        kraut <= "100" &&
        gras <= "100" &&
        beerenstraeucher <= "100" &&
        großstraeucher <= "100"
      ) {
        appContext.setWeiserpflanzenCheck(true);
        appContext.setWeiserpflanzenKraut(false);
        appContext.setWeiserpflanzenGras(false);
        appContext.setWeiserpflanzenBeerenstraeucher(false);
        appContext.setWeiserpflanzenGrossstraeucher(false);
      }
      if (kraut > "100") {
        appContext.setWeiserpflanzenKraut(true);
        appContext.setWeiserpflanzenCheck(false);
      }
      if (gras > "100") {
        appContext.setWeiserpflanzenGras(true);
        appContext.setWeiserpflanzenCheck(false);
      }
      if (beerenstraeucher > "100") {
        appContext.setWeiserpflanzenBeerenstraeucher(true);
        appContext.setWeiserpflanzenCheck(false);
      }
      if (großstraeucher > "100") {
        appContext.setWeiserpflanzenGrossstraeucher(true);
        appContext.setWeiserpflanzenCheck(false);
      }
    }
  }

  // logische Prüfung Bestandsbeschreibungen
  if (
    appContext.currentTab === "BESTANDSBESCHREIBUNGEN"
    //appContext.currentSubTab === "ALLG. INFORMATIONEN"
  ) {
    console.log("Bestandsbeschreibungen Check");
    if (
      typeof checkData.bestandsBeschreibung !== "undefined" &&
      checkData !== ""
    ) {
      //console.log("hier");
      if (
        checkData.bestandsBeschreibung.bestandBetriebsart !== "" &&
        checkData.bestandsBeschreibung.bestandNschichtig !== "" &&
        checkData.bestandsBeschreibung.bestandBiotop !== "" &&
        checkData.bestandsBeschreibung.bestandKronenschlussgrad !== "" &&
        isNaN(
          checkData.bestandsBeschreibung.bestandBedeckungsgradUnterstand
        ) === false &&
        isNaN(checkData.bestandsBeschreibung.bestandBedeckungsgradGraeser) ===
          false &&
        checkData.bestandsBeschreibung.bestandHeterogenitaetsgrad !== ""
      ) {
        appContext.setBestandsbeschreibungCheck(true);
      } else {
        appContext.setBestandsbeschreibungCheck(false);
      }
    }
  }

  // logische Prüfung Bestockung
  if (appContext.currentSubTab === "BESTOCKUNG") {
    if (typeof checkData.bestockung !== "undefined" && checkData !== "") {
      //console.log(checkData.bestockung);

      let tempCheck1 = false;
      let tempPlenterwald = 0;
      let tempVerjuengung = 0;
      let tempLiegend = 0;
      let tempMH = 0;
      let tempUeH = 0;
      let tempAB = 0;
      let tempHauptbestand = 0;
      let tempUS = 0;
      let tempOS = 0;

      let tempOMU = "undefined";
      let tempEin = "undefined";
      let tempZwei = "undefined";
      let tempVerjüngung = "undefined";
      let tempZweiU = "undefined";
      let tempPlenter = "undefined";
      let tempZweiUN = "undefined";

      checkData.bestockung.forEach(function (row) {
        if (
          row.bestockungSchicht !== "" &&
          row.bestockungBaumart !== "" &&
          row.bestockungVerteilung !== "" &&
          row.bestockungBaumartenanteil !== "" &&
          isNaN(row.bestockungBaumartenanteil) === false &&
          row.bestockungAlter !== ""
        ) {
          appContext.setBestockungZeilenCheck(true);
          tempCheck1 = true;
          if (row.bestockungSchicht === "Plenterwald") {
            tempPlenterwald =
              parseInt(tempPlenterwald) +
              parseInt(row.bestockungBaumartenanteil);
            //console.log("Plenterwald: " + tempPlenterwald);
          }
          if (row.bestockungSchicht === "Verjüngung unter Schirm") {
            tempVerjuengung =
              parseInt(tempVerjuengung) +
              parseInt(row.bestockungBaumartenanteil);
            //console.log("Verjüngung unterm Schirm: " + tempVerjuengung);
          }
          if (row.bestockungSchicht === "liegend") {
            tempLiegend =
              parseInt(tempLiegend) + parseInt(row.bestockungBaumartenanteil);
            //console.log("liegend: " + tempLiegend);
          }
          if (row.bestockungSchicht === "MH") {
            tempMH = parseInt(tempMH) + parseInt(row.bestockungBaumartenanteil);
            //console.log("MH: " + tempMH);
          }
          if (row.bestockungSchicht === "UeH") {
            tempUeH =
              parseInt(tempUeH) + parseInt(row.bestockungBaumartenanteil);
            //console.log("UeH: " + tempUeH);
          }
          if (row.bestockungSchicht === "AB") {
            tempAB = parseInt(tempAB) + parseInt(row.bestockungBaumartenanteil);
            //console.log("AB: " + tempAB);
          }
          if (row.bestockungSchicht === "Hauptbestand") {
            tempHauptbestand =
              parseInt(tempHauptbestand) +
              parseInt(row.bestockungBaumartenanteil);
            //console.log("Hauptbestand: " + tempHauptbestand);
          }
          if (row.bestockungSchicht === "US") {
            tempUS = parseInt(tempUS) + parseInt(row.bestockungBaumartenanteil);
            //console.log("US: " + tempUS);
          }
          if (row.bestockungSchicht === "OS") {
            tempOS = parseInt(tempOS) + parseInt(row.bestockungBaumartenanteil);
            //console.log("OS: " + tempOS);
          }
        } else {
          tempCheck1 = false;
          //appContext.setBestockungCheck(false);
          //appContext.setBestockungZeilenCheck(false);
        }
      });

      // OS/HB, MH, US
      if (appContext.nschicht === "aus Ober-, Mittel- und Unterholz") {
        if (
          tempMH === 100 &&
          tempUS === 100 &&
          tempPlenterwald === 0 &&
          tempVerjuengung === 0 &&
          (tempLiegend === 0 || tempLiegend === 100) &&
          tempUeH === 0 &&
          (tempAB === 100 || tempAB === 0)
        ) {
          if (tempOS === 100) {
            if (
              (tempHauptbestand === 100 || tempHauptbestand === 0) &&
              tempCheck1 === true
            ) {
              appContext.setBestockungCheck(true);
              //console.log("OMU: true");
            }
          }
          if (tempHauptbestand === 100) {
            if ((tempOS === 100 || tempOS === 0) && tempCheck1 === true) {
              appContext.setBestockungCheck(true);
              tempOMU = true;
              //console.log("OMU: true");
            }
          }
          if (tempHauptbestand + tempOS === 100) {
            tempOMU = true;
            appContext.setBestockungCheck(true);
          }
        } else {
          tempOMU = false;
          //appContext.setBestockungCheck(false);
          //console.log("OMU: false");
        }
      }
      console.log(appContext.nschicht);
      // OS/HB
      if (appContext.nschicht === "einschichtig") {
        //console.log("Prüfung einschichtig");
        tempEin = false;
        if (
          tempMH === 0 &&
          tempUS === 0 &&
          tempPlenterwald === 0 &&
          tempVerjuengung === 0 &&
          (tempLiegend === 0 || tempLiegend === 100) &&
          tempUeH === 0 &&
          (tempAB === 100 || tempAB === 0)
        ) {
          if (tempOS === 100) {
            //console.log(tempOS);
            if (
              (tempHauptbestand === 100 || tempHauptbestand === 0) &&
              tempCheck1 === true
            ) {
              tempEin = true;
              appContext.setBestockungCheck(true);
              //console.log("tempEin: true");
            }
          }
          if (tempHauptbestand === 100) {
            if ((tempOS === 100 || tempOS === 0) && tempCheck1 === true) {
              tempEin = true;
              appContext.setBestockungCheck(true);
              //console.log("tempEin: true");
            }
          }
          if (tempHauptbestand !== 100 && tempOS !== 100) {
            tempEin = false;
            //appContext.setBestockungCheck(false);
            //console.log("tempEin: false");
          }
          if (tempHauptbestand + tempOS === 100) {
            tempEin = true;
            appContext.setBestockungCheck(true);
          }
        }
      }
      // OS/Hb, US
      if (appContext.nschicht === "zweischichtig") {
        if (
          tempUS === 100 &&
          tempMH === 0 &&
          tempPlenterwald === 0 &&
          tempVerjuengung === 0 &&
          (tempLiegend === 0 || tempLiegend === 100) &&
          tempUeH === 0 &&
          (tempAB === 100 || tempAB === 0)
        ) {
          if (tempOS === 100) {
            if (
              (tempHauptbestand === 100 || tempHauptbestand === 0) &&
              tempCheck1 === true &&
              tempCheck1 === true
            ) {
              tempZwei = true;
              appContext.setBestockungCheck(true);
              //console.log("tempEin: true");
            }
          }
          if (tempHauptbestand === 100) {
            if ((tempOS === 100 || tempOS === 0) && tempCheck1 === true) {
              tempZwei = true;
              appContext.setBestockungCheck(true);
              //console.log("tempZwei: true");
            }
          }
          if (tempHauptbestand !== 100 && tempOS !== 100) {
            tempZwei = false;
            //appContext.setBestockungCheck(false);
            //console.log("tempEin: false");
          }
          if (tempHauptbestand + tempOS === 100) {
            tempZwei = true;
            appContext.setBestockungCheck(true);
          }
        } else {
          tempZwei = false;
          //appContext.setBestockungCheck(false);
          //console.log("tempZwei: false");
        }
      }
      // OS/Hb, VuS
      if (appContext.nschicht === "zweischichtig mit Vorausverjüngung") {
        if (
          tempVerjuengung === 100 &&
          tempUS === 0 &&
          tempMH === 0 &&
          tempPlenterwald === 0 &&
          (tempLiegend === 0 || tempLiegend === 100) &&
          tempUeH === 0 &&
          (tempAB === 100 || tempAB === 0)
        ) {
          if (tempOS === 100) {
            if (
              (tempHauptbestand === 100 || tempHauptbestand === 0) &&
              tempCheck1 === true
            ) {
              tempVerjüngung = true;
              appContext.setBestockungCheck(true);
              //console.log("tempVerjüngung: true");
            }
          }
          if (tempHauptbestand === 100) {
            if ((tempOS === 100 || tempOS === 0) && tempCheck1 === true) {
              tempVerjüngung = true;
              appContext.setBestockungCheck(true);
              //console.log("tempZwei: true");
            }
          }
          if (tempHauptbestand !== 100 && tempOS !== 100) {
            tempVerjüngung = false;
            //appContext.setBestockungCheck(false);
            //console.log("tempEin: false");
          }
          if (tempHauptbestand + tempOS === 100) {
            tempVerjüngung = true;
            appContext.setBestockungCheck(true);
          }
        } else {
          tempVerjüngung = false;
          //appContext.setBestockungCheck(false);
          //console.log("tempVerjüngung: false");
        }
      }
      // OS/Hb, US
      if (appContext.nschicht === "zweischichtig mit Unterbau") {
        if (
          tempUS === 100 &&
          tempVerjuengung === 0 &&
          tempMH === 0 &&
          tempPlenterwald === 0 &&
          (tempLiegend === 0 || tempLiegend === 100) &&
          tempUeH === 0 &&
          (tempAB === 100 || tempAB === 0)
        ) {
          if (tempOS === 100) {
            if (
              (tempHauptbestand === 100 || tempHauptbestand === 0) &&
              tempCheck1 === true
            ) {
              tempZweiU = true;
              appContext.setBestockungCheck(true);
              //console.log("tempZweiU: true");
            }
          }
          if (tempHauptbestand === 100) {
            if ((tempOS === 100 || tempOS === 0) && tempCheck1 === true) {
              tempZweiU = true;
              appContext.setBestockungCheck(true);
              //console.log("tempZweiU: true");
            }
          }
          if (tempHauptbestand !== 100 && tempOS !== 100) {
            tempZweiU = false;
            //appContext.setBestockungCheck(false);
            //console.log("tempEin: false");
          }
          if (tempHauptbestand + tempOS === 100) {
            tempZweiU = true;
            appContext.setBestockungCheck(true);
          }
        } else {
          tempZweiU = false;
          //appContext.setBestockungCheck(false);
          //console.log("tempZweiU: false");
        }
      }
      // P
      if (appContext.nschicht === "mehrschichtig oder plenterartig") {
        console.log("Plenter");
        if (
          tempPlenterwald === 100 &&
          tempUS === 0 &&
          tempVerjuengung === 0 &&
          tempMH === 0 &&
          (tempLiegend === 0 || tempLiegend === 100) &&
          tempUeH === 0 &&
          (tempAB === 100 || tempAB === 0) &&
          tempCheck1 === true
        ) {
          tempPlenter = true;
          //console.log("tempPlenter: true");
          appContext.setBestockungCheck(true);
        } else {
          tempPlenter = false;
          //appContext.setBestockungCheck(false);
          //console.log("tempPlenter: false");
        }
      }
      // UeH, OS/Hb, US
      if (
        appContext.nschicht === "zweischichtig  mit Überhälter/Nachhiebsrest"
      ) {
        if (
          tempUeH === 100 &&
          tempUS === 100 &&
          tempVerjuengung === 0 &&
          tempMH === 0 &&
          tempPlenterwald === 0 &&
          (tempLiegend === 0 || tempLiegend === 100) &&
          (tempAB === 100 || tempAB === 0)
        ) {
          if (tempOS === 100) {
            if (
              (tempHauptbestand === 100 || tempHauptbestand === 0) &&
              tempCheck1 === true
            ) {
              tempZweiUN = true;
              appContext.setBestockungCheck(true);
              //console.log("tempZweiUN: true");
            }
          }
          if (tempHauptbestand === 100) {
            if ((tempOS === 100 || tempOS === 0) && tempCheck1 === true) {
              tempZweiUN = true;
              appContext.setBestockungCheck(true);
              //console.log("tempZweiUN: true");
            }
          }
          if (tempHauptbestand !== 100 && tempOS !== 100) {
            tempZweiUN = false;
            //appContext.setBestockungCheck(false);
            //console.log("tempEin: false");
          }
          if (tempHauptbestand + tempOS === 100) {
            tempZweiUN = true;
            appContext.setBestockungCheck(true);
          }
        } else {
          tempZweiUN = false;
          //appContext.setBestockungCheck(false);
          //console.log("tempZweiUN: false");
        }
      }

      appContext.setSchichtOMU(tempOMU);
      appContext.setSchichtZwei(tempZwei);
      appContext.setSchichtVerjuengung(tempVerjüngung);
      appContext.setSchichtZweiU(tempZweiU);
      appContext.setSchichtPlenter(tempPlenter);
      appContext.setSchichtZweiUN(tempZweiUN);
      appContext.setSchichtEin(tempEin);
    }
  }

  // logische Prüfung Transektinfo
  if (appContext.currentSubTab === "TRANSEKTINFO") {
    console.log("Transektinfo Check");
    if (typeof checkData.transektinfo !== "undefined" && checkData !== "") {
      console.log("hier");
      console.log(checkData.transektinfo.transektKrautanteil);
      if (isNaN(checkData.transektinfo.transektKrautanteil) === false) {
        appContext.setTransektinfoCheck(true);
        console.log("und hier");
      }
    }
  }

  return <></>;
};

export default DataFormCheck;

import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "./AppContext";
import * as turf from "@turf/turf";

import MlSymbolLayer from "./Baumplot/MlSymbolLayer";
import {
  MlGeoJsonLayer,
  MlVectorTileLayer,
  MlImageMarkerLayer,
} from "@mapcomponents/react-maplibre";
import rettungspunkte from "../images/rettungspunkt.png";

function SachdatenLayer(props) {
  const appContext = useContext(AppContext);
  const [editingInProgress, setEditingInProgress] = useState(false);


  const spnrGeojson = useMemo(() => {
    if (typeof appContext.lose === "undefined") return;
    return turf.featureCollection(
      appContext.lose.features.map((el) => {
        el.properties.sp_info = el.properties.sp_schluessel;

        return el;
      })
    );
  }, [appContext.lose]);

  const rpnrGeojson = useMemo(() => {
    if (!appContext.rettungspunkte) return;
    return turf.featureCollection(
      appContext.rettungspunkte.features.map((el) => {
        el.properties.rp_info = "RPNr: " + el.properties.rpnr;

        return el;
      })
    );
  }, [appContext.rettungspunkte]);

  return (
    <>
      <MlVectorTileLayer
        mapId="map_1"
        layerId="lq-lfb-data"
        url={window.location.origin + "/lfb_data/{z}/{x}/{y}.pbf"}
        sourceOptions={{
          minzoom: 7,
          maxzoom: 14,
        }}
        layers={{
          g_lkrs_brb: {
            type: "line",
            "source-layer": "g_lkrs_brb", //kreise
            paint: {
              "line-color": "#00613A",
            },
            layout: {
              //   visibility: LowDetail ? "visible" : "none",
            },
            minzoom: 7,
          },

          // rettungswege_brb: {
          //   "source-layer": "rettungswege_brb",
          //   type: "line",
          //   layout: {
          //     "line-join": "round",
          //     "line-cap": "round",
          //   },
          //   paint: {
          //     "line-color": "orange",
          //   },
          //   minzoom: 9,
          // },

          // Waldflaechen
          g_wld: {
            "source-layer": "g_wld",
            type: "fill",
            layout: {},
            paint: {
              "fill-color": "#9db59d",
              "fill-opacity": 0.2,
            },
            minzoom: 7,
          },
        }}
      />
      {appContext.rettungswege && (
        <MlGeoJsonLayer
          options={{
            minzoom: 12,
            //maxzoom: 14,
          }}
          type="line"
          geojson={appContext.rettungswege}
          layerId="rettungswege"
          paint={{
            "line-color": "orange",
          }}
          insertBeforeLayer={"marker-rettungswege"}
        />
      )}

      {rpnrGeojson && (
        <MlSymbolLayer
          mapId="map_1"
          options={{
            type: "symbol",
            minzoom: 11,
            //maxzoom: 12,
          }}
          geojson={rpnrGeojson}
          type="symbol"
          layout={{
            "text-field": ["get", "rp_info"],
            "text-variable-anchor": ["top", "bottom", "left", "right"],
            "text-radial-offset": 0.01,
            "text-size": 13,
            "text-justify": "left",
            "text-font": ["Open Sans Regular"],
          }}
          insertBeforeLayer={"marker-rettungspunkteNR"}
        />
      )}

      {spnrGeojson && (
        <MlSymbolLayer
          mapId="map_1"
          options={{
            type: "symbol",
            minzoom: 12,
            //maxzoom: 12,
          }}
          geojson={spnrGeojson}
          type="symbol"
          layout={{
            "text-field": ["get", "sp_info"],
            "text-variable-anchor": ["top", "bottom", "left", "right"],
            "text-radial-offset": 1,
            "text-size": 12,
            "text-justify": "left",
            "text-font": ["Open Sans Regular"],
          }}
          insertBeforeLayer={"marker-spNR"}
        />
      )}

      {appContext.rettungspunkte && (
        <MlImageMarkerLayer
          mapId="map_1"
          options={{
            type: "symbol",
            minzoom: 11,
            //maxzoom: 12,
            source: {
              type: "geojson",
              // geojson: appContext.rettungspunkte,
              data: appContext.rettungspunkte,
              // type: "Feature",
              // properties: {
              //   id: "test",
              // },
              // geometry: {
              //   type: "Point",
              //   coordinates: [13.016454869317856, 52.459852681821864],
              // },
            },
            // sourceOptions: {
            //   minzoom: 12,
            //   maxzoom: 20,
            // },
            layout: {
              "icon-allow-overlap": true,
              "icon-size": 0.14,
              "icon-offset": [0, -180],
            },
          }}
          imgSrc={rettungspunkte}
          insertBeforeLayer={"marker-rettungspunkte"}
        />
      )}
      {/* {appContext.kreise && (
        <MlGeoJsonLayer
          type="line"
          geojson={appContext.kreise}
          layerId="kreise"
          paint={{
            "line-color": "red",
          }}
          insertBeforeLayer={"marker-kreise"}
        />
      )} */}
      {/* {appContext.waldflaechen && (
        <MlGeoJsonLayer
          type="fill"
          geojson={appContext.waldflaechen}
          layerId="waldflaechen"
          paint={{
            "fill-color": "#9db59d",
            "fill-opacity": 0.2,
          }}
          insertBeforeLayer={"marker-waldflaechen"}
        />
      )}  */}
      {appContext.lose && (
        <MlGeoJsonLayer
          mapId="map_1"
          type="circle"
          geojson={appContext.lose}
          onClick={(e) => {
            if (
              e.features[0].properties.workflow !== 6 &&
              e.features[0].properties.workflow !== 5
            ) {
              appContext.setGeom(e.features[0]);
              appContext.setSPClicked(!appContext.spClicked);
            }
          }}
          layerId="lose"
          paint={{
            "circle-color": [
              "match",
              ["get", "workflow"],
              4,
              "red",
              5,
              "grey",
              6,
              "grey",
              //1337 sind SPs in Bearbeitung
              1337,
              "yellow",
              "red",
            ],
            "circle-radius": [
              "match",
              ["get", "workflow"],
              4,
              4,
              5,
              6,
              6,
              6,
              //1337 sind SPs in Bearbeitung
              1337,
              6,
              4,
            ],
          }}
          insertBeforeLayer={"marker-lose"}
        />
      )}
      {appContext.currentSP && (
        <MlGeoJsonLayer
          type="circle"
          geojson={appContext.currentSP}
          layerId="zoomToSP"
          //onClick={(e) => console.log(e.features)}
          paint={{
            "circle-color": "#3bed00",
            "circle-radius": 6,
            "circle-stroke-width": 2,
            "circle-stroke-color": "red",
          }}
          insertBeforeLayer={"marker-lose"}
        />
      )}
    </>
  );
}

export default SachdatenLayer;

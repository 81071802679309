/*
Loading Lose Alert
*/
import React, { useContext, useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../AppContext";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

function LoadingLoseAlert() {
  const appContext = useContext(AppContext);
  const [lessThanFive, setLessThanFive] = useState(false);

  return (
    <>
      <Alert
        severity="warning"
        style={{
          flexWrap: "wrap",
          flexDirection: "row wrap",
          //margin: "0",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          bottom: "40%",
          zIndex: "505",
        }}
      >
        <AlertTitle>Ihre Lose werden geladen.</AlertTitle>
        <br></br>
      </Alert>
    </>
  );
}
export default LoadingLoseAlert;

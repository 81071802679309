import React, { useContext, useEffect, useState } from "react";
import {
  TableRow,
  TableCell,
  Autocomplete,
  TextField,
  Checkbox,
} from "@mui/material";
import { saveEntryToIDB } from "../swCommunication";
import { AppContext } from "./AppContext";

const BaumplotTableRow = ({
  row,
  i,
  setRows,
  baumartArray,
  rows,
  saveObject,
}) => {
  const appContext = useContext(AppContext);
  const [save, setSave] = useState(false);
  const [aziValue, setAziValue] = useState("");

  console.log(baumartArray)
  const saveRowStateToIDB = () => {
    saveEntryToIDB(saveObject, rows, appContext.currentSP);
  };

  useEffect(() => {
    saveRowStateToIDB();
  }, [save]);

  return (
    <TableRow
      key={i.toString() + "rows" + saveObject}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell align="right">{row.nr}</TableCell>
      <TableCell align="left">
        <Autocomplete
          key={i.toString() + "BaumartP1"}
          onFocus={() => {
            document.activeElement.blur();
          }}
          onChange={(event, value) => {
            let rowsCopy = [...rows];
            if (value !== null) {
              rowsCopy[row.nr - 1] = {
                ...rows[row.nr - 1],
                baumartID: value.id,
                baumart: value.label,
              };
            } else {
              rowsCopy[row.nr - 1] = {
                ...rows[row.nr - 1],
                baumartID: "",
                baumart: "",
              };
            }
            setRows(rowsCopy);

            setSave(!save);
          }}
          value={
            baumartArray.find((element) => element.id === row.baumartID) ?? null
          }
          disablePortal
          options={baumartArray}
          getOptionLabel={(option) => option.label || ""}
          sx={{
            width: "100%",
            "& button[title='Clear']": {
              visibility: "visible",
            },
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            );
          }}
          renderInput={(params) => <TextField {...params} label="Baumart" />}
        />
      </TableCell>
      <TableCell align="center">
        <TextField
          key={i.toString() + "Azimut" + saveObject}
          label="Azimut in Gon"
          variant="filled"
          type="number"
          InputProps={{
            inputProps: {
              max: 400,
              min: 0,
            },
          }}
          value={row.azimut}
          onBlur={saveRowStateToIDB}
          onChange={(event) => {
            const min = 0;
            const max = 400;

            var value = Number(event.target.value);

            if (value.toString()[0] === "0") {
              value = value.toString(8);
            }
            value = String(value);
            if (value.length > 3) {
              value = value.substring(0, 3);
            } else if (value > max) {
              value = value.substring(0, 2);
            } else if (value < min) value = min;

            const rowNr = row.nr - 1;
            let rowsCopy = [...rows];
            rowsCopy[rowNr] = {
              ...rows[rowNr],
              azimut: value,
            };
            setRows(rowsCopy);
          }}
        />
      </TableCell>
      <TableCell align="center">
        <TextField
          key={i.toString() + "Distanz" + saveObject}
          label="Distanz in cm"
          variant="filled"
          type="number"
          value={row.distanz}
          onBlur={saveRowStateToIDB}
          onChange={(event) => {
            var value = Number(event.target.value);

            if (value.toString()[0] === "0") {
              value = value.toString(8);
            }
            value = String(value);

            let rowsCopy = [...rows];
            rowsCopy[row.nr - 1] = {
              ...rows[row.nr - 1],
              distanz: value,
            };
            setRows(rowsCopy);
          }}
        />
      </TableCell>
      <TableCell align="center">
        <TextField
          key={i.toString() + "bhd" + saveObject}
          type="number"
          label="BHD in mm"
          variant="filled"
          value={row.bhd}
          onBlur={saveRowStateToIDB}
          onChange={(event) => {
            var value = Number(event.target.value);

            if (value.toString()[0] === "0") {
              value = value.toString(8);
            }
            value = String(value);

            let rowsCopy = [...rows];
            rowsCopy[row.nr - 1] = {
              ...rows[row.nr - 1],
              bhd: event.target.valueAsNumber,
            };
            setRows(rowsCopy);
          }}
        />
      </TableCell>
      <TableCell align="center">
        <TextField
          key={i.toString() + "messhoehebhd" + saveObject}
          label="Messhöhe in dm"
          variant="filled"
          value={row.messhoehebhd ?? 13}
          type="number"
          InputProps={{
            inputProps: {
              max: 20,
              min: 0,
            },
          }}
          onBlur={saveRowStateToIDB}
          onChange={(event) => {
            const min = 0;
            const max = 20;

            var value = Number(event.target.value);

            if (value.toString()[0] === "0") {
              value = value.toString(8);
            }
            value = String(value);
            if (value.length > 2) {
              value = value.substring(0, 2);
            } else if (value > max) {
              value = value.substring(0, 1);
            } else if (value < min) value = min;

            let rowsCopy = [...rows];
            rowsCopy[row.nr - 1] = {
              ...rows[row.nr - 1],
              messhoehebhd: value,
            };
            setRows(rowsCopy);
          }}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={row.schaele}
          onClick={(event) => {
            let rowsCopy = [...rows];
            rowsCopy[row.nr - 1] = {
              ...rows[row.nr - 1],
              schaele: event.target.checked,
            };
            setRows(rowsCopy);

            setSave(!save);
          }}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={row.fege}
          onClick={(event) => {
            let rowsCopy = [...rows];
            rowsCopy[row.nr - 1] = {
              ...rows[row.nr - 1],
              fege: event.target.checked,
            };
            setRows(rowsCopy);

            setSave(!save);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default BaumplotTableRow;

/*
Alerts Baumplot 2
*/
import React, { useContext, useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../AppContext";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

function Baumplot2Alert() {
  const appContext = useContext(AppContext);
  const [lessThanFive, setLessThanFive] = useState(false);

  return (
    <>
      <Alert
        severity="warning"
        style={{
          flexWrap: "wrap",
          flexDirection: "row wrap",
          margin: "0",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          bottom: "40%",
          zIndex: "505",
        }}
      >
        <AlertTitle>
          Ihre Eingaben sind nicht vollständig.<br></br> Bitte überprüfen Sie,
          ob zu jedem Baum <strong>alle</strong> Angaben vorgenommen wurden.
        </AlertTitle>
        <br></br>
        {appContext.baumplot2ZeroRowsCheck && (
          <div>
            <FormControlLabel
              control={<Checkbox />}
              label="Es sind keine Bäume vorhanden"
              value={lessThanFive}
              onClick={(event) => {
                appContext.setBaumplot2ZeroRows(true);
              }}
            />
            <br></br>
          </div>
        )}
        {appContext.baumplot2RowCountCheck && (
          <FormControlLabel
            control={<Checkbox />}
            label="Es sind weniger als 5 Bäume vorhanden"
            value={lessThanFive}
            onClick={(event) => {
              setLessThanFive(lessThanFive);
              appContext.setBaumplotLessThanFive2(true);
            }}
          />
        )}
        <br></br>
        <Button
          variant="outlined"
          onClick={() => {
            if (appContext.baumplot2Check === true) {
              appContext.setNextTabIndex(3);
              appContext.setCurrentSubTab("TRANSEKTANLAGE");
              //appContext.setCurrentTab("TRANSEKT");
            }
            appContext.setShowBaumplot2Alert(false);
          }}
        >
          Die Angaben sind vollständig
        </Button>
        <Button
          style={{ marginLeft: "10px" }}
          variant="outlined"
          onClick={() => {
            setLessThanFive(false);
            appContext.setShowBaumplot2Alert(false);
            appContext.setBaumplotLessThanFive2(false);
            appContext.setBaumplot2ZeroRows(false);
          }}
        >
          Abbrechen
        </Button>
      </Alert>
    </>
  );
}
export default Baumplot2Alert;

import React, { useState, useContext, useEffect, useMemo } from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingIndicator() {
  return (
    <>
      <div
        style={{
          background: "rgba(0,0,0,0.5)",
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: "100000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <h1
            style={{
              color: "white",
              display: "flex",
              justifyContent: "center",
            }}
          >
            ANWENDUNG LÄDT
          </h1>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress style={{ color: "white" }} size={80} />
          </div>
          <p
            style={{
              color: "white",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Bitte warten Sie während die Anwendung zur Offline-Nutzung
            vorbereitet wird.<br></br>
            Dies kann mehrere Minuten dauern.
          </p>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useMemo } from "react";
import NavigationIcon from "@mui/icons-material/Navigation";
import InputLabel from "@mui/material/InputLabel";
import { AppContext } from "./AppContext";
import useLocation from "../hooks/useLocation";
import { point, distance, bearing, bearingToAngle, center } from "@turf/turf";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FilledInput from "@mui/material/FilledInput";
import HelpIcon from "@mui/icons-material/Help";
import { IconButton } from "@mui/material";

const centerStyle = {
  flexWrap: "wrap",
  display: "flex",
  flexDirection: "row",
  margin: "0",
  alignItems: "center",
  justifyContent: "center",
};

export default function Navigation(props) {
  const appContext = useContext(AppContext);
  const userLocation = useLocation(props);

  const userPositionPoint = useMemo(() => {
    if (!userLocation.userLocation?.coords) return;

    if (appContext.gnssLocationProvider) {
      return appContext.gnssLocation;
    }
    return point([
      userLocation.userLocation.coords.longitude,
      userLocation.userLocation.coords.latitude,
    ]);
  }, [userLocation.userLocation, appContext.gnssLocation]);

  const direction = useMemo(() => {
    if (!userPositionPoint || !appContext.currentSP) return;

    return bearingToAngle(
      bearing(userPositionPoint, appContext.currentSP)
    ).toFixed(0);
  }, [userPositionPoint, appContext.currentSP]);

  const orientation = useMemo(() => {
    if (!userPositionPoint) return;

    return direction - userLocation.deviceOrientation;
  }, [userLocation.deviceOrientation, direction, userPositionPoint]);

  const distanceToSP = useMemo(() => {
    if (!userPositionPoint || !appContext.currentSP) return;

    return distance(userPositionPoint, appContext.currentSP).toFixed(3);
  }, [userPositionPoint, appContext.currentSP]);

  const locationAccuracy = useMemo(() => {
    if (!userLocation.userLocation?.coords) return;

    if (appContext.gnssLocationProvider) return appContext.gnssAccuracy;

    return userLocation.userLocation.coords.accuracy.toFixed(0);
  }, [userLocation.userLocation, appContext.gnssLocation]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          left: "0",
          bottom: "0",
          zIndex: 501,
        }}
      >
        {/* <h3 style={{ textAlign: "center" }}>Punkt-Navigation</h3> */}
        <div style={centerStyle}>
          <NavigationIcon
            style={{
              color: "red",
              fontSize: "250px",
              position: "relative",
              transform: "rotate(" + orientation + "deg)",
            }}
          />
        </div>
        <div style={{ marginBottom: 30 }}>
          <InputLabel style={{ textAlign: "center", fontWeight: "bold" }}>
            {/* Convert direction in degrees to Gorn 🦖 */}
            Richtung:{" "}
            {isNaN((400 * direction) / 360)
              ? "-/-"
              : ((400 * direction) / 360).toFixed(1)}{" "}
            Gon
          </InputLabel>
          <InputLabel style={{ textAlign: "center", fontWeight: "bold" }}>
            Entfernung:{" "}
            {isNaN(distanceToSP * 1000)
              ? "-/-"
              : (distanceToSP * 1000).toFixed(1)}{" "}
            m
          </InputLabel>
          <InputLabel style={{ textAlign: "center", fontWeight: "bold" }}>
            Genauigkeit: {locationAccuracy ?? "-/-"} m
          </InputLabel>
          <br></br>
          <InputLabel style={{ textAlign: "center", fontWeight: "bold" }}>
            Position abfragen
            <IconButton
              onClick={() => {
                appContext.setShowGPSHelp(true);
              }}
              style={{
                zIndex: 1011,
                //position: "absolute",
                //padding: "30px 40px 40px 40px",
                //top: "0",
                color: "green",
              }}
            >
              <HelpIcon />
            </IconButton>
          </InputLabel>
        </div>
        {navigator.serial && (
          <div style={centerStyle}>
            <TextField
              variant="outlined"
              sx={{
                width: "60%",
                backgroundColor: "white",
                marginBottom: "30px",
              }}
              id="baudRate"
              label="Baudrate"
              onChange={(event) => {
                appContext.setBaudRate(event.target.value);
              }}
            />
            <Button
              variant="contained"
              style={
                !appContext.openGnssPort
                  ? {
                      color: "white",
                      width: "60%",
                      bottom: "20px",
                      height: "50px",
                    }
                  : {
                      color: "white",
                      width: "60%",
                      bottom: "20px",
                      backgroundColor: "red",
                      height: "50px",
                    }
              }
              onClick={() => {
                appContext.setOpenGnssPort(!appContext.openGnssPort);
              }}
              //(TODO: Fehler abfangen und anzeigen)
            >
              {!appContext.openGnssPort
                ? "GNSS-Port öffnen"
                : "GNSS-Port schließen"}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

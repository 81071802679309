let emptyBestockung = [
  {
    nr: 1,
    bestockungSchicht: "",
    bestockungSchichtID: "",
    bestockungBaumart: "",
    bestockungBaumartID: "",
    bestockungEntstehungsart: "",
    bestockungEntstehungsartID: "",
    bestockungVerteilung: "",
    bestockungVerteilungID: "",
    bestockungBaumartenanteil: NaN,
    bestockungAlter: "-1",
    bestockungAlterID: "",
  },
];

export default emptyBestockung;

let emptyWeiserpflanzen = {
  weiserpflanzenMoos: 0,
  weiserpflanzenBrennessel: 0,
  weiserpflanzenGoldnessel: 0,
  weiserpflanzenHeidekraut: 0,
  weiserpflanzenSpringkraut: 0,
  weiserpflanzenMaigloeckchen: 0,
  weiserpflanzenWeidenroeschen: 0,
  weiserpflanzenWaldmeister: 0,
  weiserpflanzenWaldsauerklee: 0,
  weiserpflanzenWegerich: 0,
  weiserpflanzenDrahtschmiele: 0,
  weiserpflanzenFlatterbinse: 0,
  weiserpflanzenHainrispengras: 0,
  weiserpflanzenPerlgras: 0,
  weiserpflanzenPfeifengras: 0,
  weiserpflanzenSandrohr: 0,
  weiserpflanzenWaldzwenke: 0,
  weiserpflanzenWinkelsegge: 0,
  weiserpflanzenAdlerfarn: 0,
  weiserpflanzenGiersch: 0,
  weiserpflanzenHeidelbeere: 0,
  weiserpflanzenPreiselbeere: 0,
  weiserpflanzenHimbeere: 0,
  weiserpflanzenBrombeere: 0,
};
export default emptyWeiserpflanzen;

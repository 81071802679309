let emptySPAufsuche = {
  spAufsucheAufnahmetruppKuerzel: "",
  spAufsucheGNSS: "",
  spAufsucheNichtBegehbar: false,
  spAufsucheNichtBegehbarUrsache: "",
  spAufsucheNichtBegehbarUrsacheID: "",
  spAufsucheVerschoben: false,
  spAufsucheVerschobenUrsache: "",
  spAufsucheVerschobenUrsacheID: "",
  spAufsuchePermamarker: false,
  spAufsucheMarkerart: "",
  spAufsucheMarkerartID: "",
  spAufsucheNichtWald: false,
  spAufsucheNichtWaldUrsache: "",
  spAufsucheNichtWaldUrsacheID: "",
};

export default emptySPAufsuche;

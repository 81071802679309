/*
Sort array by property.
*/

const sortArrayByProperty = (array, property) => {
  array.sort(dynamicSort(property));

  function dynamicSort(property) {
    return function (a, b) {
      return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    };
  }
};
export default sortArrayByProperty;

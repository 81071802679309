import React, { useState, useContext, useEffect, useMemo } from "react";
import { AppContext } from "./AppContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import emptyBodenvegetation from "../lib/emptyObjects/emptyBodenvegetation";
import { saveEntryToIDB, checkIfEntryExists } from "../swCommunication";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import sortArrayByProperty from "./util/sortArrayByProperty";
const channel = new BroadcastChannel("sw-messages");

export default function Bodenvegetation() {
  const appContext = useContext(AppContext);
  const [bodenvegetation, setBodenvegetation] = useState([
    ...emptyBodenvegetation,
  ]);

  //let bodenvegetationArray = [];
  //let verteilungArray = [];

  useEffect(() => {
    if (
      appContext.currentSP !== undefined &&
      appContext.currentTab === "BESTANDSBESCHREIBUNGEN" &&
      appContext.currentSubTab === "BODENVEGETATION"
    ) {
      var checkExisting = {
        type: "GET_ENTRY_FROM_DB",
        id: appContext.currentSP.properties.los_id,
      };

      checkIfEntryExists(checkExisting);
    }
  }, [appContext.currentTab, appContext.currentSubTab, appContext.currentSP]);

  channel.onmessage = function (event) {
    if (
      event.data !== "no entry" &&
      appContext.currentTab === "BESTANDSBESCHREIBUNGEN" &&
      appContext.currentSubTab === "BODENVEGETATION" &&
      event.data?.properties?.los_id === appContext.currentSP.properties.los_id
    ) {
      if (event.data.properties.bodenvegetation !== undefined) {
        setBodenvegetation(event.data.properties.bodenvegetation);
      }
    }
  };

  let bodenvegetationArray = useMemo(() => {
    if (appContext.bodenvegetation !== undefined) {
      let _existingLabels = [];
      let _bodenvegetation = [];
      Object.keys(appContext.bodenvegetation).forEach(function (
        bodenvegetationNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.bodenvegetation[
              bodenvegetationNummer
            ].bodenveg_gr_txt.toString()
          ) !== -1 ||
          appContext.bodenvegetation[bodenvegetationNummer].bodenveg_gr_txt ===
            "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.bodenvegetation[
            bodenvegetationNummer
          ].bodenveg_gr_txt.toString()
        );
        _bodenvegetation.push({
          label:
            appContext.bodenvegetation[
              bodenvegetationNummer
            ].bodenveg_gr_txt.toString(),
          id: appContext.bodenvegetation[bodenvegetationNummer].id_bodenveg_gr,
        });
      });
      sortArrayByProperty(_bodenvegetation, "id");
      return _bodenvegetation;
    }
  }, [appContext.bodenvegetation]);

  let verteilungArray = useMemo(() => {
    if (appContext.verteilungKraut !== undefined) {
      let _existingLabels = [];
      let _verteilungArray = [];
      Object.keys(appContext.verteilungKraut).forEach(function (
        verteilungNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.verteilungKraut[verteilungNummer].vertbes.toString()
          ) !== -1 ||
          appContext.verteilungKraut[verteilungNummer].vertbes ===
            "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.verteilungKraut[verteilungNummer].vertbes.toString()
        );
        _verteilungArray.push({
          label:
            appContext.verteilungKraut[verteilungNummer].vertbes.toString(),
          id: appContext.verteilungKraut[verteilungNummer].id_vert,
        });
      });
      sortArrayByProperty(_verteilungArray, "id");
      return _verteilungArray;
    }
  }, [appContext.verteilungKraut]);

  const addRow = () => {
    const newtableRowsPlotOne = [...bodenvegetation];
    newtableRowsPlotOne.push({
      nr: bodenvegetation[bodenvegetation.length - 1].nr + 1,
    });
    setBodenvegetation(newtableRowsPlotOne);
  };

  const removeRow = () => {
    if (bodenvegetation.length === 1) return;
    const newTableRowsPlotOne = [...bodenvegetation];
    newTableRowsPlotOne.pop();
    setBodenvegetation(newTableRowsPlotOne);
  };

  const [save, setSave] = useState(false);

  useEffect(() => {
    saveEntryToIDB("bodenvegetation", bodenvegetation, appContext.currentSP);
  }, [save]);

  const handleAutocompleteChange = (
    objectEntryID,
    objectEntry,
    value,
    rowNr
  ) => {
    let bodenvegCopy = [...bodenvegetation];
    if (value !== null) {
      bodenvegCopy[rowNr] = {
        ...bodenvegetation[rowNr],
        [objectEntryID]: value.id,
        [objectEntry]: value.label,
      };
    } else {
      bodenvegCopy[rowNr] = {
        ...bodenvegetation[rowNr],
        [objectEntryID]: "",
        [objectEntry]: "",
      };
    }
    setBodenvegetation(bodenvegCopy);
  };

  const handleTextInput = (objectEntry, value, rowNr) => {
    let bodenvegCopy = [...bodenvegetation];
    bodenvegCopy[rowNr] = {
      ...bodenvegetation[rowNr],
      [objectEntry]: value,
    };
    setBodenvegetation(bodenvegCopy);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <strong>Nr</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Bodenvegetation</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Verteilung</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Anteil von Bodenbedeckung in %</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bodenvegetation.map((row) => (
              <TableRow
                key={row.nr}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{row.nr}</TableCell>

                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "bodenvegetationID",
                        "bodenvegetation",
                        value,
                        row.nr - 1
                      );
                      setSave(!save);
                    }}
                    value={
                      bodenvegetationArray.find(
                        (element) => element.id === row.bodenvegetationID
                      ) ?? null
                    }
                    disablePortal
                    options={bodenvegetationArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Bodenvegetation" />
                    )}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "bodenvegetationVerteilungID",
                        "bodenvegetationVerteilung",
                        value,
                        row.nr - 1
                      );

                      setSave(!save);
                    }}
                    value={
                      verteilungArray.find(
                        (element) =>
                          element.id === row.bodenvegetationVerteilungID
                      ) ?? null
                    }
                    disablePortal
                    options={verteilungArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Verteilung" />
                    )}
                  />
                </TableCell>
                <TableCell align="left">
                  <TextField
                    sx={{ width: "100%" }}
                    id="filled-basic"
                    label="Anteil in %"
                    variant="filled"
                    type="number"
                    value={String(row.bodenvegetationAnteil) ?? ""}
                    onBlur={() => {
                      saveEntryToIDB(
                        "bodenvegetation",
                        bodenvegetation,
                        appContext.currentSP
                      );
                    }}
                    onChange={(event) => {
                      const min = 0;
                      const max = 100;

                      var value = Number(event.target.value);

                      if (value.toString()[0] === "0") {
                        value = value.toString(8);
                      }
                      value = String(value);

                      if (value.length >= 3 && value > max) {
                        value = value.substring(0, 2);
                      } else if (value > max) {
                        value = max;
                      } else if (value < min) value = min;

                      handleTextInput(
                        "bodenvegetationAnteil",
                        value,
                        row.nr - 1
                      );
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          margin: "10px 0px 0px 0px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          aria-label="add"
          color="primary"
          onClick={addRow}
          style={{ transform: "scale(1.8)", marginRight: "15px" }}
        >
          <AddCircleIcon />
        </IconButton>

        <IconButton
          aria-label="remove"
          color="error"
          onClick={removeRow}
          style={{ transform: "scale(1.8)", marginLeft: "15px" }}
        >
          <RemoveCircleIcon />
        </IconButton>
      </div>
      <Button
        variant="contained"
        style={{ marginLeft: "10px", width: "100px" }}
        onClick={() => {
          saveEntryToIDB(
            "bodenvegetation",
            bodenvegetation,
            appContext.currentSP
          );
          appContext.setCurrentSubTab("BESTOCKUNG");
        }}
      >
        Zurück
      </Button>
      <Button
        variant="contained"
        style={{ marginLeft: "10px", width: "100px" }}
        onClick={() => {
          saveEntryToIDB(
            "bodenvegetation",
            bodenvegetation,
            appContext.currentSP
          );
        }}
      >
        Save
      </Button>
      <Button
        variant="contained"
        style={{ marginLeft: "10px", width: "100px" }}
        onClick={() => {
          saveEntryToIDB(
            "bodenvegetation",
            bodenvegetation,
            appContext.currentSP
          );
          appContext.setCurrentSubTab("STÖRUNGEN");
        }}
      >
        Weiter
      </Button>
    </>
  );
}

import React, { useMemo, useState, useContext, useEffect } from "react";
import { AppContext } from "./AppContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BaumplotTableRow from "./BaumplotTableRow";
import { saveEntryToIDB, checkIfEntryExists } from "../swCommunication";
import emptyPlot from "../lib/emptyObjects/emptyPlot";
import emptyPlotTwo from "../lib/emptyObjects/emptyPlotTwo";
import emptyLandmarken from "../lib/emptyObjects/emptyLandmarken";
import emptyLandmarken2 from "../lib/emptyObjects/emptyLandmarken";
import DrawBaumplot from "./DrawBaumplot";
import DrawBaumplot2 from "./DrawBaumplot2";
import DrawSecondSP from "./DrawSecondSP";
import DataFormCheck from "./logischePruefungFormular/DataFormCheck";
import sortArrayByProperty from "./util/sortArrayByProperty";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const channel = new BroadcastChannel("sw-messages");

export default function Baumplot() {
  const appContext = useContext(AppContext);
  const [value, setValue] = React.useState(0);
  const [rows, setRows] = useState(emptyPlot);
  const [rowsPlotTwo, setRowsPlotTwo] = useState(emptyPlotTwo);
  const [rowsSpecialEntries, setRowsSpecialEntries] = useState(emptyLandmarken);
  const [rowsSpecialEntries2, setRowsSpecialEntries2] =
    useState(emptyLandmarken2);
  const [azimutText, setAzimutText] = useState("");
  const [plotTwoTabEnable, setPlotTwoTabEnable] = useState(true);

  let baumartArray = useMemo(() => {
    if (appContext.baumart !== undefined) {
      let _existingLabels = [];
      let _baumartArray = [];
      Object.keys(appContext.baumart).forEach(function (baumartNummer, i) {
        if (
          _existingLabels.indexOf(
            appContext.baumart[baumartNummer].kurzd.toString()
          ) !== -1 ||
          appContext.baumart[baumartNummer].kurzd === "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.baumart[baumartNummer].kurzd.toString()
        );
        _baumartArray.push({
          label: appContext.baumart[baumartNummer].kurzd.toString(),
          nameLong: appContext.baumart[baumartNummer].langd.toString(),
          id: appContext.baumart[baumartNummer].icode_ba,
          idSort: appContext.baumart[baumartNummer].sort_bb,
        });
      });
      sortArrayByProperty(_baumartArray, "idSort");
      return _baumartArray;
    }
  }, [appContext.baumart]);

  useEffect(() => {
    if (
      typeof appContext.currentSP !== "undefined" &&
      appContext.currentTab === "BAUMPLOT"
    ) {
      var checkExisting = {
        type: "GET_ENTRY_FROM_DB",
        id: appContext.currentSP.properties.los_id,
      };
      checkIfEntryExists(checkExisting);
    }
  }, [appContext.currentTab, appContext.currentSubTab, appContext.currentSP]);

  function compareObject(
    object,
    baumplot,
    setBaumplot,
    azimut,
    landmarken,
    form
  ) {
    Object.values(object).forEach((element, index) => {
      if (typeof element !== "undefined");
      {
        if (
          element.baumart !== baumplot[index].baumart ||
          element.azimut !== baumplot[index].azimut ||
          element.distanz !== baumplot[index].distanz ||
          element.bhd !== baumplot[index].bhd ||
          element.messhoehebhd !== baumplot[index].messhoehebhd ||
          element.schaele !== baumplot[index].schaele ||
          element.fege !== baumplot[index].fege
        ) {
          setBaumplot(object);
          if (form === "plot1") {
            if (typeof azimut !== "undefined") setAzimutText(azimut);
            if (typeof landmarken !== "undefined") {
              setRowsSpecialEntries(landmarken);
            }
          } else if (form === "plot2") {
            if (typeof landmarken !== "undefined") {
              setRowsSpecialEntries2(landmarken);
            }
          }
        }
      }
    });
  }

  channel.onmessage = function (event) {
    if (appContext.currentTab !== "BAUMPLOT") return;
    if (
      typeof event.data !== "undefined" &&
      event.data !== "no entry" &&
      appContext.currentTab === "BAUMPLOT" &&
      event.data?.properties?.los_id === appContext.currentSP.properties.los_id
    ) {
      if (
        typeof event.data.properties.baumplotEins !== "undefined" &&
        appContext.currentSubTab === "BAUMPLOT 1"
      ) {
        compareObject(
          event.data.properties.baumplotEins,
          rows,
          setRows,
          event.data.properties.azimutTransektPlotEins,
          event.data.properties.landmarkenPlotEins,
          "plot1"
        );
      }
      if (
        typeof event.data.properties.baumplotZwei !== "undefined" &&
        appContext.currentSubTab === "BAUMPLOT 2"
      ) {
        compareObject(
          event.data.properties.baumplotZwei,
          rowsPlotTwo,
          setRowsPlotTwo,
          undefined,
          event.data.properties.landmarkenPlotZwei,
          "plot2"
        );
      }
    }
  };

  const addRow = () => {
    const newTableRowsPlotOne = [...rows];
    newTableRowsPlotOne.push({
      nr: rows[rows.length - 1].nr + 1,
    });
    setRows(newTableRowsPlotOne);
  };

  const removeRow = (table, setTable) => {
    if (table.length === 1) return;
    const newTableRowsPlotOne = [...table];
    newTableRowsPlotOne.pop();
    setTable(newTableRowsPlotOne);
  };

  const addRowPlotTwo = () => {
    const newRow = [...rowsPlotTwo];
    newRow.push({
      nr: rowsPlotTwo[rowsPlotTwo.length - 1].nr + 1,
    });
    setRowsPlotTwo(newRow);
  };

  const addRowSpecial = () => {
    const newSpecialRow = [...rowsSpecialEntries];
    newSpecialRow.push({
      nr: rowsSpecialEntries[rowsSpecialEntries.length - 1].nr + 1,
    });
    setRowsSpecialEntries(newSpecialRow);
  };
  const addRowSpecial2 = () => {
    const newSpecialRow2 = [...rowsSpecialEntries2];
    newSpecialRow2.push({
      nr: rowsSpecialEntries2[rowsSpecialEntries2.length - 1].nr + 1,
    });
    setRowsSpecialEntries2(newSpecialRow2);
  };

  const handleAzimutChange = (value) => {
    if (value > 0 && value <= 400) {
      setPlotTwoTabEnable(false);
    } else {
      setPlotTwoTabEnable(true);
    }
  };

  useEffect(() => {
    if (appContext.currentSubTab === "BAUMPLOT 2") {
      setPlotTwoTabEnable(true);
      setValue(1);
    }
  }, [appContext.currentSubTab]);

  const handleChange = (event, newValue, subTab = undefined) => {
    if (subTab) {
      appContext.setCurrentSubTab(subTab);
    } else {
      appContext.setCurrentSubTab(event.target.innerText);
    }

    setValue(newValue);
  };

  const handleInputEvent = (
    form,
    objectEntry,
    rowNr,
    value,
    setStateVariable
  ) => {
    let rowsCopy = [...form];
    rowsCopy[rowNr] = {
      ...form[rowNr],
      [objectEntry]: value,
    };
    setStateVariable(rowsCopy);
  };

  function saveRowStateToIDB(saveObject, saveObjectRows) {
    saveEntryToIDB(saveObject, saveObjectRows, appContext.currentSP);
  }

  //console.log(appContext.currentSubTab);

  return (
    <>
      <DrawBaumplot baumplotData={rows} />
      <DrawBaumplot2 baumplotData={rowsPlotTwo} />
      {appContext.currentSP.geometry.coordinates !== "" &&
        appContext.currentSP.geometry.coordinates !== undefined && (
          <DrawSecondSP spData={appContext.currentAzimut}></DrawSecondSP>
        )}
      {appContext.currentSubTab === "BAUMPLOT 2" ? (
        <DataFormCheck
          checkData={{ rows: rowsPlotTwo, azimutText: azimutText }}
        ></DataFormCheck>
      ) : (
        <DataFormCheck
          checkData={{ rows: rows, azimutText: azimutText }}
        ></DataFormCheck>
      )}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} aria-label="basic tabs example">
          <Tab label="Baumplot 1" {...a11yProps(0)} />
          <Tab
            disabled={plotTwoTabEnable}
            label="Baumplot 2"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong>Nr</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Baumart</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Azimut in Gon</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Distanz in cm</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>BHD in mm</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Messhöhe BHD in dm</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Schäle</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Fege</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(rows).map((row, i) => (
                <BaumplotTableRow
                  row={row}
                  i={i}
                  setRows={setRows}
                  baumartArray={baumartArray}
                  rows={rows}
                  saveObject={"baumplotEins"}
                ></BaumplotTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            margin: "10px 0px 0px 0px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            aria-label="add"
            color="primary"
            onClick={addRow}
            style={{ transform: "scale(1.8)", marginRight: "15px" }}
          >
            <AddCircleIcon />
          </IconButton>
          <IconButton
            aria-label="remove"
            color="error"
            onClick={() => {
              removeRow(rows, setRows);
            }}
            style={{ transform: "scale(1.8)", marginLeft: "15px" }}
          >
            <RemoveCircleIcon />
          </IconButton>
        </div>
        <br></br>
        <Divider />
        <br></br>
        <h3>Landmarken</h3>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong>Nr</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Landmarken</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Azimut in Gon</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Distanz in cm</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(rowsSpecialEntries ?? emptyLandmarken).map(
                (row, i) => (
                  <TableRow
                    key={i.toString() + "rowsSpecial1"}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{row.nr}</TableCell>
                    <TableCell align="center">
                      <TextField
                        key={i.toString() + "landmarkeSpecial1"}
                        id="row_nr"
                        label="Landmarken"
                        value={row.landmarken}
                        onBlur={() => {
                          saveRowStateToIDB(
                            "landmarkenPlotEins",
                            rowsSpecialEntries
                          );
                        }}
                        onChange={(event) => {
                          handleInputEvent(
                            rowsSpecialEntries,
                            "landmarken",
                            row.nr - 1,
                            event.target.value,
                            setRowsSpecialEntries
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        key={i.toString() + "azimutSpecial1"}
                        label="Azimut in Gon"
                        variant="filled"
                        type="number"
                        value={row.azimut}
                        onBlur={() => {
                          saveRowStateToIDB(
                            "landmarkenPlotEins",
                            rowsSpecialEntries
                          );
                        }}
                        onChange={(event) => {
                          const min = 0;
                          const max = 400;

                          var value = Number(event.target.value);

                          if (value.toString()[0] === "0") {
                            value = value.toString(8);
                          }
                          value = String(value);
                          if (value.length > 3) {
                            value = value.substring(0, 3);
                          } else if (value > max) {
                            value = value.substring(0, 2);
                          } else if (value < min) value = min;

                          handleInputEvent(
                            rowsSpecialEntries,
                            "azimut",
                            row.nr - 1,
                            Number(value),
                            setRowsSpecialEntries
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        key={i.toString() + "distanzSpecial1"}
                        label="Distanz in cm"
                        variant="filled"
                        type="number"
                        value={row.distanz}
                        onBlur={() => {
                          saveRowStateToIDB(
                            "landmarkenPlotEins",
                            rowsSpecialEntries
                          );
                        }}
                        onChange={(event) => {
                          handleInputEvent(
                            rowsSpecialEntries,
                            "distanz",
                            row.nr - 1,
                            event.target.valueAsNumber,
                            setRowsSpecialEntries
                          );
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            margin: "10px 0px 0px 0px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            aria-label="add"
            color="primary"
            onClick={addRowSpecial}
            style={{ transform: "scale(1.8)", marginRight: "15px" }}
          >
            <AddCircleIcon />
          </IconButton>

          <IconButton
            aria-label="remove"
            color="error"
            onClick={() => {
              removeRow(rowsSpecialEntries, setRowsSpecialEntries);
            }}
            style={{ transform: "scale(1.8)", marginLeft: "15px" }}
          >
            <RemoveCircleIcon />
          </IconButton>
        </div>
        <br></br>
        <Divider />
        <br></br>
        <h3>Transektanlage</h3>
        <div>
          <TextField
            type="number"
            label="Azimut in Gon"
            variant="filled"
            InputProps={{
              inputProps: {
                max: 400,
                min: 0,
              },
            }}
            value={azimutText}
            onBlur={() => {
              saveEntryToIDB(
                "azimutTransektPlotEins",
                azimutText,
                appContext.currentSP
              );
              appContext.setCurrentAzimut(azimutText);
            }}
            onChange={(event) => {
              const min = 0;
              const max = 400;

              var value = Number(event.target.value);

              if (value.toString()[0] === "0") {
                value = value.toString(8);
              }
              value = String(value);
              if (value.length > 3) {
                value = value.substring(0, 3);
              } else if (value > max) {
                value = value.substring(0, 2);
              } else if (value < min) value = min;

              setAzimutText(value);
              handleAzimutChange(value);
            }}
          />
        </div>
        <br></br>
        <Divider />
        <br></br>
        <div>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={(event) => {
              saveEntryToIDB("baumplotEins", rows, appContext.currentSP);
              appContext.setNextTabIndex(1);
              appContext.setCurrentTab("SP-AUFSUCHE");
            }}
          >
            Zurück
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={() => {
              saveEntryToIDB("baumplotEins", rows, appContext.currentSP);
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={(event) => {
              saveEntryToIDB("baumplotEins", rows, appContext.currentSP);
              if (appContext.baumplot1Check === true) {
                // change tab to Baumplot2
                //setPlotTwoTabEnable(true);
                appContext.setCurrentSubTab("BAUMPLOT 2");
                appContext.setShowBaumplot1Alert(false);
              } else {
                //alert
                appContext.setShowBaumplot1Alert(true);
              }
            }}
          >
            Weiter
          </Button>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
            aria-label="plot-2"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong>Nr</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Baumart</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Azimut in Gon</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Distanz in cm</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>BHD in mm</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Messhöhe BHD in dm</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Schäle</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Fege</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(rowsPlotTwo).map((row, i) => (
                <BaumplotTableRow
                  row={row}
                  i={i}
                  setRows={setRowsPlotTwo}
                  baumartArray={baumartArray}
                  rows={rowsPlotTwo}
                  saveObject={"baumplotZwei"}
                ></BaumplotTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            margin: "10px 0px 0px 0px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            aria-label="add"
            color="primary"
            onClick={addRowPlotTwo}
            style={{ transform: "scale(1.8)", marginRight: "15px" }}
          >
            <AddCircleIcon />
          </IconButton>
          <IconButton
            aria-label="remove"
            color="error"
            onClick={() => {
              removeRow(rowsPlotTwo, setRowsPlotTwo);
            }}
            style={{ transform: "scale(1.8)", marginLeft: "15px" }}
          >
            <RemoveCircleIcon />
          </IconButton>
        </div>
        <br></br>
        <Divider />
        <br></br>
        <h3>Landmarken</h3>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong>Nr</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Landmarken</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Azimut in Gon</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Distanz in cm</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(rowsSpecialEntries2 ?? emptyLandmarken2).map(
                (row, i) => (
                  <TableRow
                    key={i.toString() + "rowsSpecial2"}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{row.nr}</TableCell>
                    <TableCell align="center">
                      <TextField
                        key={i.toString() + "landmarkeSpecial2"}
                        id="row_nr"
                        label="Landmarken"
                        value={row.landmarken}
                        onBlur={() => {
                          saveRowStateToIDB(
                            "landmarkenPlotZwei",
                            rowsSpecialEntries2
                          );
                        }}
                        onChange={(event) => {
                          handleInputEvent(
                            rowsSpecialEntries2,
                            "landmarken",
                            row.nr - 1,
                            event.target.value,
                            setRowsSpecialEntries2
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        key={i.toString() + "azimutSpecial2"}
                        label="Azimut in Gon"
                        type="number"
                        variant="filled"
                        value={row.azimut}
                        onBlur={() => {
                          saveRowStateToIDB(
                            "landmarkenPlotZwei",
                            rowsSpecialEntries2
                          );
                        }}
                        onChange={(event) => {
                          const min = 0;
                          const max = 400;

                          var value = Number(event.target.value);

                          if (value.toString()[0] === "0") {
                            value = value.toString(8);
                          }
                          value = String(value);
                          if (value.length > 3) {
                            value = value.substring(0, 3);
                          } else if (value > max) {
                            value = value.substring(0, 2);
                          } else if (value < min) value = min;

                          handleInputEvent(
                            rowsSpecialEntries2,
                            "azimut",
                            row.nr - 1,
                            Number(value),
                            setRowsSpecialEntries2
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        key={i.toString() + "distanzSpecial2"}
                        label="Distanz in cm"
                        variant="filled"
                        type="number"
                        value={row.distanz}
                        onBlur={() => {
                          saveRowStateToIDB(
                            "landmarkenPlotZwei",
                            rowsSpecialEntries2
                          );
                        }}
                        onChange={(event) => {
                          handleInputEvent(
                            rowsSpecialEntries2,
                            "distanz",
                            row.nr - 1,
                            event.target.valueAsNumber,
                            setRowsSpecialEntries2
                          );
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            margin: "10px 0px 0px 0px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            aria-label="add"
            color="primary"
            onClick={addRowSpecial2}
            style={{ transform: "scale(1.8)", marginRight: "15px" }}
          >
            <AddCircleIcon />
          </IconButton>

          <IconButton
            aria-label="remove"
            color="error"
            onClick={() => {
              removeRow(rowsSpecialEntries2, setRowsSpecialEntries2);
            }}
            style={{ transform: "scale(1.8)", marginLeft: "15px" }}
          >
            <RemoveCircleIcon />
          </IconButton>
        </div>
        <div>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={(event) => {
              saveEntryToIDB("baumplotZwei", rowsPlotTwo, appContext.currentSP);
              setPlotTwoTabEnable(false);
              //appContext.setCurrentSubTab("BAUMPLOT 1");
              handleChange(event, 0, "BAUMPLOT 1");
            }}
          >
            Zurück
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={() => {
              saveEntryToIDB("baumplotZwei", rowsPlotTwo, appContext.currentSP);
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={(event) => {
              saveEntryToIDB("baumplotZwei", rowsPlotTwo, appContext.currentSP);
              if (appContext.baumplot2Check === true) {
                appContext.setNextTabIndex(3);
                //appContext.setCurrentTab("TRANSEKT");
                appContext.setCurrentSubTab("TRANSEKTANLAGE");
                appContext.setShowBaumplot2Alert(false);
              } else {
                //alert
                appContext.setShowBaumplot2Alert(true);
              }
            }}
          >
            Weiter
          </Button>
        </div>
      </TabPanel>
    </>
  );
}

import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "./AppContext";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function AutoCompleteDrawer() {
  const appContext = useContext(AppContext);
  const [data, setData] = useState(false); // true, falls alle benötigten Daten !== undefined

  const [unterlose, setUnterlose] = useState([]);
  const [spList, setSpList] = useState([]);
  const [currentHauptLos, setCurrentHauptLos] = useState(null);
  const [currentUnterLos, setCurrentUnterLos] = useState(null);
  const [currentSp, setCurrentSp] = useState(null);

  useEffect(() => {
    if (currentSp) {
      appContext.setCurrentSP(
        appContext.sortedLose[currentHauptLos][currentUnterLos][currentSp]
      );
      console.log(appContext.currentSP);
      console.log(
        appContext.sortedLose[currentHauptLos][currentUnterLos][currentSp]
      );
    }
  }, [currentSp]);

  useEffect(() => {
    if (
      appContext.sortedLose !== undefined &&
      Object.keys(appContext.sortedLose).length !== 0 &&
      currentHauptLos !== undefined &&
      currentHauptLos !== null
    ) {
      Object.keys(appContext.sortedLose).forEach(function (los) {
        if (currentHauptLos.toString() === los.toString()) {
          setUnterlose(Object.keys(appContext.sortedLose[los]));
        }
      });
    }
  }, [appContext.sortedLose, currentHauptLos]);

  useEffect(() => {
    if (appContext.sortedHauptlose !== undefined) {
      setData(true);
    }
  }, [appContext.sortedHauptlose]);

  const handleLosNumber = (e, value) => {
    setCurrentHauptLos(value);
    handleUnterLosNumber(e, null);
    setCurrentSp(null);
  };

  const handleUnterLosNumber = (e, value) => {
    if (value !== null && typeof value !== "undefined" && value) {
      setCurrentUnterLos(value);
      setCurrentSp(null);
      setSpList(Object.keys(appContext.sortedLose[currentHauptLos][value]));
    } else {
      setSpList([]);
    }
  };

  return (
    <>
      {data && (
        <Autocomplete
          size="small"
          onFocus={() => {
            document.activeElement.blur();
          }}
          onChange={(e, value) => handleLosNumber(e, value)}
          value={currentHauptLos ?? null}
          //isSearchable={false}
          options={Object.values(appContext.sortedHauptlose) ?? []}
          sx={{
            width: "100%",
            "& button[title='Clear']": {
              visibility: "visible",
            },
          }}
          renderInput={(params) => <TextField {...params} label="Losnummer" />}
        />
      )}
      <br />
      {data && (
        <Autocomplete
          size="small"
          onFocus={() => {
            document.activeElement.blur();
          }}
          onChange={(e, value) => handleUnterLosNumber(e, value)}
          value={currentUnterLos ?? null}
          options={unterlose ? unterlose : []}
          sx={{
            width: "100%",
            "& button[title='Clear']": {
              visibility: "visible",
            },
          }}
          renderInput={(params) => <TextField {...params} label="Unterlos" />}
        />
      )}
      <br />
      {data && (
        <Autocomplete
          size="small"
          disablePortal
          onChange={(e, value) => {
            if (
              value !== "" &&
              typeof value !== "undefined" &&
              value !== null
            ) {
              setCurrentSp(value);
            }
          }}
          value={currentSp ?? null}
          options={spList}
          sx={{
            width: "100%",
            "& button[title='Clear']": {
              visibility: "visible",
            },
          }}
          renderInput={(params) => (
            <TextField {...params} label="Stichprobenpunkt" />
          )}
        />
      )}
    </>
  );
}

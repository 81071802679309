import React, { useState, useEffect, useContext } from "react";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { AppContext } from "../AppContext";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

export default function BearbeitungsstandSPsHelp() {
  const appContext = useContext(AppContext);

  return (
    <Dialog open fullWidth maxWidth="sm">
      <Paper elevation={3}>
        <DialogTitle
          style={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            borderBottom: "1px solid black",
          }}
        >
          Bearbeitungsstand SPs
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "white" }}>
          <DialogContentText style={{ color: "black" }}>
            <br></br>
            <b>Aktualisieren</b>
            <br></br>
            Mit Klick auf den Button 'Aktualisieren' werden angezeigt:
            <ul>
              <li>
                <b>'SPs in Bearbeitung'</b>
              </li>
              SPs, deren Bearbeitung angefangen, dessen Formulare jedoch nicht
              beendet wurden. Über die Losauswahl lassen sich diese erneut
              heraussuchen und abschließend bearbeiten.
              <li>
                <b> 'Zum Versenden vorgemerkte SPs'</b>
              </li>
              SPs, die abschließend bearbeitet wurden, jedoch noch nicht
              versendet wurden. Diese werden nicht mehr auf der Karte angezeigt.
              Mit Klick auf den Button 'Versenden' wird bei Internetverbindung
              erneut versucht diese zu versenden.
            </ul>
            Zusätzlich wird beim 'Aktualisieren' überprüft, ob es neue oder
            veränderte SPs gibt, die in die PWA geladen werden müssen.
            <br></br>
            <br></br>
            <b>Versenden</b>
            <br></br>
            Mit Klick auf 'Versenden' werden 'Zum Versenden vorgemerkte SPs'
            versendet.
          </DialogContentText>
          <Button
            variant="contained"
            style={{
              height: "70%",
              marginTop: "10px",
              marginRight: "10px",
              width: "175px",
            }}
            onClick={() => {
              appContext.setShowBearbeitungsstandSPsHelp(false);
            }}
          >
            Zurück
          </Button>
        </DialogContent>
      </Paper>
    </Dialog>
  );
}

/*
Es gibt mehrere Auswahlmöglichkeiten, nach denen die weitere Eingabe im Formular beendet wird.

Folgende Checkboxen/Drodowns sollen dies auslösen:

- SP-Aufsuche: "Stichprobenpunkt liegt eindeutig nicht im Wald und wäre auch nach Verlegung nicht im Wald", Auswahl Dropdown
- Transektanlage: "Temporäre Störung", Dropdown "Ursache"
- Transektanlage: "Schutzmaßnahmen", Checkbox "eine Aufnahme ist nicht möglich"

- Bestandsbeschreibungen -> Störungen, Button "Eingabe abschliessen"
*/
import React, { useContext } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../AppContext";
import { saveEntryToIDB, saveLose } from "../../../swCommunication";
import { AuthContext } from "../../AuthContext";

function EingabeFormularBeenden() {
  const appContext = useContext(AppContext);
  const authContext = useContext(AuthContext);

  return (
    <>
      <Alert
        severity="warning"
        style={{
          flexWrap: "wrap",
          flexDirection: "row wrap",
          margin: "0",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          bottom: "40%",
          zIndex: "505",
        }}
      >
        <AlertTitle>
          Vorsicht, durch Ihre Eingabe wird das gesamte Aufnahmeformular
          geschlossen und kann nicht mehr bearbeitet werden.
        </AlertTitle>
        Bitte bestätigen Sie dies:
        <br></br>
        <br></br>
        <Button
          variant="outlined"
          onClick={() => {
            saveEntryToIDB("workflow", 5, appContext.currentSP);
            appContext.setFormularBlockieren(true);
            appContext.setFormularBeenden(false);
            appContext.setNextTabIndex(5);
            appContext.setSPinProgress(false);

            var loseCopy = JSON.parse(JSON.stringify(appContext.lose));
            appContext.lose.features.forEach((sp, index) => {
              if (
                sp.properties.sp_schluessel ===
                appContext.currentSP.properties.sp_schluessel
              ) {
                loseCopy.features.splice(index, 1);
              }
            });
            saveLose({ data: loseCopy }); // save Lose in DB
            appContext.setLose(loseCopy);
          }}
        >
          Eingabe beenden
        </Button>
        <Button
          style={{ marginLeft: "10px" }}
          variant="outlined"
          onClick={() => {
            appContext.setFormularBeenden(false);
            appContext.setSpAufsucheNichtWald(true);
            appContext.setTransektStoerung(true);
            appContext.setAufnahmeMoeglich(true);
          }}
        >
          Abbrechen
        </Button>
      </Alert>
    </>
  );
}
export default EingabeFormularBeenden;

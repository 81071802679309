//checks if given entry allready exists in IndexedDB
function checkIfEntryExists(checkExisting) {
  if (
    navigator.serviceWorker.controller !== undefined &&
    navigator.serviceWorker.controller !== null
  ) {
    navigator.serviceWorker.controller.postMessage(checkExisting);
  }
}

function saveLose(loseData) {
  let action = {};
  action["type"] = "SAVE_LOSE";
  action["Lose"] = loseData;
  if (
    navigator.serviceWorker.controller !== undefined &&
    navigator.serviceWorker.controller !== null
  ) {
    //console.log("send lose to SW");
    navigator.serviceWorker.controller.postMessage(action);
  }
}

function saveAuthToken(authData) {
  let action = {};
  action["type"] = "SAVE_AUTH_TOKEN";
  action["token"] = authData.token;
  action["username"] = authData.username;
  action["password"] = authData.password;

  if (
    navigator.serviceWorker.controller !== undefined &&
    navigator.serviceWorker.controller !== null
  ) {
    //console.log('send token to SW')
    navigator.serviceWorker.controller.postMessage(action);
  }
}

// save entry

function saveEntryToIDB(formToSave, data, currentSP) {
  var tempSP = JSON.parse(JSON.stringify(currentSP));
  tempSP["type"] = "SAVE_ENTRY_TO_DB";
  tempSP["form"] = formToSave;

  tempSP.properties[formToSave] = data;

  let date = new Date();
  let timeStamp = date.getTime();
  let time = new Date(timeStamp).toLocaleTimeString("de-DE");

  date = date.toISOString().split("T")[0];
  date += " " + time;

  tempSP.properties.aufnahmedatum = date;

  if (
    navigator.serviceWorker.controller !== undefined &&
    navigator.serviceWorker.controller !== null
  ) {
    navigator.serviceWorker.controller.postMessage(tempSP);
  }
}

function updateDBEntrysCoords(formToSave, currentSP, coordinates) {
  var tempSP = JSON.parse(JSON.stringify(currentSP));
  tempSP["type"] = "UPDATE_COORDS";
  tempSP["form"] = formToSave;

  console.log(coordinates);
  if (coordinates !== undefined) {
    console.log(coordinates);
    console.log(tempSP.geometry.coordinates);
    tempSP.geometry.coordinates[0] = coordinates[0];
    tempSP.geometry.coordinates[1] = coordinates[1];
  }
  if (
    navigator.serviceWorker.controller !== undefined &&
    navigator.serviceWorker.controller !== null
  ) {
    navigator.serviceWorker.controller.postMessage(tempSP);
  }
}

// post data to symfony backend
function sendPostToPSQL(currentSP, authToken) {
  var tempSP = JSON.parse(JSON.stringify(currentSP));
  tempSP["type"] = "POST_TO_PSQL";
  tempSP["authToken"] = authToken;

  if (
    navigator.serviceWorker.controller !== undefined &&
    navigator.serviceWorker.controller !== null
  ) {
    navigator.serviceWorker.controller.postMessage(tempSP);
  }
}

// get all entries from eidtedDB and send them to backend
function getAllDBEntries() {
  var tempSPs = {};
  tempSPs["type"] = "GET_ALL_DB_ENTRIES";

  if (
    navigator.serviceWorker.controller !== undefined &&
    navigator.serviceWorker.controller !== null
  ) {
    navigator.serviceWorker.controller.postMessage(tempSPs);
  }
}

function checkEditedData() {
  var tempSPs = {};
  tempSPs["type"] = "CHECK_EDITED_DATA";

  if (
    navigator.serviceWorker.controller !== undefined &&
    navigator.serviceWorker.controller !== null
  ) {
    navigator.serviceWorker.controller.postMessage(tempSPs);
  }
}

function checkUploadedData() {
  var tempSPs = {};
  tempSPs["type"] = "CHECK_UPLOADED_DATA";

  if (
    navigator.serviceWorker.controller !== undefined &&
    navigator.serviceWorker.controller !== null
  ) {
    navigator.serviceWorker.controller.postMessage(tempSPs);
  }
}

function saveTimestamp(timestamp) {
  var tempSPs = {};
  tempSPs["type"] = "SAVE_TIMESTAMP";
  tempSPs["timestamp"] = timestamp;

  if (
    navigator.serviceWorker.controller !== undefined &&
    navigator.serviceWorker.controller !== null
  ) {
    navigator.serviceWorker.controller.postMessage(tempSPs);
  }
}

function getTimestamp() {
  var tempSPs = {};
  tempSPs["type"] = "GET_TIMESTAMP";

  if (
    navigator.serviceWorker.controller !== undefined &&
    navigator.serviceWorker.controller !== null
  ) {
    navigator.serviceWorker.controller.postMessage(tempSPs);
  }
}

export {
  checkIfEntryExists,
  saveEntryToIDB,
  sendPostToPSQL,
  updateDBEntrysCoords,
  saveAuthToken,
  saveLose,
  getAllDBEntries,
  checkEditedData,
  checkUploadedData,
  saveTimestamp,
  getTimestamp,
};

const emptyPlot = [
  {
    nr: 1,
    baumart: "",
    baumartID: "",
    azimut: NaN,
    distanz: NaN,
    bhd: NaN,
    messhoehebhd: 13,
    schaele: false,
    fege: false,
  },
  {
    nr: 2,
    baumart: "",
    baumartID: "",
    azimut: NaN,
    distanz: NaN,
    bhd: NaN,
    messhoehebhd: 13,
    schaele: false,
    fege: false,
  },
  {
    nr: 3,
    baumart: "",
    baumartID: "",
    azimut: NaN,
    distanz: NaN,
    bhd: NaN,
    messhoehebhd: 13,
    schaele: false,
    fege: false,
  },
  {
    nr: 4,
    baumart: "",
    baumartID: "",
    azimut: NaN,
    distanz: NaN,
    bhd: NaN,
    messhoehebhd: 13,
    schaele: false,
    fege: false,
  },
  {
    nr: 5,
    baumart: "",
    baumartID: "",
    azimut: NaN,
    distanz: NaN,
    bhd: NaN,
    messhoehebhd: 13,
    schaele: false,
    fege: false,
  },
];

export default emptyPlot;

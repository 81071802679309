import React, { useState, useEffect, useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
//import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import logo from "../images/LFB-logo.png";
import LosAuswahl from "./LosAuswahl";
import { AuthContext } from "./AuthContext";
import { AppContext } from "./AppContext";
import packageInfo from "../../package.json";
import {
  getAllDBEntries,
  checkEditedData,
  saveTimestamp,
  saveLose,
} from "../swCommunication";
import InputLabel from "@mui/material/InputLabel";

const channel = new BroadcastChannel("sw-messages");

export default function Sidepane(props) {
  const authContext = useContext(AuthContext);
  const mediaIsMobile = useMediaQuery("(max-width:900px)");
  const [drawerOpen, setDrawerOpen] = useState(true);
  const appContext = useContext(AppContext);
  const [spsEditingDone, setSPsEditingDone] = useState([]);
  const [spsEditingNotDone, setSPsEditingNotDone] = useState([]);
  const [showSPs, setShowSPs] = useState(false);
  const [checkDataWhenSent, setCheckDataWhenSent] = useState(false);

  channel.onmessage = function (event) {
    let spCopyDone = [];
    let spCopyNoteDone = [];
    if (event.data?.[0]?.toBeSendCheck) {
      event.data.forEach((sp, index) => {
        if (index > 0) {
          if (sp.properties.workflow === 5) {
            spCopyDone.push(sp);
            appContext.setAllEntriesHaveBeenSendToBackend(false);
            setShowSPs(true);
          } else {
            spCopyNoteDone.push(sp);
            setShowSPs(true);
          }
        }
      });
      setSPsEditingDone(spCopyDone);
      setSPsEditingNotDone(spCopyNoteDone);
      appContext.setMarkInEditingLose(true);
    } else {
      appContext.setAllEntriesHaveBeenSendToBackend(true);
      setShowSPs(false);
    }

    if (event.data?.[0]?.uploadedData) {
      let spUploadedCopy = [];
      event.data.forEach((sp, index) => {
        if (index > 0) {
          spUploadedCopy.push(sp.properties.sp_schluessel);
        }
      });
      appContext.setSPUolpaded(spUploadedCopy);
    }
  };

  useEffect(() => {
    if (checkDataWhenSent) {
      checkEditedData();
    }
    setCheckDataWhenSent(false);
  }, [checkDataWhenSent]);

  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    if (appContext.currentSP !== undefined) {
      setDrawerOpen(false);
    }
  }, [appContext.currentSP]);

  const useStyles = makeStyles((theme) => ({
    drawer: {
      flexGrow: 1,
      zIndex: 1010,
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      backgroundColor: "#fafafa",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      alignContent: "stretch",
    },
    drawerPaper: {
      position: "initial !important",
      boxSizing: "border-box",
      padding: "40px",
    },
    drawerHeader: {
      alignContent: "flex-start",
      display: "flex",
    },
    drawerContent: {},
  }));

  const classes = useStyles();

  useEffect(() => {
    if (
      typeof appContext.lose !== "undefined" &&
      typeof spsEditingNotDone !== "undefined" &&
      appContext.markInEditingLose
    ) {
      var loseCopy = JSON.parse(JSON.stringify(appContext.lose));
      appContext.lose.features.forEach((sp, index) => {
        spsEditingNotDone.forEach((spInEditing) => {
          if (
            sp.properties.sp_schluessel === spInEditing.properties.sp_schluessel
          ) {
            loseCopy.features[index].properties.workflow = 1337;
          }
        });

        if (spsEditingDone.length > 0) {
          spsEditingDone.forEach((spDone) => {
            if (
              sp.properties.sp_schluessel === spDone.properties.sp_schluessel
            ) {
              loseCopy.features.splice(index, 1);
            }
          });
        }
      });
      saveLose({ data: loseCopy }); // save Lose in DB
      appContext.setLose(loseCopy);
    }
    appContext.setMarkInEditingLose(false);
  }, [
    appContext.spsEditingNotDone,
    appContext.lose,
    authContext.authenticated,
    appContext.markInEditingLose,
  ]);

  return (
    <>
      <IconButton
        onClick={handleToggleDrawer}
        style={{
          zIndex: 1011,
          position: "absolute",
          padding: "30px 40px 40px 40px",
          top: "0",
          color: "green",
        }}
      >
        <MenuIcon style={{ outline: "1px solid green" }} />
      </IconButton>
      <div style={{ display: drawerOpen ? "block" : "none" }}>
        <Drawer
          transitionDuration={0}
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          sx={{
            maxWidth: mediaIsMobile ? "40%" : "25%",
            ...props.sx,
            ...(drawerOpen ? {} : { left: mediaIsMobile ? "-40%" : "-25%" }),
          }}
        >
          <br></br>
          <img src={logo} style={{ width: "80%" }} alt={"logo"} />
          <br></br>
          <div style={{ maxWidth: "100%" }}>
            <h3>Losauswahl</h3>
            <LosAuswahl />
            {/*<Button
              variant="contained"
              disabled={!appContext.openGnssPort}
              style={{
                color: "white",
                width: "100%",
                bottom: "20px",
              }}
              onClick={() => {
                appContext.setGnssOption("initialMeasure");
                appContext.setGnssSPEinmessung(true);
              }}
              //(TODO: Fehler abfangen und anzeigen)
            >
              Einmessung starten
            </Button>

            <section>
              {LinearDeterminate()}
              <section style={{ justifyContent: "center", display: "flex" }}>
                <strong>{appContext.gnssMeasurementsDone} / 100</strong>
              </section>
              <p>
                Nach der Einmessung sind die Daten im Formular unter SP-Aufsuche
                sichtbar
              </p>
            </section>
            <br></br>*/}
            <br></br>
            <Divider></Divider>
            <h3>
              Bearbeitungsstand SPs
              <IconButton
                onClick={() => {
                  appContext.setShowBearbeitungsstandSPsHelp(true);
                }}
                style={{
                  zIndex: 1011,
                  //position: "absolute",
                  //padding: "30px 40px 40px 40px",
                  //top: "0",
                  color: "green",
                }}
              >
                <HelpOutlineOutlinedIcon />
              </IconButton>
            </h3>
            <Button
              variant="contained"
              style={{
                color: "white",
                width: "100%",
                bottom: "5px",
              }}
              onClick={(event) => {
                appContext.setRefreshSPs(true);
                checkEditedData();
              }}
            >
              aktualisieren
            </Button>
            <br></br>
            <div
              style={{
                display: showSPs ? "block" : "none",
                whiteSpace: "pre-wrap",
              }}
            >
              <p style={{}}>SPs in Bearbeitung:</p>
              <p style={{ fontSize: "12px" }}>
                {spsEditingNotDone.map(
                  (sp) =>
                    `Unterlos: ${sp.properties.unterlosnr}, Losnummer: ${sp.properties.sp_schluessel}\n`
                )}
              </p>

              <p style={{}}>Zum Versenden vorgemerkte SPs:</p>
              <p style={{ fontSize: "12px" }}>
                {spsEditingDone.map(
                  (sp) =>
                    `Unterlos: ${sp.properties.unterlosnr}, Losnummer: ${sp.properties.sp_schluessel}\n`
                )}
              </p>
            </div>
            <br></br>
            <Button
              variant="contained"
              style={{
                color: "white",
                width: "100%",
                bottom: "5px",
              }}
              disabled={appContext.allEntriesHaveBeenSendToBackend}
              onClick={(event) => {
                getAllDBEntries();
                setCheckDataWhenSent(true);
                appContext.setAllEntriesHaveBeenSendToBackend(true);
                setShowSPs(false);
              }}
            >
              versenden
            </Button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <section
                style={{
                  fontSize: "12px",
                }}
              >
                {appContext.allEntriesHaveBeenSendToBackend // true, wenn keine abgeschlossenen Einträge in editedDB
                  ? "Alle abgeschlossenen Eingaben wurden bereits ans Backend gesendet."
                  : "Es gibt abgeschlossene Eingaben, die noch ans Backend gesendet werden müssen."}
              </section>
            </div>
            <br></br>
            <Divider />
            <h3>
              PWA aktualisieren
              <IconButton
                onClick={() => {
                  appContext.setShowPWAAktualisierenHelp(true);
                }}
                style={{
                  zIndex: 1011,
                  //position: "absolute",
                  //padding: "30px 40px 40px 40px",
                  //top: "0",
                  color: "green",
                }}
              >
                <HelpOutlineOutlinedIcon />
              </IconButton>
            </h3>
            Version {packageInfo.version}
            <IconButton
              onClick={() => {
                appContext.setShowChangelog(true);
              }}
              style={{
                zIndex: 1011,
                //position: "absolute",
                //padding: "30px 40px 40px 40px",
                //top: "0",
                color: "green",
              }}
            >
              <HelpOutlineOutlinedIcon />
            </IconButton>
            <br></br>
            <br></br>
            <Button
              variant="contained"
              style={{
                color: "white",
                width: "100%",
                bottom: "5px",
              }}
              disabled={!appContext.isServiceWorkerUpdated}
              onClick={(event) => {
                appContext.setUpdateSW(true);
              }}
            >
              aktualisieren
            </Button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <section
                style={{
                  fontSize: "12px",
                }}
              >
                {appContext.isServiceWorkerUpdated
                  ? "Eine neue Version ist verfügbar."
                  : "Die Anwendung ist auf dem neuesten Stand."}
              </section>
            </div>
            <br></br>
            <Divider />
            <br></br>
            <br></br>
            <Button
              variant="contained"
              style={{
                backgroundColor: "red",
                color: "white",
                width: "100%",
                bottom: "20px",
              }}
              onClick={(event) => {
                authContext.setWrongCredentials(false);
                authContext.setAuthenticated(false);
              }}
            >
              Logout
            </Button>
          </div>
        </Drawer>
      </div>
    </>
  );
}

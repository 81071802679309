/*
Alerts Verjuengungstransekt
*/
import React, { useContext, useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../AppContext";

function WeiserpflanzenAlert() {
  const appContext = useContext(AppContext);
  return (
    <>
      <Alert
        severity="warning"
        style={{
          flexWrap: "wrap",
          flexDirection: "row wrap",
          margin: "0",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          bottom: "40%",
          zIndex: "505",
        }}
      >
        <AlertTitle>
          In <strong>jeder Bodenvegetationsgruppe</strong> dürfen die
          Prozentangaben der einzelnen Pflanzen <br></br>
          zusammen addiert<strong> 100% nicht überschreiten</strong>.
        </AlertTitle>
        <br></br>
        <strong>In folgender Bodenvegetationsgruppe besteht ein Fehler:</strong>
        <br></br>
        {appContext.weiserpflanzenKraut && (
          <div>
            <strong>Kraut</strong>
            <br></br>
          </div>
        )}
        {appContext.weiserpflanzenGras && (
          <div>
            <strong>Gras - Süß- und Sauergräser, Binsen, Seggen</strong>
            <br></br>
          </div>
        )}
        {appContext.weiserpflanzenBeerenstraeucher && (
          <div>
            <strong>Beersträucher</strong>
            <br></br>
          </div>
        )}
        <br></br>
        {appContext.weiserpflanzenGrossstraeucher && (
          <div>
            <strong>Großsträucher</strong>
            <br></br>
          </div>
        )}

        <Button
          style={{ marginLeft: "10px" }}
          variant="outlined"
          onClick={() => {
            appContext.setShowWeiserpflanzenAlert(false);
          }}
        >
          ok
        </Button>
      </Alert>
    </>
  );
}
export default WeiserpflanzenAlert;

import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "./AppContext";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import emptyStoerungen from "../lib/emptyObjects/emptyStoerungen";
import {
  saveEntryToIDB,
  checkIfEntryExists,
  sendPostToPSQL,
} from "../swCommunication";
import { AuthContext } from "./AuthContext";

const channel = new BroadcastChannel("sw-messages");
export default function Stoerungen() {
  const appContext = useContext(AppContext);
  const authContext = useContext(AuthContext);
  const [stoerungen, setStoerungen] = useState(emptyStoerungen);
  const [saveCheckBox, setSaveCheckBox] = useState(false);

  useEffect(() => {
    if (saveCheckBox) {
      saveEntryToIDB("stoerungen", stoerungen, appContext.currentSP);
      setSaveCheckBox(false);
    }
  }, [saveCheckBox]);

  useEffect(() => {
    if (
      appContext.currentSP !== undefined &&
      appContext.currentTab === "BESTANDSBESCHREIBUNGEN" &&
      appContext.currentSubTab === "STÖRUNGEN"
    ) {
      var checkExisting = {
        type: "GET_ENTRY_FROM_DB",
        id: appContext.currentSP.properties.los_id,
      };

      checkIfEntryExists(checkExisting);
    }
  }, [appContext.currentTab, appContext.currentSubTab, appContext.currentSP]);

  channel.onmessage = function (event) {
    if (
      event.data !== undefined &&
      event.data !== "no entry" &&
      appContext.currentTab === "BESTANDSBESCHREIBUNGEN" &&
      appContext.currentSubTab === "STÖRUNGEN" &&
      event.data?.properties?.los_id === appContext.currentSP.properties.los_id
    ) {
      if (event.data.properties.stoerungen !== undefined) {
        setStoerungen(event.data.properties.stoerungen);
      }
    }
  };

  const handleCheckBoxChange = (objectEntry, value) => {
    setStoerungen({ ...stoerungen, [objectEntry]: value });
  };

  const handleFormularBeenden = () => {
    appContext.setFormularBeenden(true);
  };

  return (
    <>
      <FormGroup sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}>
        <strong>
          Störungen im Bestand, die sich auf die Verjüngung auswirken:
        </strong>
        <FormControlLabel
          control={
            <Checkbox
              checked={stoerungen.stoerungenDurchforstung ?? false}
              onChange={(event) => {
                handleCheckBoxChange(
                  "stoerungenDurchforstung",
                  event.target.checked
                );

                setSaveCheckBox(true);
              }}
            />
          }
          label="Durchforstung"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={stoerungen.stoerungenSanitaerhiebe ?? false}
              onChange={(event) => {
                handleCheckBoxChange(
                  "stoerungenSanitaerhiebe",
                  event.target.checked
                );

                setSaveCheckBox(true);
              }}
            />
          }
          label="Sanitärhiebe"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={stoerungen.stoerungenWaldbrand ?? false}
              onChange={(event) => {
                handleCheckBoxChange(
                  "stoerungenWaldbrand",
                  event.target.checked
                );

                setSaveCheckBox(true);
              }}
            />
          }
          label="Waldbrand"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={stoerungen.stoerungenSturm ?? false}
              onChange={(event) => {
                handleCheckBoxChange("stoerungenSturm", event.target.checked);

                setSaveCheckBox(true);
              }}
            />
          }
          label="Sturm"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={stoerungen.stoerungenBodenbearbeitung ?? false}
              onChange={(event) => {
                handleCheckBoxChange(
                  "stoerungenBodenbearbeitung",
                  event.target.checked
                );

                setSaveCheckBox(true);
              }}
            />
          }
          label="Bodenbearbeitung"
        />
        <TextField
          sx={{ width: "100%" }}
          id="filled-basic"
          label="Weitere Störung:"
          variant="filled"
          value={stoerungen.stoerungenWeitereStoerung}
          onBlur={(event) => {
            saveEntryToIDB("stoerungen", stoerungen, appContext.currentSP);
          }}
          onChange={(event) => {
            setStoerungen({
              ...stoerungen,
              stoerungenWeitereStoerung: event.target.value,
            });
            setSaveCheckBox(true);
          }}
        />
      </FormGroup>
      <br></br>
      <Button
        variant="contained"
        style={{ marginLeft: "10px", width: "100px" }}
        onClick={() => {
          saveEntryToIDB("stoerungen", stoerungen, appContext.currentSP);
          appContext.setCurrentSubTab("BODENVEGETATION");
        }}
      >
        Zurück
      </Button>
      <Button
        variant="contained"
        style={{ marginLeft: "10px", width: "100px" }}
        onClick={() => {
          saveEntryToIDB("stoerungen", stoerungen, appContext.currentSP);
        }}
      >
        Save
      </Button>
      <Button
        align="right"
        color="error"
        variant="contained"
        style={{ marginLeft: "10px", width: "300px" }}
        onClick={() => {
          saveEntryToIDB("workflow", 5, appContext.currentSP);
          handleFormularBeenden();
          appContext.setCurrentTab("undefined");
          appContext.setCurrentSubTab("undefined");
        }}
      >
        Eingabe abschliessen
      </Button>
    </>
  );
}

/*
Baumplots in Karte anzeigen.
*/

import React, { useState, useContext, useEffect, useMemo } from "react";
import Baumplot from "./Baumplot/BaumplotCurrent";
import { AppContext } from "./AppContext";
import * as turf from "@turf/turf";
import getCoordinates from "./Baumplot/util/getCoordinates";

export default function DrawBaumplot({ baumplotData }) {
  const appContext = useContext(AppContext);

  // FeatureCollection mit allen Bäumen aus Baumplot 1 und Baumplot 2
  const baumplotGeojson = useMemo(() => {
    let features = [];
    if (appContext.currentSP !== "" && appContext.currentSP !== undefined) {
      baumplotData.forEach(function (row) {
        if (
          row.baumart !== "" &&
          row.azimut !== "" &&
          row.distanz !== "" &&
          row.bhd !== "" &&
          typeof row.baumart !== "undefined" &&
          typeof row.azimut !== "undefined" &&
          typeof row.distanz !== "undefined" &&
          typeof row.bhd !== "undefined" &&
          row.azimut >= 0 &&
          row.distanz > 0 &&
          row.bhd > 0 &&
          row.bhd !== NaN &&
          row.distanz !== NaN &&
          row.azimut !== NaN
        ) {
          features.push(
            turf.point(
              getCoordinates(
                appContext.currentSP.geometry.coordinates,
                row.distanz,
                row.azimut
              ),
              row
            )
          );
        }
      });
      return {
        type: "FeatureCollection",
        features,
      };
    }
  }, [baumplotData]);

  useEffect(() => {
    if (typeof baumplotGeojson !== "undefined") {
      appContext.setCurrentBaumplot(baumplotGeojson);
    }
  }, [baumplotGeojson]);

  return (
    <>{/* {baumplotGeojson && <Baumplot geojson={baumplotGeojson} />} */}</>
  );
}

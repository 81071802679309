let emptyVerjuengungstransekt = [
  {
    nr: 1,
    verjuengungstransektBaumart: "",
    verjuengungstransektLaenge: "",
    verjuengungstransektBaumartID: "",
    verjuengungstransektHoehenstufe: "",
    verjuengungstransektHoehenstufeID: "",
    verjuengungstransektBHD: "",
    verjuengungstransektSchutzmassnahmen: "",
    verjuengungstransektSchutzmassnahmenID: "",
    verjuengungstransektTriebverlustAn: "",
    verjuengungstransektTriebverlustAnID: "",
    verjuengungstransektTriebverlustDurchSchalenwildverbiss: false,
    verjuengungstransektTriebverlustDurchTrockenheit: false,
    verjuengungstransektTriebverlustDurchInsektenfrass: false,
    verjuengungstransektTriebverlustDurchFrost: false,
    verjuengungstransektTriebverlustDurchSchaeleFege: false,
  },
];

export default emptyVerjuengungstransekt;

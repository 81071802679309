/*
  Stellt Baumplot aus aktueller Eingabe in Karte dar.
*/

import { useMemo } from "react";
import { MlGeoJsonLayer } from "@mapcomponents/react-maplibre";
import * as turf from "@turf/turf";

import MlSymbolLayer from "./MlSymbolLayer";

const BaumplotCurrent = ({ geojson }) => {
  const symbolGeojson = useMemo(() => {
    if (!geojson) return;

    return turf.featureCollection(
      geojson.features.map((el) => {
        el.properties.bp_info = "SP, zweiter Baumplot";
        return el;
      })
    );
  }, [geojson]);

  return (
    <>
      {geojson && (
        <MlGeoJsonLayer
          geojson={geojson}
          paint={{
            "circle-radius": 8,
            "circle-color": "green",
          }}
        />
      )}
      {symbolGeojson && (
        <MlSymbolLayer
          geojson={symbolGeojson}
          type="symbol"
          layout={{
            "text-field": ["get", "bp_info"],
            "text-variable-anchor": ["top", "bottom", "left", "right"],
            "text-radial-offset": 2,
            "text-size": 13,
            "text-justify": "left",
            "text-font": ["Open Sans Regular"],
          }}
        />
      )}
    </>
  );
};

export default BaumplotCurrent;

/* 
- Unter dem Reiter "Allg. Informationen" verbirgt sich die Tabelle tbestbes.
- Unter dem Reiter "Bestockung" verbirgt sich die Tabelle tbestbess - Detaillierte Angaben zur Bestockung OS, ZS, US
-> Componente Bestockung.js

*/

import React, { useState, useContext, useEffect, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AppContext } from "./AppContext";
import Autocomplete from "@mui/material/Autocomplete";
import Bestockung from "./Bestockung";
import Bodenvegetation from "./Bodenvegetation";
import Stoerungen from "./Stoerungen";
import Button from "@mui/material/Button";
import emptyBestandsbeschreibungen from "../lib/emptyObjects/emptyBestandsbeschreibungen";
import { saveEntryToIDB, checkIfEntryExists } from "../swCommunication";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import sortArrayByProperty from "./util/sortArrayByProperty";
import DataFormCheck from "./logischePruefungFormular/DataFormCheck";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const channel = new BroadcastChannel("sw-messages");
export default function Bestandsbeschreibung() {
  const appContext = useContext(AppContext);
  const [bestBeschreibung, setBestBeschreibung] = useState(
    emptyBestandsbeschreibungen
  );

  useEffect(() => {
    if (
      typeof appContext.currentSP !== "undefined" &&
      appContext.currentTab === "BESTANDSBESCHREIBUNGEN" &&
      appContext.currentSubTab === "ALLG. INFORMATIONEN"
    ) {
      var checkExisting = {
        type: "GET_ENTRY_FROM_DB",
        id: appContext.currentSP.properties.los_id,
      };

      checkIfEntryExists(checkExisting);
    }
  }, [appContext.currentTab, appContext.currentSubTab, appContext.currentSP]);

  channel.onmessage = function (event) {
    if (
      event.data !== undefined &&
      event.data !== "no entry" &&
      appContext.currentTab === "BESTANDSBESCHREIBUNGEN" &&
      appContext.currentSubTab === "ALLG. INFORMATIONEN" &&
      event.data?.properties?.los_id === appContext.currentSP.properties.los_id
    ) {
      if (event.data.properties.bestandsBeschreibung !== undefined) {
        setBestBeschreibung(event.data.properties.bestandsBeschreibung);
      }
    }
  };

  let betriebsartArray = useMemo(() => {
    if (appContext.betriebsart !== undefined) {
      let _existingLabels = [];
      let _betriebsartArray = [];
      Object.keys(appContext.betriebsart).forEach(function (
        betriebsartNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.betriebsart[betriebsartNummer].bea_txt.toString()
          ) !== -1 ||
          appContext.betriebsart[betriebsartNummer].bea_txt === "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.betriebsart[betriebsartNummer].bea_txt.toString()
        );
        _betriebsartArray.push({
          label: appContext.betriebsart[betriebsartNummer].bea_txt.toString(),
          id: appContext.betriebsart[betriebsartNummer].id_bea,
        });
      });
      sortArrayByProperty(_betriebsartArray, "id");
      return _betriebsartArray;
    }
  }, [appContext.betriebsart]);

  let kronenschlussgradArray = useMemo(() => {
    if (appContext.kronenschlussgrad !== undefined) {
      let _existingLabels = [];
      let _kronenschlussgradArray = [];
      Object.keys(appContext.kronenschlussgrad).forEach(function (
        kronenschlussgradNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.kronenschlussgrad[
              kronenschlussgradNummer
            ].ksgtxt.toString()
          ) !== -1 ||
          appContext.kronenschlussgrad[kronenschlussgradNummer].ksgtxt ===
            "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.kronenschlussgrad[
            kronenschlussgradNummer
          ].ksgtxt.toString()
        );
        _kronenschlussgradArray.push({
          label:
            appContext.kronenschlussgrad[
              kronenschlussgradNummer
            ].ksgtxt.toString(),
          id: appContext.kronenschlussgrad[kronenschlussgradNummer].id_kssg,
        });
      });
      sortArrayByProperty(_kronenschlussgradArray, "id");
      return _kronenschlussgradArray;
    }
  }, [appContext.kronenschlussgrad]);

  let biotopArray = useMemo(() => {
    if (appContext.biotop !== undefined) {
      let _existingLabels = [];
      let _biotopArray = [];
      Object.keys(appContext.biotop).forEach(function (biotopNummer, i) {
        if (
          _existingLabels.indexOf(
            appContext.biotop[biotopNummer].kurzd.toString()
          ) !== -1 ||
          appContext.biotop[biotopNummer].kurzd === "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(appContext.biotop[biotopNummer].kurzd.toString());
        _biotopArray.push({
          label: appContext.biotop[biotopNummer].kurzd.toString(),
          nameLong: appContext.biotop[biotopNummer].langd.toString(),
          id: appContext.biotop[biotopNummer].icode,
        });
      });
      sortArrayByProperty(_biotopArray, "id");
      return _biotopArray;
    }
  }, [appContext.biotop]);

  let schutzmaßnahmenArray = useMemo(() => {
    if (appContext.schutzmaßnahmen !== undefined) {
      let _existingLabels = [];
      let _schutz = [];
      Object.keys(appContext.schutzmaßnahmen).forEach(function (
        schutzmaßnahmenNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.schutzmaßnahmen[schutzmaßnahmenNummer].smatxt.toString()
          ) !== -1 ||
          appContext.schutzmaßnahmen[schutzmaßnahmenNummer].smatxt ===
            "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.schutzmaßnahmen[schutzmaßnahmenNummer].smatxt.toString()
        );
        _schutz.push({
          label:
            appContext.schutzmaßnahmen[schutzmaßnahmenNummer].smatxt.toString(),
          id: appContext.schutzmaßnahmen[schutzmaßnahmenNummer].id_sma,
        });
      });
      sortArrayByProperty(_schutz, "id");
      return _schutz;
    }
  }, [appContext.schutzmaßnahmen]);

  let schichtArray = useMemo(() => {
    if (appContext.schichtigkeit !== undefined) {
      let _existingLabels = [];
      let _schichtArray = [];
      Object.keys(appContext.schichtigkeit).forEach(function (
        schichtNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.schichtigkeit[schichtNummer].besr.toString()
          ) !== -1 ||
          appContext.schichtigkeit[schichtNummer].besr === "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.schichtigkeit[schichtNummer].besr.toString()
        );
        _schichtArray.push({
          label: appContext.schichtigkeit[schichtNummer].besr.toString(),
          id: appContext.schichtigkeit[schichtNummer].id_bess,
        });
      });
      sortArrayByProperty(_schichtArray, "id");
      return _schichtArray;
    }
  }, [appContext.schichtigkeit]);

  let heterogenitaetsgradArray = useMemo(() => {
    if (appContext.heterogenitaetsgrad !== undefined) {
      let _existingLabels = [];
      let _heterogenitaetsgradArray = [];
      Object.keys(appContext.heterogenitaetsgrad).forEach(function (
        heterogenNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.heterogenitaetsgrad[
              heterogenNummer
            ].heterogenitaetsgrad_txt.toString()
          ) !== -1 ||
          appContext.heterogenitaetsgrad[heterogenNummer]
            .heterogenitaetsgrad_txt === "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.heterogenitaetsgrad[
            heterogenNummer
          ].heterogenitaetsgrad_txt.toString()
        );
        _heterogenitaetsgradArray.push({
          label:
            appContext.heterogenitaetsgrad[
              heterogenNummer
            ].heterogenitaetsgrad_txt.toString(),
          id: appContext.heterogenitaetsgrad[heterogenNummer]
            .id_heterogenitaetsgrad,
        });
      });
      sortArrayByProperty(_heterogenitaetsgradArray, "id");
      return _heterogenitaetsgradArray;
    }
  }, [appContext.heterogenitaetsgrad]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    appContext.setCurrentSubTab(event.target.innerText);
    setValue(newValue);
  };

  const handleAutocompleteChange = (objectEntryID, objectEntry, value) => {
    if (value !== null) {
      setBestBeschreibung({
        ...bestBeschreibung,
        [objectEntryID]: value.id,
        [objectEntry]: value.label,
      });
    } else {
      setBestBeschreibung({
        ...bestBeschreibung,
        [objectEntryID]: "",
        [objectEntry]: "",
      });
    }
  };

  const [save, setSave] = useState(false);

  useEffect(() => {
    if (save) {
      saveEntryToIDB(
        "bestandsBeschreibung",
        bestBeschreibung,
        appContext.currentSP
      );
      setSave(false);
    }
  }, [save]);

  const handleTextInput = (objectEntry, value) => {
    setBestBeschreibung({
      ...bestBeschreibung,
      [objectEntry]: value,
    });
  };

  useEffect(() => {
    if (appContext.currentSubTab === "ALLG. INFORMATIONEN") {
      setValue(0);
    }
    if (appContext.currentSubTab === "BESTOCKUNG") {
      setValue(1);
    }
    if (appContext.currentSubTab === "BODENVEGETATION") {
      setValue(2);
    }
    if (appContext.currentSubTab === "STÖRUNGEN") {
      setValue(3);
    }
  }, [appContext.currentSubTab]);

  return (
    <>
      {appContext.currentTab === "BESTANDSBESCHREIBUNGEN" && (
        <DataFormCheck
          checkData={{ bestandsBeschreibung: bestBeschreibung }}
        ></DataFormCheck>
      )}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab disabled label="Allg. Informationen" {...a11yProps(0)} />
          <Tab disabled label="Bestockung" {...a11yProps(1)} />
          <Tab disabled label="Bodenvegetation" {...a11yProps(2)} />
          <Tab disabled label="Störungen" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TableContainer component={Paper}>
          <Table sx={{ backgroundColor: "#f0f2f0" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong>Betriebsart</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Kronenschlussgrad</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Schutzmaßnahmen im Bestand</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>n-schichtig</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "bestandBetriebsartID",
                        "bestandBetriebsart",
                        value
                      );

                      setSave(true);
                    }}
                    value={
                      betriebsartArray.find(
                        (element) =>
                          element.id === bestBeschreibung.bestandBetriebsartID
                      ) ?? null
                    }
                    disablePortal
                    options={betriebsartArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Betriebsart"
                        error={
                          !appContext.bestandsbeschreibungCheck &&
                          !bestBeschreibung.bestandBetriebsartID
                        }
                        // helperText={
                        //   !appContext.bestandsbeschreibungCheck ? (
                        //     <p style={{ color: "red" }}>Blalalala</p>
                        //   ) : (
                        //     ""
                        //   )
                        // }
                      />
                    )}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "bestandKronenschlussgradID",
                        "bestandKronenschlussgrad",
                        value
                      );

                      setSave(true);
                    }}
                    value={
                      kronenschlussgradArray.find(
                        (element) =>
                          element.id ===
                          bestBeschreibung.bestandKronenschlussgradID
                      ) ?? null
                    }
                    disablePortal
                    options={kronenschlussgradArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Kronenschlussgrad"
                        error={
                          !appContext.bestandsbeschreibungCheck &&
                          !bestBeschreibung.bestandKronenschlussgradID
                        }
                      />
                    )}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "bestandSchutzmassnahmenID",
                        "bestandSchutzmassnahmen",
                        value
                      );

                      setSave(true);
                    }}
                    value={
                      schutzmaßnahmenArray.find(
                        (element) =>
                          element.id ===
                          bestBeschreibung.bestandSchutzmassnahmenID
                      ) ?? null
                    }
                    disablePortal
                    options={schutzmaßnahmenArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Schutzmaßnahmen" />
                    )}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "bestandNschichtigID",
                        "bestandNschichtig",
                        value
                      );

                      setSave(true);

                      // aktuelle Eingabe nschichtig muss für logische Prüfung in Bestockung gespeichert werden
                      appContext.setNSchicht(value.label);
                      // console.log(
                      //   "n-schichtig: " + bestBeschreibung.bestandNschichtig
                      // );
                      appContext.setNSchicht(value.label);
                    }}
                    value={
                      schichtArray.find(
                        (element) =>
                          element.id === bestBeschreibung.bestandNschichtigID
                      )
                        ? schichtArray.find(
                            (element) =>
                              element.id ===
                              bestBeschreibung.bestandNschichtigID
                          )
                        : schichtArray[7]
                    }
                    disablePortal
                    options={schichtArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="n-schichtig"
                        error={
                          !appContext.bestandsbeschreibungCheck &&
                          !bestBeschreibung.bestandNschichtigID
                        }
                      />
                    )}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br></br>
        <br></br>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
            aria-label="simple table"
          >
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <strong>Geschütztes Biotop </strong> - Gehört der
                  Stichprobenpunkt zu einem besonders geschütztem Biotop?
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "bestandBiotopID",
                        "bestandBiotop",
                        value
                      );

                      setSave(true);
                    }}
                    value={
                      biotopArray.find(
                        (element) =>
                          element.id === bestBeschreibung.bestandBiotopID
                      ) ?? null
                    }
                    disablePortal
                    options={biotopArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Biotop"
                        error={
                          !appContext.bestandsbeschreibungCheck &&
                          !bestBeschreibung.bestandBiotopID
                        }
                      />
                    )}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <strong>Bedeckungsgrad </strong>Unterstand
                </TableCell>
                <TableCell align="left">
                  <TextField
                    id="bedeckungsgrad_unterstand"
                    label="Unterstand [%]"
                    error={
                      !appContext.bestandsbeschreibungCheck &&
                      isNaN(
                        bestBeschreibung.bestandBedeckungsgradUnterstand
                      ) === true
                    }
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={String(
                      bestBeschreibung.bestandBedeckungsgradUnterstand
                    )}
                    onBlur={() => {
                      saveEntryToIDB(
                        "bestandsBeschreibung",
                        bestBeschreibung,
                        appContext.currentSP
                      );
                    }}
                    onChange={(event) => {
                      const min = 0;
                      const max = 100;

                      var value = Number(event.target.value);

                      if (value.toString()[0] === "0") {
                        value = value.toString(8);
                      }
                      value = String(value);

                      if (value.length >= 3 && value > max) {
                        value = value.substring(0, 2);
                      } else if (value > max) {
                        value = max;
                      } else if (value < min) value = min;

                      handleTextInput("bestandBedeckungsgradUnterstand", value);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <strong>Bodenbedeckung </strong> mit Gräsern, Kräutern und
                  Sträuchern
                </TableCell>
                <TableCell align="left">
                  <br></br>
                  <TextField
                    id="bedeckungsgrad_graeser"
                    label="Gräsern, Kräutern und Sträuchern [%]"
                    error={
                      !appContext.bestandsbeschreibungCheck &&
                      isNaN(bestBeschreibung.bestandBedeckungsgradGraeser) ===
                        true
                    }
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={String(
                      bestBeschreibung.bestandBedeckungsgradGraeser
                    )}
                    onBlur={() => {
                      saveEntryToIDB(
                        "bestandsBeschreibung",
                        bestBeschreibung,
                        appContext.currentSP
                      );
                    }}
                    onChange={(event) => {
                      const min = 0;
                      const max = 100;

                      var value = Number(event.target.value);

                      if (value.toString()[0] === "0") {
                        value = value.toString(8);
                      }
                      value = String(value);

                      if (value.length >= 3 && value > max) {
                        value = value.substring(0, 2);
                      } else if (value > max) {
                        value = max;
                      } else if (value < min) value = min;

                      handleTextInput("bestandBedeckungsgradGraeser", value);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <strong>Heterogenitätsgrad </strong> - Einschätzung der
                  vertikalen und horizontalen Struktur, 0 (homogen) bis 10
                  (heterogen)
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "bestandHeterogenitaetsgradID",
                        "bestandHeterogenitaetsgrad",
                        value
                      );

                      setSave(true);
                    }}
                    value={
                      heterogenitaetsgradArray.find(
                        (element) =>
                          element.id ===
                          bestBeschreibung.bestandHeterogenitaetsgradID
                      ) ?? null
                    }
                    disablePortal
                    options={heterogenitaetsgradArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="0 bis 10"
                        error={
                          !appContext.bestandsbeschreibungCheck &&
                          !bestBeschreibung.bestandHeterogenitaetsgradID
                        }
                      />
                    )}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {(typeof appContext.bestandsbeschreibungCheck === "undefined" ||
          appContext.bestandsbeschreibungCheck) && <br></br>}
        {!appContext.bestandsbeschreibungCheck && (
          <p
            style={{
              color: "red",
            }}
          >
            Fehlende Eingaben sind rot makiert.
          </p>
        )}
        <div
          style={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            margin: "10px 0px 0px 0px",
            alignItems: "left",
            justifyContent: "center",
          }}
        ></div>
        <div>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={() => {
              saveEntryToIDB(
                "bestandsBeschreibung",
                bestBeschreibung,
                appContext.currentSP
              );
              appContext.setNextTabIndex(3);
              appContext.setCurrentSubTab("TRANSEKTINFO");
            }}
          >
            Zurück
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={() => {
              saveEntryToIDB(
                "bestandsBeschreibung",
                bestBeschreibung,
                appContext.currentSP
              );
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={() => {
              saveEntryToIDB(
                "bestandsBeschreibung",
                bestBeschreibung,
                appContext.currentSP
              );
              if (appContext.bestandsbeschreibungCheck === true) {
                appContext.setCurrentSubTab("BESTOCKUNG");
                appContext.setShowBestandsbeschreibungAlert(false);
              } else {
                //alert
                appContext.setShowBestandsbeschreibungAlert(true);
              }
            }}
          >
            Weiter
          </Button>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* tbestbes */}
        {/* <strong>Detaillierte Angaben zur Bestockung OS, ZS, US</strong> */}
        <Bestockung></Bestockung>
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* tbesbodenveggr */}
        {/* <strong>Detaillierte Angaben zur Bodenvegetation im Bestand</strong> */}
        <Bodenvegetation></Bodenvegetation>
      </TabPanel>
      <TabPanel value={value} index={3}>
        {/* bstrg, Beziehungstabelle an tbestbes */}
        {/* <h4>Störungen im Bestand, die sich auf die Verjüngung auswirken: </h4> */}
        <Stoerungen></Stoerungen>
      </TabPanel>
    </>
  );
}

import React, { useState, useContext, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { AppContext } from "./AppContext";
import TextField from "@mui/material/TextField";
import emptyWeiserpflanzen from "../lib/emptyObjects/emptyWeiserpflanzen";
import { saveEntryToIDB, checkIfEntryExists } from "../swCommunication";
import DataFormCheck from "./logischePruefungFormular/DataFormCheck";

const channel = new BroadcastChannel("sw-messages");
export default function Weiserpflanzen() {
  const appContext = useContext(AppContext);
  let [weiserPflanzen, setWeiserPflanzen] = useState(emptyWeiserpflanzen);

  useEffect(() => {
    if (
      appContext.currentSP !== undefined &&
      appContext.currentTab === "TRANSEKT" &&
      appContext.currentSubTab === "WEISERPFLANZEN"
    ) {
      var checkExisting = {
        type: "GET_ENTRY_FROM_DB",
        id: appContext.currentSP.properties.los_id,
      };

      checkIfEntryExists(checkExisting);
    }
  }, [appContext.currentTab, appContext.currentSubTab, appContext.currentSP]);

  channel.onmessage = function (event) {
    if (
      event.data !== "no entry" &&
      appContext.currentTab === "TRANSEKT" &&
      appContext.currentSubTab === "WEISERPFLANZEN" &&
      event.data?.properties?.los_id === appContext.currentSP.properties.los_id
    ) {
      if (event.data.properties.weiserpflanzen !== undefined) {
        setWeiserPflanzen(event.data.properties.weiserpflanzen);
      }
    }
  };

  const saveWeiserpflanze = (pflanze, value) => {
    let weiserCopy = weiserPflanzen;
    weiserCopy = {
      ...weiserPflanzen,
      [pflanze]: value,
    };
    setWeiserPflanzen(weiserCopy);
  };

  const validateInput = (event) => {
    const min = 0;
    const max = 100;

    var value = Number(event.target.value);

    if (value.toString()[0] === "0") {
      value = value.toString(8);
    }
    value = String(value);

    if (value.length >= 3 && value > max) {
      value = value.substring(0, 2);
    } else if (value > max) {
      value = max;
    } else if (value < min) value = min;

    return value;
  };

  const leftCellStyle = { width: "50%" };

  return (
    <>
      {appContext.currentSubTab === "WEISERPFLANZEN" && (
        <DataFormCheck
          checkData={{ weiserpflanzen: weiserPflanzen }}
        ></DataFormCheck>
      )}
      <br></br>
      <br></br>
      Indikatorpflanzen werden in den einzelnen
      <strong> Bodenvegetationsgruppen</strong> bestimmt.
      <br></br>
      Die Angabe erfolgt in Prozent. Rechnet man die Prozentangaben in einer
      Bodenvegetationsgruppe zusammen, darf der Wert 100 Prozent nicht
      übersteigen.
      <br></br>
      <br></br>
      <strong>Moose</strong>
      <br></br>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: "98%",
            backgroundColor: "#f0f2f0",
            justifyContent: "flex-end",
          }}
          aria-label="simple table"
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Moos </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="moos"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenMoos ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenMoos", value);
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          margin: "10px 0px 0px 0px",
          alignItems: "left",
          justifyContent: "center",
        }}
      ></div>
      <br></br>
      <br></br>
      <strong>Kraut</strong>
      <br></br>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
          aria-label="simple table"
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Brennessel </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="brennessel"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenBrennessel ?? "0"}
                  // === "" ​? 0 ​: weiserPflanzen.weiserpflanzenBrennessel}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenBrennessel", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Goldnessel </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="goldnessel"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenGoldnessel ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenGoldnessel", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Heidekraut </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="heidekraut"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenHeidekraut ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenHeidekraut", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Kleinblütiges Springkraut </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="springkraut"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenSpringkraut ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenSpringkraut", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Maiglöckchen </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="maigloeckchen"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenMaigloeckchen ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenMaigloeckchen", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Schmalblättriges Weidenröschen </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="weidenroeschen"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenWeidenroeschen ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenWeidenroeschen", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Waldmeister </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="waldmeister"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenWaldmeister ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenWaldmeister", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong> Waldsauerklee </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="waldsauerklee"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenWaldsauerklee ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenWaldsauerklee", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Wegerich </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="wegerich"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenWegerich ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenWegerich", value);
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          margin: "10px 0px 0px 0px",
          alignItems: "left",
          justifyContent: "center",
        }}
      />
      <br></br>
      <br></br>
      <strong>Gras - Süß- und Sauergräser, Binsen, Seggen</strong>
      <br></br>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
          aria-label="simple table"
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong> Drahtschmiele </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="drahtschmiele"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenDrahtschmiele ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenDrahtschmiele", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong> Flatterbinse </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="flatterbinse"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenFlatterbinse ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenFlatterbinse", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong> Hainrispengras </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="hainrispengras"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenHainrispengras ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenHainrispengras", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Perlgras </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="
                  perlgras"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenPerlgras ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenPerlgras", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Pfeifengras </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="
                  pfeifengras"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenPfeifengras ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenPfeifengras", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong> Sandrohr </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="sandrohr"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenSandrohr ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenSandrohr", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong> Waldzwenke </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="waldzwenke"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenWaldzwenke ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenWaldzwenke", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong> Winkelsegge </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="winkelsegge"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenWinkelsegge ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenWinkelsegge", value);
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          margin: "10px 0px 0px 0px",
          alignItems: "left",
          justifyContent: "center",
        }}
      ></div>
      <br></br>
      <br></br>
      <strong>Farne</strong>
      <br></br>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
          aria-label="simple table"
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Adlerfarn </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="adlerfarn"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenAdlerfarn ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenAdlerfarn", value);
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          margin: "10px 0px 0px 0px",
          alignItems: "left",
          justifyContent: "center",
        }}
      ></div>
      <br></br>
      <br></br>
      <strong>Doldengewächse</strong>
      <br></br>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
          aria-label="simple table"
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Giersch </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="giersch"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenGiersch ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenGiersch", value);
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          margin: "10px 0px 0px 0px",
          alignItems: "left",
          justifyContent: "center",
        }}
      ></div>
      <br></br>
      <br></br>
      <strong>Beersträucher (klein)</strong> {/*Heidel-, Preiselbeere*/}
      <br></br>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
          aria-label="simple table"
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Heidelbeere </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="heidlebeere"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenHeidelbeere ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenHeidelbeere", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Preiselbeere </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="preiselbeere"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenPreiselbeere ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenPreiselbeere", value);
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          margin: "10px 0px 0px 0px",
          alignItems: "left",
          justifyContent: "center",
        }}
      ></div>
      <br></br>
      <br></br>
      <strong>
        Großsträucher {/* Pfaffenhütchen, Hartriegel, Berberitze, Faulbaum*/}
      </strong>
      <br></br>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
          aria-label="simple table"
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Himbeere </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="himbeere"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenHimbeere ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenHimbeere", value);
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={leftCellStyle}>
                <strong>Brombeere </strong>
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="brombeere"
                  label="Anteil [%]"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={weiserPflanzen.weiserpflanzenBrombeere ?? "0"}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "weiserpflanzen",
                      weiserPflanzen,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    const value = validateInput(event);
                    saveWeiserpflanze("weiserpflanzenBrombeere", value);
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          margin: "10px 0px 0px 0px",
          alignItems: "left",
          justifyContent: "center",
        }}
      ></div>
      <div>
        <Button
          variant="contained"
          style={{ marginLeft: "10px", width: "100px" }}
          onClick={() => {
            saveEntryToIDB(
              "weiserpflanzen",
              weiserPflanzen,
              appContext.currentSP
            );
            appContext.setCurrentSubTab("VERJÜNGUNGSTRANSEKT");
          }}
        >
          Zurück
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: "10px", width: "100px" }}
          onClick={() => {
            saveEntryToIDB(
              "weiserpflanzen",
              weiserPflanzen,
              appContext.currentSP
            );
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: "10px", width: "100px" }}
          onClick={() => {
            saveEntryToIDB(
              "weiserpflanzen",
              weiserPflanzen,
              appContext.currentSP
            );
            if (appContext.weiserpflanzenCheck === true) {
              appContext.setCurrentSubTab("TRANSEKTINFO");
              appContext.setShowWeiserpflanzenAlert(false);
            } else {
              //alert
              appContext.setShowWeiserpflanzenAlert(true);
            }
          }}
        >
          Weiter
        </Button>
      </div>
    </>
  );
}

let emptyStoerungen = {
  stoerungenKeineStoerung: false,
  stoerungenDurchforstung: false,
  stoerungenSanitaerhiebe: false,
  stoerungenWaldbrand: false,
  stoerungenSturm: false,
  stoerungenBodenbearbeitung: false,
  stoerungenWeitereStoerung: "",
};

export default emptyStoerungen;

import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import { AppContext } from "./AppContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import emptySPAufsuche from "../lib/emptyObjects/emptySPAufsuche";
//import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { IconButton } from "@mui/material";
import {
  saveEntryToIDB,
  checkIfEntryExists,
  updateDBEntrysCoords,
} from "../swCommunication";
import DataFormCheck from "./logischePruefungFormular/DataFormCheck";
import Divider from "@mui/material/Divider";
import GNSSHandler from "./GNSSHandler";
import LinearDeterminate from "./LinearDeterminate";
import Switch from "@mui/material/Switch";
import sortArrayByProperty from "./util/sortArrayByProperty";

const channel = new BroadcastChannel("sw-messages");

let copyLon = [];
let copyLat = [];
let copyAcc = [];

export default function SPAufsuche() {
  const appContext = useContext(AppContext);
  const [spAufsuche, setSPAufsuche] = useState(emptySPAufsuche);
  const [saveCheckBox, setSaveCheckBox] = useState(false);

  const showSPAufsucheAlert = () => {
    appContext.setShowSPAufsucheAlert(true);
  };

  useEffect(() => {
    if (saveCheckBox) {
      saveEntryToIDB("spAufsuche", spAufsuche, appContext.currentSP);
      setSaveCheckBox(false);
    }
  }, [saveCheckBox]);

  useEffect(() => {
    if (
      appContext.currentSP !== undefined &&
      appContext.currentTab === "SP-AUFSUCHE"
    ) {
      var checkExisting = {
        type: "GET_ENTRY_FROM_DB",
        id: appContext.currentSP.properties.los_id,
      };

      checkIfEntryExists(checkExisting);
    }
  }, [appContext.currentTab, appContext.currentSubTab, appContext.currentSP]);

  channel.onmessage = function (event) {
    if (
      event.data !== "no entry" &&
      event.data !== undefined &&
      (appContext.currentTab === "SP-AUFSUCHE" ||
        appContext.currentTab === undefined) &&
      event.data?.properties?.los_id === appContext.currentSP.properties.los_id
    ) {
      if (event.data.properties.spAufsuche !== undefined) {
        setSPAufsuche(event.data.properties.spAufsuche);
      }
      if (event.data.properties.spEinmessung !== undefined) {
        setCurrentCoordSPEinmessung(event.data.properties.spEinmessung);
        setGpsAccuracySPEinmessung(
          String(event.data.properties.spEinmessung[2])
        );
      }
      if (event.data.properties.spVerschiebung !== undefined) {
        setCurrentCoordSPEinmessung(event.data.properties.spVerschiebung);
        setGpsAccuracySPVerschiebung(
          String(event.data.properties.spVerschiebung[2])
        );
      }
    }
  };

  let begehbarArray = useMemo(() => {
    if (appContext.begehbar !== undefined) {
      let _existingLabels = [];
      let _begehbar = [];
      Object.keys(appContext.begehbar).forEach(function (begehbarNummer, i) {
        if (
          _existingLabels.indexOf(
            appContext.begehbar[begehbarNummer].kurzd.toString()
          ) !== -1 ||
          appContext.begehbar[begehbarNummer].kurzd === "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.begehbar[begehbarNummer].kurzd.toString()
        );
        _begehbar.push({
          label: appContext.begehbar[begehbarNummer].kurzd.toString(),
          id: appContext.begehbar[begehbarNummer].icode_sbgeh,
        });
      });
      sortArrayByProperty(_begehbar, "id");
      return _begehbar;
    }
  }, [appContext.begehbar]);

  let verlegungArray = useMemo(() => {
    if (appContext.verlegung !== undefined) {
      let _existingLabels = [];
      let _verlegungArray = [];
      Object.keys(appContext.verlegung).forEach(function (verlegungNummer, i) {
        if (
          _existingLabels.indexOf(
            appContext.verlegung[verlegungNummer].verleggt.toString()
          ) !== -1 ||
          appContext.verlegung[verlegungNummer].verleggt === "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.verlegung[verlegungNummer].verleggt.toString()
        );
        _verlegungArray.push({
          label: appContext.verlegung[verlegungNummer].verleggt.toString(),
          id: appContext.verlegung[verlegungNummer].id_verlegg,
        });
      });
      sortArrayByProperty(_verlegungArray, "id");
      return _verlegungArray;
    }
  }, [appContext.verlegung]);

  let nichtwaldArray = useMemo(() => {
    if (appContext.nichtwald !== undefined) {
      let _existingLabels = [];
      let _nichtwaldArray = [];
      Object.keys(appContext.nichtwald).forEach(function (nichtwaldNummer, i) {
        if (
          _existingLabels.indexOf(
            appContext.nichtwald[nichtwaldNummer].landschaftselement.toString()
          ) !== -1 ||
          appContext.nichtwald[nichtwaldNummer].landschaftselement ===
            "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.nichtwald[nichtwaldNummer].landschaftselement.toString()
        );
        _nichtwaldArray.push({
          label:
            appContext.nichtwald[nichtwaldNummer].landschaftselement.toString(),
          id: appContext.nichtwald[nichtwaldNummer].nw_id,
        });
      });
      sortArrayByProperty(_nichtwaldArray, "id");
      return _nichtwaldArray;
    }
  }, [appContext.nichtwald]);

  let permamarkerArray = useMemo(() => {
    if (appContext.permamarker !== undefined) {
      let _existingLabels = [];
      let _permamarkerArray = [];
      Object.keys(appContext.permamarker).forEach(function (
        permamarkerNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.permamarker[permamarkerNummer].markerart.toString()
          ) !== -1 ||
          appContext.permamarker[permamarkerNummer].markerart === "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.permamarker[permamarkerNummer].markerart.toString()
        );
        _permamarkerArray.push({
          label: appContext.permamarker[permamarkerNummer].markerart.toString(),
          id: appContext.permamarker[permamarkerNummer].id_perma,
        });
      });
      sortArrayByProperty(_permamarkerArray, "id");
      return _permamarkerArray;
    }
  }, [appContext.permamarker]);

  const [saveChange, setSaveChange] = useState(false);
  const handleAutocompleteChange = (objectEntry, objectEntryID, value) => {
    if (value !== null) {
      setSPAufsuche({
        ...spAufsuche,
        [objectEntryID]: value.id,
        [objectEntry]: value.label,
      });
    } else {
      setSPAufsuche({
        ...spAufsuche,
        [objectEntryID]: "",
        [objectEntry]: "",
      });
    }

    setSaveChange(true);
  };

  useEffect(() => {
    if (saveChange) {
      saveEntryToIDB("spAufsuche", spAufsuche, appContext.currentSP);
      setSaveChange(false);
    }
  }, [saveChange]);

  useEffect(() => {
    if (appContext.spAufsucheNichtWald === true) {
      setSPAufsuche({
        ...spAufsuche,
        spAufsucheNichtWaldUrsache: "",
      });

      appContext.setSpAufsucheNichtWald(false);
    }

    //saveEntryToIDB("spAufsuche", spAufsuche, appContext.currentSP);
  }, [appContext.spAufsucheNichtWald]);

  const [gpsAccuracy, setGpsAccuracy] = useState("-");
  const [currentCoord, setCurrentCoord] = useState(["-", "-"]);
  const [currentCoordSPEinmessung, setCurrentCoordSPEinmessung] = useState([
    0.0, 0.0,
  ]);

  const [gpsAccuracySPEinmessung, setGpsAccuracySPEinmessung] = useState("-");
  const [currentCoordSPVerschiebung, setCurrentCoordSPVerschiebung] = useState([
    0.0, 0.0,
  ]);

  const [satellitesEinmessung, setSatellitesEinmessung] = useState(undefined);
  const [satellitesVerschiebung, setSatellitesVerschiebung] =
    useState(undefined);

  const [saveCoodsToIDB, setSaveCoordsToIDB] = useState(false);

  useEffect(() => {
    if (saveCoodsToIDB) {
      if (appContext.gnssOption === "initialMeasure" || initialMeasure.current) {
        // currentCoordSPEinmessung[0] & currentCoordSPEinmessung [1] = Positionskoordinaten
        // currentCoordSPEinmessung[2] = Genauigkeit
        // currentCoordSPEinmessung[3] = Anzahl Satelliten
        saveEntryToIDB(
          "spEinmessung",
          currentCoordSPEinmessung,
          appContext.currentSP
        );
      } else if (appContext.gnssOption === "newPosMeasure" || !initialMeasure.current) {
        // currentCoordSPVerschiebung[0] & currentCoordSPVerschiebung [1] = Positionskoordinaten
        // currentCoordSPVerschiebung[2] = Genauigkeit
        // currentCoordSPVerschiebung[3] = Anzahl Satelliten
        saveEntryToIDB(
          "spVerschiebung",
          currentCoordSPVerschiebung,
          appContext.currentSP
        );
      }
      setSaveCoordsToIDB(false);
    }
  }, [saveCoodsToIDB]);

  const [gpsAccuracySPVerschiebung, setGpsAccuracySPVerschiebung] =
    useState("-");

  const getCurrentGeoData = (isEinmessung) => {
    let coordinatesNew = undefined;

    navigator.geolocation.getCurrentPosition(
      (position) => {
        coordinatesNew = [position.coords.longitude, position.coords.latitude];
        setGpsAccuracy(Math.trunc(position.coords.accuracy) + "m");
        setCurrentCoord(coordinatesNew);
        updateDBEntrysCoords(
          "updateCoords",
          appContext.currentSP,
          coordinatesNew
        );
      },
      () => {
        setGpsAccuracy("Fehler, GPS aktiv?");
        setCurrentCoord(["", "\n\n\n Fehler, GPS aktiv?"]);
      },
      { maximumAge: 0, timeout: 2000, enableHighAccuracy: true }
    );
  };

  useEffect(() => {
    if (appContext.gnssOption === "initialMeasure") {
      if (appContext.averageGNSSLocation === undefined) return;
      setCurrentCoordSPEinmessung([
        appContext.averageGNSSLocation.lat,
        appContext.averageGNSSLocation.lon,
        appContext.averageGNSSLocation.accuracy,
        Math.round(appContext.averageGNSSLocation.satellites),
      ]);
      setGpsAccuracySPEinmessung(
        appContext.averageGNSSLocation.accuracy.toFixed(2) + "m"
      );
      setSaveCoordsToIDB(true);
    } else if (appContext.gnssOption === "newPosMeasure") {
      if (appContext.averageGNSSLocation === undefined) return;
      setCurrentCoordSPVerschiebung([
        appContext.averageGNSSLocation.lat,
        appContext.averageGNSSLocation.lon,
        appContext.averageGNSSLocation.accuracy,
        Math.round(appContext.averageGNSSLocation.satellites),
      ]);
      setGpsAccuracySPVerschiebung(
        appContext.averageGNSSLocation.accuracy.toFixed(2) + "m"
      );
      setSaveCoordsToIDB(true);
    }
  }, [appContext.averageGNSSLocation]);

  const [watcher, setWatcher] = useState(undefined);
  const initialMeasure = useRef(false);
  const [measureCount, setMeasureCount] = useState(0);
  const [averagePos, setAveragePos] = useState(undefined);
  const [switchEnableExternal, setSwitchEnableExternal] = useState(true);

  const [lonMeasureMents, setLonMeasureMents] = useState([]);
  const [latMeasureMents, setLatMeasureMents] = useState([]);
  const [accMeasureMents, setAccMeasureMents] = useState([]);

  const startMeasure = () => {
    copyLon = [];
    copyLat = [];
    copyAcc = [];

    let count = 0;
    const id = navigator.geolocation.watchPosition(
      (pos) => {
        if (count >= 100) {
          navigator.geolocation.clearWatch(id);
          calcAveragePos();
        }
        count += 3;
        appContext.setGnssMeasurementsDone(count >= 100 ? 100 : count);
        console.log(pos);

        copyLon.push(pos.coords.longitude);

        copyLat.push(pos.coords.latitude);

        copyAcc.push(pos.coords.accuracy);
      },
      (err) => {
        console.warn("ERROR(" + err.code + "): " + err.message);
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
      }
    );
  };

  function calcAveragePos() {
    const averageLat = copyLat.reduce((a, b) => a + b, 0) / copyLat.length || 0;
    const averageLon = copyLon.reduce((a, b) => a + b, 0) / copyLon.length || 0;
    const averageAccuracy =
      copyAcc.reduce((a, b) => a + b, 0) / copyAcc.length || 0;

    if (initialMeasure.current) {
      setCurrentCoordSPEinmessung([averageLat, averageLon, averageAccuracy]);
      setGpsAccuracySPEinmessung(averageAccuracy);
    } else {
      setCurrentCoordSPVerschiebung([averageLat, averageLon, averageAccuracy]);
      setGpsAccuracySPVerschiebung(averageAccuracy);
    }
    setSaveCoordsToIDB(true);
    console.log({ lat: averageLat, lon: averageLon, acc: averageAccuracy });
  }

  function formatAccuracy(isEinmessung) {
    if (isEinmessung) {
      return gpsAccuracySPEinmessung.replace(".", ",");
    } else {
      return gpsAccuracySPVerschiebung.replace(".", ",");
    }
    return "-";
  }

  return (
    <>
      <DataFormCheck
        checkData={{
          spAufsuche,
        }}
        einmessung={currentCoordSPEinmessung}
      ></DataFormCheck>
      <br></br>
      Informationen zum Aufnahmetrupp:
      <br></br>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
          aria-label="simple table"
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <strong>Aufnahmetrupp </strong> - Kürzel des Trupps
              </TableCell>
              <TableCell align="left">
                <TextField
                  sx={{ width: "100%" }}
                  id="aufnahmetrupp"
                  label="Kürzel:"
                  value={spAufsuche.spAufsucheAufnahmetruppKuerzel}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "spAufsuche",
                      spAufsuche,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    setSPAufsuche({
                      ...spAufsuche,
                      spAufsucheAufnahmetruppKuerzel: event.target.value,
                    });
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <strong>GNSS-Gerät </strong> - Bezeichnung des Gerätes
              </TableCell>
              <TableCell align="left">
                <TextField
                  sx={{ width: "100%" }}
                  id="gnss"
                  label="Bezeichnung:"
                  value={spAufsuche.spAufsucheGNSS}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "spAufsuche",
                      spAufsuche,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    setSPAufsuche({
                      ...spAufsuche,
                      spAufsucheGNSS: event.target.value,
                    });
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                Position abfragen
                <IconButton
                  onClick={() => {
                    appContext.setShowLocationSwitch(true);
                  }}
                  style={{
                    zIndex: 1011,
                    //position: "absolute",
                    //padding: "30px 40px 40px 40px",
                    //top: "0",
                    color: "green",
                  }}
                >
                  <HelpOutlineOutlinedIcon />
                </IconButton>
                <br></br>
                <strong>Standortdatenquelle </strong> - Aktivieren für externe
                GNSS-Daten / Deaktivieren für interne Gerätedaten
              </TableCell>
              <TableCell align="left">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked
                        onChange={(event) => {
                          console.log(event.target.checked);
                          setSwitchEnableExternal(event.target.checked);
                        }}
                      />
                    }
                    label="Externe Standortdaten nutzen"
                  />
                </FormGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          margin: "10px 0px 0px 0px",
          alignItems: "left",
          justifyContent: "center",
        }}
      ></div>
      <br></br>
      Informationen zum Stichprobenpunkt:
      <br></br>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
          aria-label="simple table"
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                Stichprobenpunkt <strong>ist begehbar.</strong>
                <br></br>
                <section>
                  <p>
                    Einmessung am Stichprobenpunkt:<br></br>
                    <br></br>
                    Bitte am Stichprobenpunkt positionieren, den GNSS-Port über
                    die Sidepane <br></br>öffnen und danach ebenfalls in der
                    Sidepane die Einmessung starten.
                    <br></br>Sobald 100 Messungen durchgeführt wurden (Indikator
                    in der Sidepane), <br></br>werden die Daten ausgewertet,
                    gespeichert und hier, rechts dargestellt. <br></br>
                    <br></br>Falls die Messung nach kurzer Zeit nicht startet,
                    bitte alle Programme (z.B. QGIS), <br></br>die aktiv auf den
                    gleichen Port hören, ausschalten und erneut den Port wählen.
                    <br></br>
                    <br></br>
                  </p>
                </section>
              </TableCell>
              <TableCell align="left">
                <br></br>
                <br></br>
                <Button
                  variant="contained"
                  disabled={
                    navigator.serial &&
                    switchEnableExternal &&
                    !appContext.openGnssPort
                      ? true
                      : false
                  }
                  style={{
                    color: "white",
                    width: "100%",
                    bottom: "20px",
                  }}
                  onClick={() => {
                    if (navigator.serial && switchEnableExternal) {
                      appContext.setGnssOption("initialMeasure");
                      appContext.setGnssSPEinmessung(true);
                    } else {
                      initialMeasure.current = true;
                      startMeasure();
                    }
                  }}
                  //(TODO: Fehler abfangen und anzeigen)
                >
                  Einmessung starten
                </Button>
                <section>
                  {(initialMeasure.current ||
                    (navigator.serial && switchEnableExternal)) && (
                    <LinearDeterminate></LinearDeterminate>
                  )}
                  <section
                    style={{ justifyContent: "center", display: "flex" }}
                  >
                    <strong>{appContext.gnssMeasurementsDone} / 100</strong>
                  </section>
                </section>
                <br></br>
                <section>
                  <strong>Eingemessen an Koordinate: </strong>
                  {Number(currentCoordSPEinmessung[0])} /{" "}
                  {Number(currentCoordSPEinmessung[1])}
                </section>
                <section>
                  <strong>Mit Genauigkeit von:</strong>{" "}
                  {navigator.serial && switchEnableExternal
                    ? formatAccuracy(true)
                    : Number(gpsAccuracySPEinmessung).toFixed(3)}
                </section>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                Stichprobenpunkt ist <strong> nicht begehbar </strong>
              </TableCell>
              <TableCell align="left">
                <FormGroup>
                  <Autocomplete
                    inputMode="none"
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "spAufsucheNichtBegehbarUrsache",
                        "spAufsucheNichtBegehbarUrsacheID",
                        value
                      );
                      appContext.setFormularBeenden(true);
                    }}
                    value={
                      begehbarArray.find(
                        (element) =>
                          element.id ===
                          spAufsuche.spAufsucheNichtBegehbarUrsacheID
                      ) ?? null
                    }
                    disablePortal
                    options={begehbarArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Ursache" />
                    )}
                  />
                </FormGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                Stichprobenpunkt liegt auf{" "}
                <strong>dauerhaft unbestockter Fläche</strong> (Straße, See,
                Lagerplatz) <br></br>- SP kann aber nach{" "}
                <strong>Verlegung</strong>, um max. 50 m, zu sinnvoller
                Datenerhebung führen<br></br>
                <br></br>
                <section>
                  <p>
                    Einmessung am verschobenem Stichprobenpunkt:<br></br>
                    <br></br>
                    Bitte am neuen Standort positionieren, ggf. den GNSS-Port
                    über die Sidepane <br></br>öffnen (falls noch nicht
                    geschehen) und danach ebenfalls in der Sidepane die
                    Einmessung starten.
                    <br></br>
                  </p>
                </section>
              </TableCell>
              <TableCell align="left">
                <FormGroup style={{ width: "100%" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={spAufsuche.spAufsucheVerschoben ?? false}
                        onChange={(event) => {
                          appContext.setGnssMeasurementsDone(0);
                          setSPAufsuche({
                            ...spAufsuche,
                            spAufsucheVerschoben:
                              !spAufsuche.spAufsucheVerschoben,
                          });

                          setSaveCheckBox(true);
                        }}
                      />
                    }
                    label="SP MUSS verschoben werden"
                  />
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "spAufsucheVerschobenUrsache",
                        "spAufsucheVerschobenUrsacheID",
                        value
                      );
                    }}
                    value={
                      verlegungArray.find(
                        (element) =>
                          element.id ===
                          spAufsuche.spAufsucheVerschobenUrsacheID
                      ) ?? null
                    }
                    disablePortal
                    options={verlegungArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Ursache" />
                    )}
                  />

                  <br></br>
                  <Divider></Divider>

                  <br></br>
                  <br></br>
                  <Button
                    variant="contained"
                    disabled={
                      navigator.serial &&
                      switchEnableExternal &&
                      !appContext.openGnssPort
                        ? true
                        : !spAufsuche.spAufsucheVerschoben
                        ? true
                        : false
                    }
                    style={{
                      color: "white",
                      width: "100%",
                      bottom: "20px",
                    }}
                    onClick={() => {
                      if (navigator.serial && switchEnableExternal) {
                        appContext.setGnssOption("newPosMeasure");
                        appContext.setGnssSPEinmessung(true);
                      } else {
                        initialMeasure.current = false;
                        startMeasure();
                      }
                    }}
                    //(TODO: Fehler abfangen und anzeigen)
                  >
                    Einmessung starten
                  </Button>
                  <section>
                    {spAufsuche.spAufsucheVerschoben && <LinearDeterminate />}
                    <section
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      {spAufsuche.spAufsucheVerschoben && (
                        <strong>{appContext.gnssMeasurementsDone} / 100</strong>
                      )}
                    </section>
                  </section>
                  <br></br>
                  <section>
                    <strong>Verschobene Koordinate: </strong>
                    {spAufsuche.spAufsucheVerschoben
                      ? Number(currentCoordSPVerschiebung[0]).toFixed(3)
                      : "-"}{" "}
                    /{" "}
                    {spAufsuche.spAufsucheVerschoben
                      ? Number(currentCoordSPVerschiebung[1]).toFixed(3)
                      : "-"}
                  </section>
                  <section>
                    <strong>Mit Genauigkeit von:</strong>{" "}
                    {navigator.serial && switchEnableExternal
                      ? formatAccuracy(false)
                      : Number(gpsAccuracySPVerschiebung).toFixed(3)}
                  </section>
                  <br></br>
                  <Divider></Divider>
                  <br></br>
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={spAufsuche.spAufsuchePermamarker ?? false}
                        onChange={(event) => {
                          setSPAufsuche({
                            ...spAufsuche,
                            spAufsuchePermamarker:
                              !spAufsuche.spAufsuchePermamarker,
                          });

                          setSaveCheckBox(true);
                        }}
                      />
                    }
                    label="SP wurde durch einen Permamarker markiert"
                  /> */}
                  <p>SP wurde durch einen Permamarker markiert:</p>
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "spAufsucheMarkerart",
                        "spAufsucheMarkerartID",
                        value
                      );
                    }}
                    value={
                      permamarkerArray.find(
                        (element) =>
                          element.id === spAufsuche.spAufsucheMarkerartID
                      ) ?? null
                    }
                    disablePortal
                    options={permamarkerArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Markerart" />
                    )}
                  />
                </FormGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                Stichprobenpunkt liegt
                <strong> eindeutig nicht im Wald</strong> und wäre auch nach
                <strong> Verlegung </strong>
                nicht im Wald
              </TableCell>
              <TableCell align="left">
                <FormGroup>
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "spAufsucheNichtWaldUrsache",
                        "spAufsucheNichtWaldUrsacheID",
                        value
                      );
                      appContext.setFormularBeenden(true);
                    }}
                    value={spAufsuche.spAufsucheNichtWaldUrsache ?? null}
                    disablePortal
                    options={nichtwaldArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Ursache" />
                    )}
                  />
                </FormGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          margin: "10px 0px 0px 0px",
          alignItems: "left",
          justifyContent: "center",
        }}
      ></div>
      <div>
        <Button
          variant="contained"
          style={{ marginLeft: "10px", width: "100px" }}
          onClick={() => {
            saveEntryToIDB("spAufsuche", spAufsuche, appContext.currentSP);
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: "10px", width: "100px" }}
          onClick={() => {
            saveEntryToIDB("spAufsuche", spAufsuche, appContext.currentSP);
            console.log(currentCoordSPVerschiebung);
            console.log(currentCoordSPEinmessung);
            if (
              appContext.spAufsucheCheck
              // && (currentCoordSPVerschiebung !== ["-", "-"] || currentCoordSPEinmessung === ["-", "-"]) // ZUM TESTEN OHNE GNSS, ! hinzufügen WENN PRODUKTIV
            ) {
              appContext.setNextTabIndex(2);
              appContext.setCurrentSubTab("BAUMPLOT 1");
              appContext.setSpAufsucheCheck(false);
            } else {
              //alert
              showSPAufsucheAlert();
            }
          }}
        >
          Weiter
        </Button>
      </div>
    </>
  );
}

import React, { useState, useEffect, useContext } from "react";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { AppContext } from "../AppContext";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

export default function Changelog() {
  const appContext = useContext(AppContext);

  return (
    <Dialog open fullWidth maxWidth="sm">
      <Paper elevation={3}>
        <DialogTitle
          style={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            borderBottom: "1px solid black",
          }}
        >
          Changelog
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "white" }}>
          <DialogContentText style={{ color: "black" }}>
            <br></br>
            <b>Sichtbare Neuerungen in Version 3.1</b>
            <ul>
              <li>
                <b>Hilfen</b>
              </li>
              Es gibt nun Hilfe-Icons an wichtigen Punkten in der PWA. Sie
              können bei Problemen Hilfestellungen geben.
              <li>
                <b>Darstellung SPs</b>
              </li>
              SPs, die auf diesem Tablet nicht vollständig bearbeitet wurden,
              werden nun auf der Karte gelb dargestellt. SPs, die bereits
              versendet wurden, werden von der Karte gelöscht.
            </ul>

            <br></br>
          </DialogContentText>
          <Button
            variant="contained"
            style={{
              height: "70%",
              marginTop: "10px",
              marginRight: "10px",
              width: "175px",
            }}
            onClick={() => {
              appContext.setShowChangelog(false);
            }}
          >
            Zurück
          </Button>
        </DialogContent>
      </Paper>
    </Dialog>
  );
}

/*
Alerts Verjuengungstransekt
*/
import React, { useContext, useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../AppContext";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

function VerjuengungstransektAlert() {
  const appContext = useContext(AppContext);
  return (
    <>
      <Alert
        severity="warning"
        style={{
          flexWrap: "wrap",
          flexDirection: "row wrap",
          margin: "0",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          bottom: "40%",
          zIndex: "505",
        }}
      >
        <AlertTitle>
          Ihre Eingaben sind nicht vollständig.
          <br></br>
          <br></br> Bitte überprüfen Sie, ob zu jedem Baum Angaben zur{" "}
          <strong>Baumart und Höhenstufe</strong> gemacht wurden.
          <br></br>
          <br></br>
          Falls Sie bei <strong>"Hoehenstufe [cm]" "über 200"</strong>{" "}
          eingegeben haben, müssen Sie auch den BHD setzen.
          <br></br>
          <br></br>
          Die <strong>Transektlänge</strong> muss angegeben werden.
        </AlertTitle>
        {appContext.verjuengungstransekZeroRowCheck && (
          <div>
            <FormControlLabel
              control={<Checkbox />}
              label="Es sind keine Bäume vorhanden"
              onClick={(event) => {
                appContext.setVerjuengungstransekZeroRow(true);
              }}
            />
            <br></br>
          </div>
        )}
        <Button
          variant="outlined"
          onClick={() => {
            appContext.setShowVerjuengungstransekAlert(false);
            if (appContext.verjuengungstransekCheck === true) {
              appContext.setCurrentSubTab("WEISERPFLANZEN");
            }
          }}
        >
          Die Angaben sind vollständig
        </Button>
        <Button
          style={{ marginLeft: "10px" }}
          variant="outlined"
          onClick={() => {
            appContext.setShowVerjuengungstransekAlert(false);
          }}
        >
          Abbrechen
        </Button>
      </Alert>
    </>
  );
}
export default VerjuengungstransektAlert;

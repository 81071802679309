import React, { useState, useContext, useEffect, useMemo } from "react";
import { AppContext } from "./AppContext";
import {parseNmeaSentence} from "nmea-simple";
import * as turf from "@turf/turf";

let port;
let initial = false;
let nmeaString = "";
let keepReading = true

export default function GNSSHandler() {
  const appContext = useContext(AppContext);
  let lonMeasurements = [];
  let latMeasurements = [];
  let accuracyMeasurements = [];
  let satellites = [];
  let counter = 0;

  useEffect(() => {
    async function openPort() {
      if (
        appContext.openGnssPort &&
        typeof appContext.openGnssPort !== "undefined"
      ) {
        if (appContext.portIsOpened) {
          return;
        }
        let newBaudRate;
        console.log(appContext.baudRate);
        if (
          typeof appContext.baudRate !== "undefined" &&
          appContext.baudRate !== "" &&
          appContext.baudRate !== null
        ) {
          newBaudRate = appContext.baudRate;
        } else {
          newBaudRate = 9600;
        }

        port = await navigator.serial.requestPort();

        port
          .open({ baudRate: newBaudRate })
          .then((openedPort) => {
            keepReading = true;
            appContext.setPortIsOpened(true);
            console.log("opened successfully");
            appContext.setGnssLocationProvider(true);
          })
          .catch((e) => {
            appContext.setOpenGnssPort(false);
            appContext.setPortIsOpened(false);
            console.log("port could not open");
          });
      } else if (
        !appContext.openGnssPort &&
        typeof appContext.openGnssPort !== "undefined" &&
        appContext.portIsOpened
      ) {
        keepReading = false;
        appContext.setGnssLocationProvider(false);
        appContext.setOpenGnssPort(false);
        appContext.setPortIsOpened(false);
      }
    }
    openPort();
  }, [appContext.openGnssPort]);

  /*useEffect(() => {
    async function runEffect() {
      if (
        appContext.portIsOpened &&
        typeof appContext.portIsOpened !== "undefined"
      ) {
        appContext.setGnssLocationProvider(true);
      }
      if (typeof port !== "undefined" && !appContext.openGnssPort) {
        if (
          typeof reader !== "undefined" &&
          typeof readableStreamClosed !== "undefined"
        ) {
          keepReading = false;
          reader.cancel();
          await readableStreamClosed.catch(() => {
            /* Ignore the error 
          });
        }
        console.log("ASDFFFF");
        await port.close();
      }
    }
    runEffect();
  }, [appContext.portIsOpened, appContext.openGnssPort]);*/

  /*useEffect(() => {
    async function spEinmessung() {
      if (
        appContext.gnssSPEinmessung &&
        typeof appContext.gnssSPEinmessung !== "undefined"
      ) {
        appContext.setGnssSPEinmessung(false);
        /*if (
          typeof reader !== "undefined" &&
          typeof readableStreamClosed !== "undefined"
        ) {
          keepReading = false;
          reader.cancel();
          await readableStreamClosed.catch(() => {
             Ignore the error 
          });
        }

        // eslint-disable-next-line no-undef
        const textDecoder = new TextDecoderStream();

        const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
        const reader = textDecoder.readable.getReader();
        while (port.readable && keepReading) {
          try {
            while (true) {
              const { value, done } = await reader.read();
              if (done) {
                // reader.cancel() has been called.
                break;
              }
              //let locationAmount = checkForLocationData();
              counter = increaseCounter();
              appContext.setGnssMeasurementsDone(counter);
              if (counter >= 100) {
                appContext.setPortIsOpened(false);
                appContext.setOpenGnssPort(false);
                // eslint-disable-next-line no-undef
                const textEncoder = new TextEncoderStream();

                reader.cancel();
                await readableStreamClosed.catch(() => {
                  /* Ignore the error 
                });

                await port.close();

                nmeaString.split("\r\n").forEach((line) => {
                  try {
                    const packet = parseNmeaSentence(line);

                    if (
                      packet.sentenceId === "RMC" &&
                      packet.status === "valid"
                    ) {
                      counter++;
                      lonMeasurements.push(Number(packet.longitude));
                      latMeasurements.push(Number(packet.latitude));
                      accuracyMeasurements.push(
                        Number(packet.horizontalDilution)
                      );
                      console.log(
                        "Got location via RMC packet:",
                        packet.latitude,
                        packet.longitude,
                        packet.horizontalDilution
                      );
                    }

                    if (
                      packet.sentenceId === "GGA" &&
                      packet.fixType !== "none"
                    ) {
                      counter++;
                      lonMeasurements.push(Number(packet.longitude));
                      latMeasurements.push(Number(packet.latitude));
                      accuracyMeasurements.push(
                        Number(packet.horizontalDilution)
                      );
                      console.log(
                        "Got location via GGA packet:",
                        packet.latitude,
                        packet.longitude,
                        packet.horizontalDilution
                      );
                    }

                    if (packet.sentenceId === "GSA") {
                      console.log(
                        "There are " +
                          packet.satellites.length +
                          " satellites in view."
                      );
                    }
                  } catch (error) {
                    console.error("Got bad packet:", line, error);
                  }
                });
                calculateAveragePosition();
              }
              nmeaString += value;
            }
          } catch (error) {
            console.log(error);
          } finally {
            reader.releaseLock();
            await port.close();
          }
        }
      }
    }
    spEinmessung();
  }, [appContext.gnssSPEinmessung]);*/

  useEffect(() => {
    if (
      typeof appContext.gnssOption !== "undefined" &&
      typeof appContext.gnssSPEinmessung !== "undefined" &&
      appContext.gnssSPEinmessung &&
      (appContext.gnssOption === "newPosMeasure" ||
        appContext.gnssOption === "initialMeasure")
    ) {
      nmeaString = "";
      initial = true;
    }
  }, [appContext.gnssOption, appContext.gnssSPEinmessung]);

  useEffect(() => {
    async function locationProviderStream() {
      if (
        appContext.gnssLocationProvider &&
        typeof appContext.gnssLocationProvider !== "undefined"
      ) {
        /*if (
          typeof reader !== "undefined" &&
          typeof readableStreamClosed !== "undefined"
        ) {
          keepReading = false;
          reader.cancel();
          await readableStreamClosed.catch(() => {
             Ignore the error 
          });
        }*/
        // eslint-disable-next-line no-undef
        const textDecoder = new TextDecoderStream();

        const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
        const reader = textDecoder.readable.getReader();

        let thisCounter = 0;
        while (true) {
          if (!keepReading) {
            reader.cancel();
            await readableStreamClosed.catch(() => {
              // Ignore the error
            });
            await port.close();
          }
          const { value, done } = await reader.read();
          if (done) {
            // reader.cancel() has been called.
            break;
          }

          // eslint-disable-next-line no-undef
          const textEncoder = new TextEncoderStream();

          let currentPosition = [];

          if (initial) {
            appContext.setGnssMeasurementsDone(thisCounter);
            if (thisCounter >= 100) {
              appContext.setGnssMeasurementsDone(100);
              appContext.setGnssSPEinmessung(false);
              initial = false;
              thisCounter = 0;
              calculateAveragePosition();
            }
            nmeaString.split("\r\n").forEach((line) => {
              try {
                const packet = parseNmeaSentence(line);

                if (packet.sentenceId === "RMC" && packet.status === "valid") {
                  thisCounter += 1;
                  lonMeasurements.push(Number(packet.longitude));
                  latMeasurements.push(Number(packet.latitude));
                  accuracyMeasurements.push(Number(packet.horizontalDilution));
                  /*console.log(
                      "Got location via RMC packet:",
                      packet.latitude,
                      packet.longitude,
                      packet.horizontalDilution
                    );*/
                }

                if (packet.sentenceId === "GGA" && packet.fixType !== "none") {
                  thisCounter += 1;
                  lonMeasurements.push(Number(packet.longitude));
                  latMeasurements.push(Number(packet.latitude));
                  accuracyMeasurements.push(Number(packet.horizontalDilution));
                  /*console.log(
                      "Got location via GGA packet:",
                      packet.latitude,
                      packet.longitude,
                      packet.horizontalDilution
                    );*/
                }

                if (packet.sentenceId === "GSA") {
                  satellites.push(Number(packet.satellites.length));
                }
              } catch (error) {
                //console.error("Got bad packet:", line, error);
              }
            });
          } else {
            nmeaString.split("\r\n").forEach((line) => {
              try {
                const packet = parseNmeaSentence(line);

                if (packet.sentenceId === "RMC" && packet.status === "valid") {
                  counter += 1;
                  currentPosition = [
                    Number(packet.latitude),
                    Number(packet.longitude),
                    Number(packet.horizontalDilution),
                  ];
                  /*console.log(
                      "Got location via RMC packet:",
                      packet.latitude,
                      packet.longitude,
                      packet.horizontalDilution
                    );*/
                }

                if (packet.sentenceId === "GGA" && packet.fixType !== "none") {
                  counter += 1;
                  currentPosition = [
                    Number(packet.latitude),
                    Number(packet.longitude),
                    Number(packet.horizontalDilution),
                  ];
                  /*console.log(
                      "Got location via GGA packet:",
                      packet.latitude,
                      packet.longitude,
                      packet.horizontalDilution
                    );*/
                }

                if (packet.sentenceId === "GSA") {
                  satellites.push(Number(packet.satellites.length));
                }
                nmeaString = "";
              } catch (error) {
                //console.error("Got bad packet:", line, error);
              }
            });
          }

          try {
            const point = turf.point([currentPosition[1], currentPosition[0]]);
            /*const point = turf.point([
                  13.016454869317856, 52.459852681821864,
                ]);*/

            appContext.setGnssAccuracy(currentPosition[2]);
            appContext.setGnssLocation(point);
          } catch (error) {
            //console.log("invalid coordinates")
          }
          //calculateAveragePosition();
          nmeaString += value;
        }
      }
    }
    locationProviderStream();
  }, [appContext.gnssLocationProvider]);

  function increaseCounter() {
    counter += 1;
    return counter;
  }

  function calculateAveragePosition() {
    let averageLat =
      latMeasurements.reduce((a, b) => a + b, 0) / latMeasurements.length || 0;
    let averageLon =
      lonMeasurements.reduce((a, b) => a + b, 0) / lonMeasurements.length || 0;
    let averageAccuracy =
      accuracyMeasurements.reduce((a, b) => a + b, 0) /
        accuracyMeasurements.length || 0;
    let averageSatellites =
      satellites.reduce((a, b) => a + b, 0) / satellites.length || 0;

    const gnssLocation = {
      lat: averageLat,
      lon: averageLon,
      accuracy: averageAccuracy,
      satellites: averageSatellites,
    };
    appContext.setAverageGNSSLocation(gnssLocation);

    latMeasurements = [];
    lonMeasurements = [];
    accuracyMeasurements = [];
    satellites = [];
  }

  return <></>;
}

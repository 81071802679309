/*
  Stellt Landmarkes aus Vorgängerinventur in Karte dar.
*/

import { useMemo } from "react";
import { MlGeoJsonLayer } from "@mapcomponents/react-maplibre";
import * as turf from "@turf/turf";

import MlSymbolLayer from "./MlSymbolLayer";

const Landmarken = ({ geojson }) => {
  const symbolGeojson = useMemo(() => {
    if (!geojson) return;

    return turf.featureCollection(
      geojson.features.map((el) => {
        el.properties.lm_info = el.properties.typ;
        return el;
      })
    );
  }, [geojson]);

  return (
    <>
      {geojson && (
        <MlGeoJsonLayer
          geojson={geojson}
          layout={{}}
          paint={{
            "circle-radius": 8,
            "circle-color": "black",
            "circle-stroke-color": "white",
            "circle-stroke-width": 2,
          }}
        />
      )}
      {symbolGeojson && (
        <MlSymbolLayer
          geojson={symbolGeojson}
          type="symbol"
          layout={{
            "text-field": ["get", "lm_info"],
            "text-variable-anchor": ["top", "bottom", "left", "right"],
            "text-radial-offset": 2,
            "text-size": 13,
            "text-justify": "left",
            "text-font": ["Open Sans Regular"],
          }}
        />
      )}
    </>
  );
};

export default Landmarken;

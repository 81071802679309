import { useEffect, useState } from "react";

// In the long term, this hook should be moved to
// react-map-components-maplibre

function useLocation(props) {
  const [deviceOrientation, setDeviceOrientation] = useState(0);
  const [userLocation, setUserLocation] = useState({});

  const handleOrientation = (event) => {
    setDeviceOrientation(-event.alpha);
  };

  const getLocationError = (err) => {
    console.log("Access of user location denied");
  };

  useEffect(() => {
    // geolocation
    let _watchId = navigator.geolocation.watchPosition(
      setUserLocation,
      getLocationError
    );

    // device orientation
    let _handleOrientation = handleOrientation;
    window.addEventListener("deviceorientation", _handleOrientation);

    return () => {
      window.removeEventListener("deviceorientation", _handleOrientation);
      navigator.geolocation.clearWatch(_watchId);
    };
  }, []);

  return {
    deviceOrientation: deviceOrientation,
    userLocation: userLocation,
  };
}

export default useLocation;

import React, { useState, useContext, useEffect, useMemo } from "react";
import { AppContext } from "./AppContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import emptyVerjuengungstransekt from "../lib/emptyObjects/emptyVerjuengungstransekt";
import { saveEntryToIDB, checkIfEntryExists } from "../swCommunication";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DataFormCheck from "./logischePruefungFormular/DataFormCheck";
import sortArrayByProperty from "./util/sortArrayByProperty";
import DeleteIcon from "@mui/icons-material/Delete";

const channel = new BroadcastChannel("sw-messages");
export default function Verjuengungstransekt() {
  const appContext = useContext(AppContext);
  const [verjuengungstransekt, setVerjuengungsTransekt] = useState(
    emptyVerjuengungstransekt
  );
  const [verjuengungstransektLaenge, setVerjuengungstransektLaenge] =
    useState("");
  const showVerjuengungstransektAlert = () => {
    appContext.setShowVerjuengungstransekAlert(true);
  };
  const [save, setSave] = useState(false);

  useEffect(() => {
    if (save) {
      saveEntryToIDB(
        "verjuengungstransekt",
        verjuengungstransekt,
        appContext.currentSP
      );
      setSave(false);
    }
  }, [save]);

  useEffect(() => {
    if (appContext.transektVerkleinert) {
      setVerjuengungstransektLaenge(20);
    } else {
      setVerjuengungstransektLaenge("");
    }
    saveEntryToIDB(
      "verjuengungstransektLaenge",
      verjuengungstransektLaenge,
      appContext.currentSP
    );
  }, [appContext.transektVerkleinert]);

  useEffect(() => {
    if (
      appContext.currentSP !== undefined &&
      appContext.currentTab === "TRANSEKT" &&
      appContext.currentSubTab === "VERJÜNGUNGSTRANSEKT"
    ) {
      var checkExisting = {
        type: "GET_ENTRY_FROM_DB",
        id: appContext.currentSP.properties.los_id,
      };

      checkIfEntryExists(checkExisting);
    }
  }, [appContext.currentTab, appContext.currentSubTab, appContext.currentSP]);

  channel.onmessage = function (event) {
    if (
      event.data !== "no entry" &&
      appContext.currentTab === "TRANSEKT" &&
      appContext.currentSubTab === "VERJÜNGUNGSTRANSEKT" &&
      event.data?.properties?.los_id === appContext.currentSP.properties.los_id
    ) {
      if (event.data.properties.verjuengungstransekt !== undefined) {
        setVerjuengungsTransekt(event.data.properties.verjuengungstransekt);
      }
      if (event.data.properties.verjuengungstransektLaenge !== undefined) {
        setVerjuengungstransektLaenge(
          event.data.properties.verjuengungstransektLaenge
        );
      }
    }
  };

  let baumartArray = useMemo(() => {
    if (appContext.baumart !== undefined) {
      let _existingLabels = [];
      let _baumartArray = [];
      Object.keys(appContext.baumart).forEach(function (baumartNummer, i) {
        if (
          _existingLabels.indexOf(
            appContext.baumart[baumartNummer].kurzd.toString()
          ) !== -1 ||
          appContext.baumart[baumartNummer].kurzd === "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.baumart[baumartNummer].kurzd.toString()
        );
        _baumartArray.push({
          label: appContext.baumart[baumartNummer].kurzd.toString(),
          nameLong: appContext.baumart[baumartNummer].langd.toString(),
          id: appContext.baumart[baumartNummer].icode_ba,
          idSort: appContext.baumart[baumartNummer].sort_bb,
        });
      });
      sortArrayByProperty(_baumartArray, "idSort");
      return _baumartArray;
    }
  }, [appContext.baumart]);

  let hoehenstufeArray = useMemo(() => {
    if (appContext.hoehenstufe !== undefined) {
      let _existingLabels = [];
      let _hoehen = [];
      Object.keys(appContext.hoehenstufe).forEach(function (
        hoehenstufeNummer,
        i
      ) {
        _existingLabels.push(
          appContext.hoehenstufe[hoehenstufeNummer].h_von +
            " bis " +
            appContext.hoehenstufe[hoehenstufeNummer].h_bis
        );
        _hoehen.push({
          label:
            appContext.hoehenstufe[hoehenstufeNummer].h_von +
            " bis " +
            appContext.hoehenstufe[hoehenstufeNummer].h_bis,
          id: appContext.hoehenstufe[hoehenstufeNummer].id_hohst,
        });
      });
      _hoehen.push({ label: "über 200", id: _hoehen.length + 1 });
      sortArrayByProperty(_hoehen, "id");
      return _hoehen;
    }
  }, [appContext.hoehenstufe]);

  let schutzmaßnahmenArray = useMemo(() => {
    if (appContext.schutzmaßnahmen !== undefined) {
      let _existingLabels = [];
      let _schutz = [];
      Object.keys(appContext.schutzmaßnahmen).forEach(function (
        schutzmaßnahmenNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.schutzmaßnahmen[schutzmaßnahmenNummer].smatxt.toString()
          ) !== -1 ||
          appContext.schutzmaßnahmen[schutzmaßnahmenNummer].smatxt ===
            "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.schutzmaßnahmen[schutzmaßnahmenNummer].smatxt.toString()
        );
        _schutz.push({
          label:
            appContext.schutzmaßnahmen[schutzmaßnahmenNummer].smatxt.toString(),
          id: appContext.schutzmaßnahmen[schutzmaßnahmenNummer].id_sma,
        });
      });
      sortArrayByProperty(_schutz, "id");
      return _schutz;
    }
  }, [appContext.schutzmaßnahmen]);

  let triebverlustArray = useMemo(() => {
    if (appContext.triebverlust !== undefined) {
      let _existingLabels = [];
      let _triebverlust = [];
      Object.keys(appContext.triebverlust).forEach(function (
        triebverlustNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.triebverlust[triebverlustNummer].trverlu_bes.toString()
          ) !== -1 ||
          appContext.triebverlust[triebverlustNummer].trverlu_bes ===
            "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.triebverlust[triebverlustNummer].trverlu_bes.toString()
        );
        _triebverlust.push({
          label:
            appContext.triebverlust[triebverlustNummer].trverlu_bes.toString(),
          id: appContext.triebverlust[triebverlustNummer].id_trverlu,
        });
      });
      sortArrayByProperty(_triebverlust, "id");
      return _triebverlust;
    }
  }, [appContext.triebverlust]);

  let triebverlustOrtArray = useMemo(() => {
    if (appContext.triebverlustOrt !== undefined) {
      let _existingLabels = [];
      let _triebverlustOrt = [];
      Object.keys(appContext.triebverlustOrt).forEach(function (
        triebverlustOrtNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.triebverlustOrt[
              triebverlustOrtNummer
            ].trverlwo.toString()
          ) !== -1 ||
          appContext.triebverlustOrt[triebverlustOrtNummer].trverlwo ===
            "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.triebverlustOrt[triebverlustOrtNummer].trverlwo.toString()
        );
        _triebverlustOrt.push({
          label:
            appContext.triebverlustOrt[
              triebverlustOrtNummer
            ].trverlwo.toString(),
          id: appContext.triebverlustOrt[triebverlustOrtNummer].id_trverlwo,
        });
      });
      sortArrayByProperty(_triebverlustOrt, "id");
      return _triebverlustOrt;
    }
  }, [appContext.triebverlustOrt]);

  const addRow = () => {
    const newVerjuengungstransekt = [...verjuengungstransekt];
    newVerjuengungstransekt.push({
      nr: verjuengungstransekt[verjuengungstransekt.length - 1].nr + 1,
    });
    setVerjuengungsTransekt(newVerjuengungstransekt);

    setSave(true);
  };

  const removeRow = (table, setTable) => {
    if (verjuengungstransekt.length === 1) return;
    const newTableRowsPlotOne = [...verjuengungstransekt];
    newTableRowsPlotOne.pop();
    setVerjuengungsTransekt(newTableRowsPlotOne);

    setSave(true);
  };

  const handleAutocompleteChange = (
    objectEntryID,
    objectEntry,
    value,
    rowNr
  ) => {
    let verjCopy = [...verjuengungstransekt];
    if (value !== null) {
      verjCopy[rowNr] = {
        ...verjuengungstransekt[rowNr],
        [objectEntryID]: value.id,
        [objectEntry]: value.label,
      };
    } else {
      verjCopy[rowNr] = {
        ...verjuengungstransekt[rowNr],
        [objectEntryID]: "",
        [objectEntry]: "",
      };
    }
    setVerjuengungsTransekt(verjCopy);
  };

  const handleInputEvent = (objectEntry, rowNr, value) => {
    let verjCopy = [...verjuengungstransekt];
    verjCopy[rowNr] = {
      ...verjuengungstransekt[rowNr],
      [objectEntry]: value,
    };
    setVerjuengungsTransekt(verjCopy);
  };

  // useEffect(() => {
  //   if (appContext.verjuengungstransekCheck) {
  //     appContext.setShowWeiterButton(true);
  //   } else {
  //     appContext.setStartVerjuengungstransektCheck(false);
  //   }
  // }, [appContext.verjuengungstransekCheck]);

  return (
    <>
      {appContext.startVerjuengungstransektCheck && (
        <DataFormCheck
          checkData={{
            transektVerjuengung: verjuengungstransekt,
            laenge: verjuengungstransektLaenge,
          }}
        ></DataFormCheck>
      )}
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <strong>Nr</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Baumart</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Höhenstufe [cm]</strong>
              </TableCell>
              <TableCell align="center">
                <strong>BHD [mm] bei Bäumen {">"} 2m</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Schutzstatus</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Triebverlust an</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Triebverlust durch *</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(verjuengungstransekt).map((row) => (
              <TableRow
                key={row.nr}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{row.nr}</TableCell>
                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "verjuengungstransektBaumartID",
                        "verjuengungstransektBaumart",
                        value,
                        row.nr - 1
                      );
                    }}
                    value={
                      baumartArray.find(
                        (element) =>
                          element.id === row.verjuengungstransektBaumartID
                      ) ?? null
                    }
                    disablePortal
                    options={baumartArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Baumart" />
                    )}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "verjuengungstransektHoehenstufeID",
                        "verjuengungstransektHoehenstufe",
                        value,
                        row.nr - 1
                      );
                    }}
                    value={
                      hoehenstufeArray.find(
                        (element) =>
                          element.id === row.verjuengungstransektHoehenstufeID
                      ) ?? " "
                    }
                    disablePortal
                    options={hoehenstufeArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Höhenstufe [cm]" />
                    )}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    id="filled-basic"
                    label="BHD in mm"
                    type="number"
                    variant="filled"
                    value={row.verjuengungstransektBHD}
                    onChange={(event, value) => {
                      handleInputEvent(
                        "verjuengungstransektBHD",
                        row.nr - 1,
                        event.target.value
                      );
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "verjuengungstransektSchutzmassnahmenID",
                        "verjuengungstransektSchutzmassnahmen",
                        value,
                        row.nr - 1
                      );
                    }}
                    value={
                      schutzmaßnahmenArray.find(
                        (element) =>
                          element.id ===
                          row.verjuengungstransektSchutzmassnahmenID
                      ) ?? null
                    }
                    disablePortal
                    options={schutzmaßnahmenArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Schutzmaßnahmen" />
                    )}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "verjuengungstransektTriebverlustAnID",
                        "verjuengungstransektTriebverlustAn",
                        value,
                        row.nr - 1
                      );
                    }}
                    value={
                      triebverlustOrtArray.find(
                        (element) =>
                          element.id ===
                          row.verjuengungstransektTriebverlustAnID
                      )
                        ? triebverlustOrtArray.find(
                            (element) =>
                              element.id ===
                              row.verjuengungstransektTriebverlustAnID
                          )
                        : triebverlustOrtArray[3]
                    }
                    disablePortal
                    options={triebverlustOrtArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Triebverlust an" />
                    )}
                  />
                </TableCell>
                <TableCell align="center">
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label="SV  "
                      checked={
                        row.verjuengungstransektTriebverlustDurchSchalenwildverbiss
                      }
                      onChange={(event, value) => {
                        handleInputEvent(
                          "verjuengungstransektTriebverlustDurchSchalenwildverbiss",
                          row.nr - 1,
                          event.target.checked
                        );
                      }}
                    />
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label="F  "
                      checked={row.verjuengungstransektTriebverlustDurchFrost}
                      onChange={(event, value) => {
                        handleInputEvent(
                          "verjuengungstransektTriebverlustDurchFrost",
                          row.nr - 1,
                          event.target.checked
                        );
                      }}
                    />
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label="T  "
                      checked={
                        row.verjuengungstransektTriebverlustDurchTrockenheit
                      }
                      onChange={(event, value) => {
                        handleInputEvent(
                          "verjuengungstransektTriebverlustDurchTrockenheit",
                          row.nr - 1,
                          event.target.checked
                        );
                      }}
                    />
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label="SF  "
                      checked={
                        row.verjuengungstransektTriebverlustDurchSchaeleFege
                      }
                      onChange={(event, value) => {
                        handleInputEvent(
                          "verjuengungstransektTriebverlustDurchSchaeleFege",
                          row.nr - 1,
                          event.target.checked
                        );
                      }}
                    />
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label="I  "
                      checked={
                        row.verjuengungstransektTriebverlustDurchInsektenfrass
                      }
                      onChange={(event, value) => {
                        handleInputEvent(
                          "verjuengungstransektTriebverlustDurchInsektenfrass",
                          row.nr - 1,
                          event.target.checked
                        );
                      }}
                    />
                  </FormGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          margin: "10px 0px 0px 0px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          aria-label="add"
          color="primary"
          onClick={addRow}
          style={{ transform: "scale(1.8)", marginRight: "15px" }}
        >
          <AddCircleIcon />
        </IconButton>

        <IconButton
          aria-label="remove"
          color="error"
          onClick={removeRow}
          style={{ transform: "scale(1.8)", marginLeft: "15px" }}
        >
          <RemoveCircleIcon />
        </IconButton>
      </div>
      <br></br>
      <br></br>* SV = Schalenwildverbiss, F = Frost, T = Trockenheit, SF =
      Schäle/Fege, I = Insektenfraß
      <br></br>
      <br></br>
      <br></br>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
          aria-label="simple table"
        >
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <strong>Transektlänge </strong> in dm
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="transektlaenge"
                  label="Transektlänge [dm]"
                  type="number"
                  value={
                    appContext.transektVerkleinert
                      ? 20
                      : String(verjuengungstransektLaenge)
                      ? String(verjuengungstransektLaenge)
                      : ""
                  }
                  InputProps={{
                    inputProps: {
                      max: 200,
                      min: 0,
                    },
                  }}
                  onBlur={(event) => {
                    saveEntryToIDB(
                      "verjuengungstransektLaenge",
                      verjuengungstransektLaenge,
                      appContext.currentSP
                    );
                  }}
                  onChange={(event) => {
                    //setVerjuengungstransektLaenge(event.target.value);
                    var value = Number(event.target.value);
                    var max = 200;
                    var min = 0;
                    if (value.toString()[0] === "0") {
                      value = value.toString(8);
                    }
                    value = String(value);
                    if (value.length > 3) {
                      value = value.substring(0, 3);
                    } else if (value > max) {
                      value = value.substring(0, 2);
                    } else if (value < min) value = min;

                    setVerjuengungstransektLaenge(value);
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {appContext.transektVerkleinert && (
        <div>
          <p style={{ color: "red" }}>
            Die Transektlänge wurde auf 20 dm gesetzte, da Sie unter dem Reiter
            "Transektanlage" angegeben haben, dass der Transekt verkleinert
            wurde.
          </p>
        </div>
      )}
      <br></br>
      <div
        style={{
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          margin: "10px 0px 0px 0px",
          alignItems: "left",
          justifyContent: "center",
        }}
      ></div>
      <div>
        <Button
          variant="contained"
          style={{ marginLeft: "10px", width: "100px" }}
          onClick={() => {
            saveEntryToIDB(
              "verjuengungstransekt",
              verjuengungstransekt,
              appContext.currentSP
            );
            saveEntryToIDB(
              "verjuengungstransektLaenge",
              verjuengungstransektLaenge,
              appContext.currentSP
            );
            appContext.setCurrentSubTab("TRANSEKTANLAGE");
          }}
        >
          Zurück
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: "10px", width: "100px" }}
          onClick={() => {
            saveEntryToIDB(
              "verjuengungstransekt",
              verjuengungstransekt,
              appContext.currentSP
            );
            saveEntryToIDB(
              "verjuengungstransektLaenge",
              verjuengungstransektLaenge,
              appContext.currentSP
            );
          }}
        >
          Save
        </Button>
        {/* <Button
            // disabled={appContext.startVerjuengungstransektCheck}
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={(event) => {
              setSave(true);
              saveEntryToIDB(
                "verjuengungstransektLaenge",
                verjuengungstransektLaenge,
                appContext.currentSP
              );
              appContext.setStartVerjuengungstransektCheck(true);
              if (appContext.verjuengungstransekCheck === true) {
                appContext.setShowWeiterButton(true);
              } else {
                //alert
                showVerjuengungstransektAlert();
              }
            }}
          >
            Prüfen
          </Button> */}
        {/* {appContext.showWeiterButton && ( */}
        <Button
          //disabled={!appContext.verjuengungstransekCheck}
          variant="contained"
          style={{ marginLeft: "10px", width: "100px" }}
          onClick={(event) => {
            setSave(true);
            saveEntryToIDB(
              "verjuengungstransektLaenge",
              verjuengungstransektLaenge,
              appContext.currentSP
            );
            appContext.setStartVerjuengungstransektCheck(true);
            if (appContext.verjuengungstransekCheck === true) {
              console.log("Prüfung erfolgreich");
              appContext.setCurrentSubTab("WEISERPFLANZEN");
              appContext.setVerjuengungstransekCheck(false);
              appContext.setVerjuengungstransekZeroRow(false);
            } else {
              //appContext.setStartVerjuengungstransektCheck(false);
              showVerjuengungstransektAlert();
            }
          }}
        >
          Weiter
        </Button>
        {/* )} */}
      </div>
    </>
  );
}

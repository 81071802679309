import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { AppContext } from "./AppContext";
import { styled } from "@mui/material/styles";

export default function LinearDeterminate() {
  const [progress, setProgress] = useState(0);
  const appContext = useContext(AppContext);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: "20px",
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 500 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#068c1e" : "#057519",
    },
  }));

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "100%", height: "50%" }}>
      <BorderLinearProgress
        variant="determinate"
        value={appContext.gnssMeasurementsDone}
      />
    </Box>
  );
}

import React, { useRef, useContext } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { AuthContext } from "./AuthContext";
import CircularProgress from "@mui/material/CircularProgress";
import * as Sentry from "@sentry/browser";

export default function Login() {
  const authContext = useContext(AuthContext);
  const emailRef = useRef();
  const passwordRef = useRef();

  return (
    <>
      <div
        style={{
          background: "rgba(0,0,0,0.5)",
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: "100000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {authContext.isLoading && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: "10000000",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            {<CircularProgress style={{ color: "white" }} size={80} />}
          </div>
        )}
        <div
          style={{
            width: "250px",
            backgroundColor: authContext.isLoading ? "#949494" : "#f0f2f0",
            border: "1px solid",
            borderRadius: "5px",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          {/* <p
            style={{
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            Version 3.1
          </p> */}
          <h1
            style={{
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            ANMELDUNG
          </h1>
          <div
            style={{
              color: "black",
              textAlign: "center",
              fontWeight: "bold",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              onFocus={(event) => {
                authContext.setWrongCredentials(false);
              }}
              style={{ margin: "10px" }}
              title="Geben Sie Ihre E-Mail-Adresse ein:"
              ref={emailRef}
              label="E-Mail"
              onChange={(event) => {
                authContext.setUsername(event.target.value);
              }}
            />
            <TextField
              style={{ margin: "10px" }}
              ref={passwordRef}
              type="password"
              title="Geben Sie Ihr Passwort ein"
              label="Passwort"
              onChange={(event) => {
                authContext.setPassword(event.target.value);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={(event) => {
                Sentry.setUser({ email: String(authContext.username) });
                authContext.setWrongCredentials(false);
                authContext.setIsLoading(true);
                emailRef.current.blur();
                passwordRef.current.blur();
                authContext.getJwtTokenFromBackend();
                authContext.setHasLoggedInOnce(true);
              }}
            >
              Bestätigen
            </Button>
          </div>
          {authContext.wrongCredentials === 401 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "red" }}>Falsche E-Mail oder Passwort!</p>
            </div>
          ) : authContext.wrongCredentials === 400 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "red" }}>
                Fehler bei der Anfrage, bitte erneut versuchen! Es besteht keine
                Verbindung oder der Benutzer ist nicht bekannt.
              </p>
            </div>
          ) : authContext.wrongCredentials === 999 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "red" }}>
                Benutzer "{authContext.username}" nicht in der lokalen
                Datenbank. Bitte stellen Sie eine Verbindung her und versuchen
                es erneut.
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

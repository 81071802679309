import React, { useState, useEffect, useContext } from "react";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { AppContext } from "../AppContext";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

export default function PWAAktualisierenHelp() {
  const appContext = useContext(AppContext);

  return (
    <Dialog open fullWidth maxWidth="sm">
      <Paper elevation={3}>
        <DialogTitle
          style={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            borderBottom: "1px solid black",
          }}
        >
          PWA aktualisieren
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "white" }}>
          <DialogContentText style={{ color: "black" }}>
            <br></br>
            Wenn eine neue PWA-Version verfügbar ist, erscheint der Button
            'Aktualisieren' blau. Mit Klick darauf wird die PWA aktualisiert
            OHNE das bereits aufgenommene Daten verloren gehen. <br></br>
            Man kann überprüfen, ob eine neue Version zur Verfügung steht, indem
            man die Seite neu lädt oder sich einmal aus und wieder einloggt.
          </DialogContentText>
          <Button
            variant="contained"
            style={{
              height: "70%",
              marginTop: "10px",
              marginRight: "10px",
              width: "175px",
            }}
            onClick={() => {
              appContext.setShowPWAAktualisierenHelp(false);
            }}
          >
            Zurück
          </Button>
        </DialogContent>
      </Paper>
    </Dialog>
  );
}

let emptyBodenvegetation = [
  {
    nr: 1,
    bodenvegetation: "",
    bodenvegetationID: "",
    bodenvegetationVerteilung: "",
    bodenvegetationVerteilungID: "",
    bodenvegetationAnteil: NaN,
  },
];

export default emptyBodenvegetation;

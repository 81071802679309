import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { saveAuthToken, checkIfEntryExists } from "../swCommunication";

const AuthContext = React.createContext({});
const AuthStateProvider = AuthContext.Provider;

var backendUrl = "/backend";
if (window.location.host === "localhost:3000" || window.location.host === "localhost:8080" ) {
  backendUrl = "https://lfb-dev.wheregroup.com/backend";
}

const channelAuth = new BroadcastChannel("sw-auth-messages");

const AuthContextProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [hasLoggedInOnce, setHasLoggedInOnce] = useState(false); // Zustand, ob SW nach Token gefragt wurde
  const [attemptingLogin, setAttemptingLogin] = useState(undefined);
  const [username, setUsername] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const [jwtToken, setJwtToken] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);

  //useEffect(() => {
  // listens to sw messages on the auth channel
  channelAuth.onmessage = function (event) {
    //console.log('SW MESSAGE');
    //console.log(event);
    if (
      typeof event.data !== "undefined" &&
      typeof event.data.token !== "undefined" &&
      event.data.token !== ""
    ) {
      setWrongCredentials(false);
      setJwtToken(event.data.token);
      setAuthenticated(true);
      setIsLoading(false);
    } else if (event.data === "wrong password") {
      setAuthenticated(false);
      setIsLoading(false);
      setWrongCredentials(401);
    } else if (event.data === "user not found") {
      setAuthenticated(false);
      setIsLoading(false);
      setWrongCredentials(400);
    } else if (event.data === "no token found") {
      setAuthenticated(false);
      setIsLoading(false);
      if (hasLoggedInOnce) {
        setWrongCredentials(999);
      }
    }
    /* else if(typeof event.data.token === "undefined" || event.data.token === "") {
        getJwtTokenFromBackend()
      } */
  };
  //}, []);

  const getJwtTokenFromBackend = () => {
    fetch(backendUrl + "/api/login_check", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((response) => {
        console.log(response);
        if (response.ok && typeof response !== "undefined") {
          setWrongCredentials(false);
          response.json().then((data) => {
            setAttemptingLogin(false);
            if (typeof data.token !== "undefined") {
              setJwtToken(data.token);
              saveAuthToken({
                username: username,
                password: password,
                token: data.token,
              });
              setAuthenticated(true);
              setWrongCredentials(false);
            } else {
              setAuthenticated(false);
              setWrongCredentials(401);
            }
          });
        } else {
          console.log(response);
          if (response.status === 401) {
            setWrongCredentials(401);
          } else if (response.status === 400) {
            setWrongCredentials(400);
          }
          console.log(wrongCredentials);
          setAuthenticated(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        getJwtTokenFromServiceWorker();
      });
  };

  const getJwtTokenFromServiceWorker = () => {
    //console.log('get Token from SW')
    checkIfEntryExists({
      type: "GET_AUTH_TOKEN",
      user: username,
      password: password,
    });
  };

  const getLoseFromServiceWorker = () => {
    //console.log("get Lose from SW");
    checkIfEntryExists({
      type: "GET_LOSE",
    });
  };

  const value = {
    authenticated,
    setAuthenticated,
    hasLoggedInOnce,
    setHasLoggedInOnce,
    attemptingLogin,
    setAttemptingLogin,
    username,
    setUsername,
    password,
    setPassword,
    jwtToken,
    setJwtToken,
    isLoading,
    setIsLoading,
    wrongCredentials,
    setWrongCredentials,
    getJwtTokenFromBackend,
    getJwtTokenFromServiceWorker,
    getLoseFromServiceWorker,
  };

  return <AuthStateProvider value={value}>{children}</AuthStateProvider>;
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthContext, AuthContextProvider };

import React, { useState, useEffect, useContext } from "react";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { AppContext } from "../AppContext";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

export default function GPSHelp() {
  const appContext = useContext(AppContext);

  return (
    <Dialog open fullWidth maxWidth="lg">
      <Paper elevation={3}>
        <DialogTitle
          style={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            borderBottom: "1px solid black",
          }}
        >
          GNSS / GPS Hilfe
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "white" }}>
          <DialogContentText style={{ color: "black" }}>
            <br></br>
            <ul>
              <li>
                <b>
                  Wie stelle ich eine Verbindung zum externen GNSS-Gerät her?
                </b>
              </li>
              <br></br>
              <b style={{ color: "red" }}>
                WICHTIG! Es darf keine andere Anwendung auf den selben COM-Port
                hören, da sonst keine Verbindung hergestellt werden kann.
              </b>
              <br></br>
              <b style={{ color: "red" }}>
                WICHTIG! Für jegliche GPS/GNSS-Interaktion sollte dem Browser
                Zugriff auf die Positionsdaten gewährt werden
              </b>
              <br></br>
              <p style={{ color: "red", display: "inline" }}>
                {" (oben rechts in der Adresszeile über das Positions-Symbol)"}.
              </p>
              <br></br>
              <br></br>
              <ul>
                <li>
                  Um einen externen GNSS-Empfänger mit der PWA nutzen zu können,
                  muss der Button "GNSS-PORT ÖFFNEN" im unteren, linken Bereich
                  der Karte gedrückt werden.
                </li>
                <br></br>
                <li>
                  Wurde der Button gedrückt, erscheint ein neuer Dialog in dem
                  ein COM-Port ausgewählt werden kann.
                </li>
                <br></br>
                <li>
                  Hier muss der entsprechende COM-Port gewählt werden, welcher
                  vom GNSS-Empfänger benutzt werden soll.
                  {" (Dies kann z.B. mit QGIS im Vorfeld getestet werden.)"}
                </li>
                <br></br>
                <li>
                  Nachdem ein COM-Port gewählt wurde, versucht die PWA eine
                  Vebindung mit dem Empfänger herzustellen. Der "GNSS-PORT
                  ÖFFNEN"-Button ändert sich zu einem roten
                  "GNSS-PORT-SCHLIESSEN"-Button und <br></br>
                  <br></br>
                  <li>
                    Ist dies erfolgreich, so bleibt der Button rot und zeigt
                    weiterhin "GNSS-PORT-SCHLIESSEN". Ist der Vebindungsaufbau
                    erfolglos dann wechselt der Button wieder in den
                    ursprünglichen Status "GNSS-PORT ÖFFNEN".{" "}
                  </li>
                  <br></br>
                  <li>
                    Schlug der Verbindungsaufbau fehl, ist der erste Schritt ein
                    erneuter Versuch. Falls es weiterhin nicht gelingt ist zu
                    prüfen: ob das GNSS-Gerät mit dem mobilen Gerät verbunden
                    ist, ob der richtige Port gewählt wurde und ob evtl. eine
                    andere Anwendung auf den entsprechenden COM-Port hört.
                  </li>
                  <br></br>
                </li>
              </ul>
              <br></br>
              <br></br>
              <li>
                <b>Navigation in der Karte</b>
              </li>
              <br></br>
              <ul>
                <li>
                  Zur Navigation zum Punkt kann das GNSS-Gerät und das interne
                  GPS des mobilen Geräts verwendet werden.<br></br> Ist eine
                  Verbindung zu einem GNSS-Empfänger erfolgreich hergestellt
                  wird automatisch die Position des GNSS-Empfänger durch einen
                  Punkt mit der Beschriftung "GNSS - Position" in der Karte
                  angezeigt. Die Entfernung sowie die Genauigkeit werden unten
                  links in der Karte laufend angepasst, sodass eine Navigation
                  vereinfacht wird.
                </li>
                <br></br>
                <li>
                  Zusätzlich besteht die Möglichkeit die interne
                  Positionsbestimmung des mobilen Gerätes hinzuzuschalten.
                  Hierfür muss lediglich unten rechts in der Karte, bei den
                  Navigationswerkzeugen, das Standort-Symbol gedrückt werden
                  (drittes Symbol von unten). Daraufhin wird die Position des
                  Gerätes in der Karte angezeigt und es wird initial zur
                  Position gezoomt.<br></br>Hierfür ist es essenziell, dass dem
                  Browser Zugriff aud die Positionsdaten des Gerätes gewährt
                  wird {" (siehe oben unter 'WICHTIG')"}.
                </li>
                <br></br>
                <li>
                  Falls beide Ortungs-Methoden aktiviert sind werden die Daten
                  des GNSS-Gerätes in der Navigation{" "}
                  {" (in der Karte unten links, Genauigkeit, Entfernung usw)"}{" "}
                  preferiert.
                </li>
              </ul>
            </ul>

            <br></br>
          </DialogContentText>
          <Button
            variant="contained"
            style={{
              height: "70%",
              marginTop: "10px",
              marginRight: "10px",
              width: "175px",
            }}
            onClick={() => {
              appContext.setShowGPSHelp(false);
            }}
          >
            Zurück
          </Button>
        </DialogContent>
      </Paper>
    </Dialog>
  );
}

import React, { useState, useContext, useEffect, useMemo } from "react";
import { AppContext } from "./AppContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import emptyBestockung from "../lib/emptyObjects/emptyBestockung";
import { saveEntryToIDB, checkIfEntryExists } from "../swCommunication";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DataFormCheck from "./logischePruefungFormular/DataFormCheck";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import sortArrayByProperty from "./util/sortArrayByProperty";

const channel = new BroadcastChannel("sw-messages");
export default function Bestockung() {
  const appContext = useContext(AppContext);
  const [bestockung, setBestockung] = useState([...emptyBestockung]);
  //let baumartArray = [];
  //let schichtArray = [];
  //let entstehungsartArray = [];
  //let verteilungArray = [];

  useEffect(() => {
    if (
      appContext.currentSP !== undefined &&
      appContext.currentTab === "BESTANDSBESCHREIBUNGEN" &&
      appContext.currentSubTab === "BESTOCKUNG"
    ) {
      var checkExisting = {
        type: "GET_ENTRY_FROM_DB",
        id: appContext.currentSP.properties.los_id,
      };

      checkIfEntryExists(checkExisting);
    }
  }, [appContext.currentTab, appContext.currentSubTab, appContext.currentSP]);

  channel.onmessage = function (event) {
    if (
      event.data !== "no entry" &&
      appContext.currentTab === "BESTANDSBESCHREIBUNGEN" &&
      appContext.currentSubTab === "BESTOCKUNG" &&
      event.data?.properties?.los_id === appContext.currentSP.properties.los_id
    ) {
      if (event.data.properties.bestockung !== undefined) {
        setBestockung(event.data.properties.bestockung);
      }
    }
  };

  let baumartArray = useMemo(() => {
    if (appContext.baumart !== undefined) {
      let _existingLabels = [];
      let _baumartArray = [];
      Object.keys(appContext.baumart).forEach(function (baumartNummer, i) {
        if (
          _existingLabels.indexOf(
            appContext.baumart[baumartNummer].kurzd.toString()
          ) !== -1 ||
          appContext.baumart[baumartNummer].kurzd === "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.baumart[baumartNummer].kurzd.toString()
        );
        _baumartArray.push({
          label: appContext.baumart[baumartNummer].kurzd.toString(),
          nameLong: appContext.baumart[baumartNummer].langd.toString(),
          id: appContext.baumart[baumartNummer].icode_ba,
          idSort: appContext.baumart[baumartNummer].sort_bb,
        });
      });
      sortArrayByProperty(_baumartArray, "idSort");
      return _baumartArray;
    }
  }, [appContext.baumart]);

  let schichtArray = useMemo(() => {
    if (appContext.schicht !== undefined) {
      let _existingLabels = [];
      let _schicht = [];
      Object.keys(appContext.schicht).forEach(function (schichtNummer, i) {
        if (
          _existingLabels.indexOf(
            appContext.schicht[schichtNummer].schichttxt.toString()
          ) !== -1 ||
          appContext.schicht[schichtNummer].schichttxt === "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.schicht[schichtNummer].schichttxt.toString()
        );
        _schicht.push({
          label: appContext.schicht[schichtNummer].schichttxt.toString(),
          id: appContext.schicht[schichtNummer].id_schicht,
        });
      });
      sortArrayByProperty(_schicht, "id");
      return _schicht;
    }
  }, [appContext.schicht]);

  let entstehungsartArray = useMemo(() => {
    if (appContext.entstehungsart !== undefined) {
      let _existingLabels = [];
      let _entstehung = [];
      Object.keys(appContext.entstehungsart).forEach(function (
        entstehungNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.entstehungsart[entstehungNummer].langd.toString()
          ) !== -1 ||
          appContext.entstehungsart[entstehungNummer].langd === "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.entstehungsart[entstehungNummer].langd.toString()
        );
        _entstehung.push({
          label: appContext.entstehungsart[entstehungNummer].langd.toString(),
          id: appContext.entstehungsart[entstehungNummer].id_entsart,
        });
      });
      sortArrayByProperty(_entstehung, "id");
      return _entstehung;
    }
  }, [appContext.entstehungsart]);

  let verteilungArray = useMemo(() => {
    if (appContext.verteilung !== undefined) {
      let _existingLabels = [];
      let _verteilung = [];
      Object.keys(appContext.verteilung).forEach(function (
        verteilungNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.verteilung[verteilungNummer].vertbes.toString()
          ) !== -1 ||
          appContext.verteilung[verteilungNummer].vertbes === "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.verteilung[verteilungNummer].vertbes.toString()
        );
        _verteilung.push({
          label: appContext.verteilung[verteilungNummer].vertbes.toString(),
          id: appContext.verteilung[verteilungNummer].id_vert,
        });
      });
      sortArrayByProperty(_verteilung, "id");
      return _verteilung;
    }
  }, [appContext.verteilung]);

  let altersstufenArray = useMemo(() => {
    if (appContext.altersstufen !== undefined) {
      let _existingLabels = [];
      let _alterStufen = [];
      Object.keys(appContext.altersstufen).forEach(function (
        altersstufenNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.altersstufen[altersstufenNummer].nastxt.toString()
          ) !== -1 ||
          appContext.altersstufen[altersstufenNummer].nastxt === "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.altersstufen[altersstufenNummer].nastxt.toString()
        );
        _alterStufen.push({
          label: appContext.altersstufen[altersstufenNummer].nastxt.toString(),
          id: appContext.altersstufen[altersstufenNummer].id_nas,
        });
      });
      sortArrayByProperty(_alterStufen, "id");
      return _alterStufen;
    }
  }, [appContext.altersstufen]);

  //let altersstufenArray = [];

  const addRow = () => {
    const newtableRowsPlotOne = [...bestockung];
    newtableRowsPlotOne.push({
      nr: bestockung[bestockung.length - 1].nr + 1,
      bestockungAlterID: 5,
    });
    setBestockung(newtableRowsPlotOne);
  };

  const removeRow = () => {
    if (bestockung.length === 1) return;
    const newTableRowsPlotOne = [...bestockung];
    newTableRowsPlotOne.pop();
    setBestockung(newTableRowsPlotOne);
  };

  const [save, setSave] = useState(false);

  const handleAutocompleteChange = (
    objectEntryID,
    objectEntry,
    value,
    rowNr
  ) => {
    let bestCopy = [...bestockung];
    if (value !== null) {
      bestCopy[rowNr - 1] = {
        ...bestockung[rowNr - 1],
        [objectEntryID]: value.id,
        [objectEntry]: value.label,
      };
    } else {
      bestCopy[rowNr - 1] = {
        ...bestockung[rowNr - 1],
        [objectEntryID]: "",
        [objectEntry]: "",
      };
    }
    setBestockung(bestCopy);
  };

  useEffect(() => {
    if (save) {
      saveEntryToIDB("bestockung", bestockung, appContext.currentSP);
      setSave(false);
    }
  }, [save]);

  const handleTextInput = (objectEntry, value, rowNr) => {
    let bestCopy = [...bestockung];
    bestCopy[rowNr - 1] = {
      ...bestockung[rowNr - 1],
      [objectEntry]: value,
    };
    setBestockung(bestCopy);
  };

  return (
    <>
      {appContext.currentSubTab === "BESTOCKUNG" && (
        <DataFormCheck checkData={{ bestockung: bestockung }}></DataFormCheck>
      )}
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <strong>Nr</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Schicht</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Baumart</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Natürliche Altersklasse</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Entstehungsart</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Verteilung</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Baumartenanteil</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(bestockung).map((row) => (
              <TableRow
                key={row.nr}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{row.nr}</TableCell>

                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "bestockungSchichtID",
                        "bestockungSchicht",
                        value,
                        row.nr
                      );

                      setSave(true);
                    }}
                    value={
                      schichtArray.find(
                        (element) => element.id === row.bestockungSchichtID
                      ) ?? null
                    }
                    disablePortal
                    options={schichtArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Schicht" />
                    )}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "bestockungBaumartID",
                        "bestockungBaumart",
                        value,
                        row.nr
                      );
                      setSave(true);
                    }}
                    value={
                      baumartArray.find(
                        (element) => element.id === row.bestockungBaumartID
                      ) ?? null
                    }
                    disablePortal
                    options={baumartArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Baumart" />
                    )}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "bestockungAlterID",
                        "bestockungAlter",
                        value,
                        row.nr
                      );

                      setSave(true);
                    }}
                    value={
                      row.bestockungAlterID === ""
                        ? altersstufenArray[0]
                        : altersstufenArray.find(
                            (element) => element.id === row.bestockungAlterID
                          )
                    }
                    disablePortal
                    options={altersstufenArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Altersstufen" />
                    )}
                  />
                </TableCell>

                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "bestockungEntstehungsartID",
                        "bestockungEntstehungsart",
                        value,
                        row.nr
                      );

                      setSave(true);
                    }}
                    value={
                      entstehungsartArray.find(
                        (element) =>
                          element.id === row.bestockungEntstehungsartID
                      ) ?? null
                    }
                    disablePortal
                    options={entstehungsartArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{ width: "100%" }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Entstehungsart" />
                    )}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                    onChange={(event, value) => {
                      handleAutocompleteChange(
                        "bestockungVerteilungID",
                        "bestockungVerteilung",
                        value,
                        row.nr
                      );

                      setSave(true);
                    }}
                    value={
                      verteilungArray.find(
                        (element) => element.id === row.bestockungVerteilungID
                      ) ?? null
                    }
                    disablePortal
                    options={verteilungArray}
                    getOptionLabel={(option) => option.label || ""}
                    sx={{
                      width: "100%",
                      "& button[title='Clear']": {
                        visibility: "visible",
                      },
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Verteilung" />
                    )}
                  />
                </TableCell>
                <TableCell align="left">
                  <TextField
                    sx={{ width: "100%" }}
                    id="filled-basic"
                    label="Baumartenanteil in %"
                    variant="filled"
                    value={String(row.bestockungBaumartenanteil) ?? ""}
                    type="number"
                    onBlur={() => {
                      saveEntryToIDB(
                        "bestockung",
                        bestockung,
                        appContext.currentSP
                      );
                    }}
                    onChange={(event) => {
                      const min = 0;
                      const max = 100;

                      var value = Number(event.target.value);

                      if (value.toString()[0] === "0") {
                        value = value.toString(8);
                      }
                      value = String(value);

                      if (value.length >= 3 && value > max) {
                        value = value.substring(0, 2);
                      } else if (value > max) {
                        value = max;
                      } else if (value < min) value = min;

                      handleTextInput(
                        "bestockungBaumartenanteil",
                        value,
                        row.nr
                      );
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          margin: "10px 0px 0px 0px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          aria-label="add"
          color="primary"
          onClick={addRow}
          style={{ transform: "scale(1.8)", marginRight: "15px" }}
        >
          <AddCircleIcon />
        </IconButton>

        <IconButton
          aria-label="remove"
          color="error"
          onClick={removeRow}
          style={{ transform: "scale(1.8)", marginLeft: "15px" }}
        >
          <RemoveCircleIcon />
        </IconButton>
      </div>

      {!appContext.schichtEin && appContext.showBestockungAlert && (
        <>
          <p>
            Der Bestand ist{" "}
            <strong
              style={{
                color: "red",
              }}
            >
              einschichtig
            </strong>
            . Es werden Angaben zu folgenden Schichten erwartet:
          </p>
          <strong
            style={{
              color: "red",
            }}
          >
            {" "}
            - OS und / oder Hauptbestand{" "}
          </strong>
        </>
      )}
      {!appContext.schichtZwei && appContext.showBestockungAlert && (
        <>
          <p>
            Der Bestand ist{" "}
            <strong
              style={{
                color: "red",
              }}
            >
              zweischichtig
            </strong>
            . Es werden Angaben zu folgenden Schichten erwartet:
          </p>
          <strong
            style={{
              color: "red",
            }}
          >
            - OS und / oder Hauptbestand<br></br>- US
          </strong>
        </>
      )}
      {!appContext.schichtZweiU && appContext.showBestockungAlert && (
        <>
          <p>
            Der Bestand ist{" "}
            <strong
              style={{
                color: "red",
              }}
            >
              zweischichtig mit Unterbau
            </strong>
            . Es werden Angaben zu folgenden Schichten erwartet:
          </p>
          <strong
            style={{
              color: "red",
            }}
          >
            - OS und / oder Hauptbestand<br></br>- US
          </strong>
        </>
      )}
      {!appContext.schichtVerjuengung && appContext.showBestockungAlert && (
        <>
          <p>
            Der Bestand ist{" "}
            <strong
              style={{
                color: "red",
              }}
            >
              zweischichtig mit Vorausverjüngung
            </strong>
            . Es werden Angaben zu folgenden Schichten erwartet:
          </p>
          <strong
            style={{
              color: "red",
            }}
          >
            - OS und / oder Hauptbestand<br></br>- Verjüngung unterm Schirm
          </strong>
        </>
      )}
      {!appContext.schichtPlenter && appContext.showBestockungAlert && (
        <>
          <p>
            Der Bestand ist{" "}
            <strong
              style={{
                color: "red",
              }}
            >
              mehrschichtig oder plenterartig
            </strong>
            . Es werden Angaben zu folgenden Schichten erwartet:
          </p>
          <strong
            style={{
              color: "red",
            }}
          >
            - Plenterwald
          </strong>
        </>
      )}
      {!appContext.schichtOMU && appContext.showBestockungAlert && (
        <>
          <p>
            Der Bestand ist{" "}
            <strong
              style={{
                color: "red",
              }}
            >
              aus Ober-, Mittel- und Unterholz
            </strong>
            . Es werden Angaben zu folgenden Schichten erwartet:
          </p>
          <strong
            style={{
              color: "red",
            }}
          >
            - OS und / oder Hauptbestand<br></br>- MH<br></br>- US
          </strong>
        </>
      )}
      {!appContext.schichtZweiUN && appContext.showBestockungAlert && (
        <>
          <p>
            Der Bestand ist{" "}
            <strong
              style={{
                color: "red",
              }}
            >
              zweischichtig mit Überhälter / Nachhiebsrest
            </strong>
            . Es werden Angaben zu folgenden Schichten erwartet:
          </p>
          <strong
            style={{
              color: "red",
            }}
          >
            - OS und / oder Hauptbestand<br></br>- UeH<br></br>- US
          </strong>
        </>
      )}
      {appContext.showBestockungAlert && (
        <>
          <p>
            Es sind immer die Schichtzuordnungen "AB" und "liegend" möglich.
            <br></br> Jede Schicht kann mehrmals angegeben werden, jedoch muss
            der Baumartenanteil je Schicht addiert genau 100 % ergeben.
            Ausnahme: Hauptbestand und OS können zusammen addiert 100 % ergeben.
          </p>
        </>
      )}
      {!appContext.bestockungZeilenCheck && appContext.showBestockungAlert && (
        <p
          style={{
            color: "red",
          }}
        >
          Bitte kontrollieren Sie, ob Sie in jeder Zeile Schicht, Baumart,
          Natürliche Altersklasse, Entstehungsart, Verteilung und
          Baumartenanteil angegeben haben.
        </p>
      )}

      <Button
        variant="contained"
        style={{ marginLeft: "10px", width: "100px" }}
        onClick={() => {
          saveEntryToIDB("bestockung", bestockung, appContext.currentSP);
          appContext.setCurrentSubTab("ALLG. INFORMATIONEN");
        }}
      >
        Zurück
      </Button>
      <Button
        variant="contained"
        style={{ marginLeft: "10px", width: "100px" }}
        onClick={() => {
          saveEntryToIDB("bestockung", bestockung, appContext.currentSP);
        }}
      >
        Save
      </Button>

      <Button
        variant="contained"
        style={{ marginLeft: "10px", width: "100px" }}
        onClick={() => {
          saveEntryToIDB("bestockung", bestockung, appContext.currentSP);
          if (appContext.bestockungCheck === true) {
            appContext.setCurrentSubTab("BODENVEGETATION");
            appContext.setShowBestockungAlert(false);
          } else {
            appContext.setShowBestockungAlert(true);
          }
        }}
      >
        Weiter
      </Button>
      <br></br>
      <br></br>
      <Divider>
        <Chip label="Legende" />
      </Divider>
      <br></br>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Schicht
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: "40%",
                justifyContent: "flex-end",
              }}
              aria-label="simple table"
            >
              <TableHead></TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <strong>liegend</strong>
                  </TableCell>
                  <TableCell align="left">
                    liegender Baum / sehr schräg stehend
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <strong>MH</strong>
                  </TableCell>
                  <TableCell align="left">
                    Zwischenstand, Mittelholz - erkennbar unterhalb des
                    Oberstandes befinden, jedoch mindestens dessen halbe Höhe
                    erreichen und einen Derbholzvorrat aufweisen
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <strong>UeH:</strong>
                  </TableCell>
                  <TableCell align="left">
                    Überhalt - Reste des Vorbestandes mit annähernd
                    gleichmäßiger Verteilung auf der Fläche
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <strong>AB</strong>
                  </TableCell>
                  <TableCell align="left">
                    Altbäume - alte Einzelbäume, die nicht der Schichtart UeH
                    zugeorndet werden können - Restvorrat
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <strong>US</strong>
                  </TableCell>
                  <TableCell align="left">
                    Unterstand - erkennbar unterhalb des Oberstandes befinden,
                    jedoch entweder dessen halbe Höhe nicht erreichen oder noch
                    keinen Derbholzvorrat aufweisen
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <strong>OS</strong>
                  </TableCell>
                  <TableCell align="left">Oberstand</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              width: "40%",
              flexWrap: "wrap",
              display: "flex",
              flexDirection: "row",
              margin: "10px 0px 0px 0px",
              alignItems: "left",
              justifyContent: "center",
            }}
          ></div>
        </AccordionDetails>
      </Accordion>
      <Divider variant="middle" />
      <br></br>
      <br></br>
    </>
  );
}

/*
Gegeben: Coordinates vom SP, Azimut, Distanz
Gesucht: Lat2 und Long2 bzw. Coordinates vom eingetragenem Baum

Erdradius = 6364573 m (berechnet mit Breitengrad 53 und 34 m über Meeresspiegel bezogen auf Berlin

Zur Orientierung:
Berlin, latitude 52.520008 and longitude 13.404954
*/
//let erdradius = 637100000;

function degrees_to_radians(degrees) {
  var pi = Math.PI;
  return degrees * (pi / 180);
}

function radians_to_degrees(radians) {
  var pi = Math.PI;
  return radians * (180 / pi);
}

const getCoordinates = (coordinatesSP, distanz, azimut) => {
  /*let lat1 = coordinatesSP[1];
    console.log("lat1: " + lat1)
    let long1 = coordinatesSP[0];
    console.log("long1: " + long1)
    console.log("distanz: " + distanz)
    console.log("azimut: " + azimut) 
    let b = distanz / erdradius;
    console.log("b" + b)
    let a = Math.acos(
      (Math.cos(b) * Math.cos(90 - lat1)) +
        (Math.sin(90 - lat1) * Math.sin(b) * Math.cos(azimut))
    );
    console.log("a" + a)
    let c = Math.asin(Math.sin(b) * (Math.sin(azimut) / Math.sin(a)));
    console.log("c" + c)
    let lat2 = 90 - a;
    let long2 = c + long1;
    console.log("lat2: " + lat2);
    console.log("long2: " + long2);*/

  // Erdradius muss höchstwahrscheinlich noch angepasst werden
  let earthRadius = 6378.173; //6378.1;
  let bearing = degrees_to_radians(azimut); // in radians
  let d = distanz / 100000; // Distance in km, 1 cm = 1 / 100000 km

  let lat1 = degrees_to_radians(coordinatesSP[1]);
  let lon1 = degrees_to_radians(coordinatesSP[0]);

  let lat2 = Math.asin(
    Math.sin(lat1) * Math.cos(d / earthRadius) +
      Math.cos(lat1) * Math.sin(d / earthRadius) * Math.cos(bearing)
  );

  let lon2 =
    lon1 +
    Math.atan2(
      Math.sin(bearing) * Math.sin(d / earthRadius) * Math.cos(lat1),
      Math.cos(d / earthRadius) - Math.sin(lat1) * Math.sin(lat2)
    );

  lat2 = radians_to_degrees(lat2);
  lon2 = radians_to_degrees(lon2);

  return [lon2, lat2];
};;;

export default getCoordinates;

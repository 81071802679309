import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { MapComponentsProvider } from "@mapcomponents/react-maplibre";
import { AppContextProvider } from "./components/AppContext";
import { AuthContextProvider } from "./components/AuthContext";
import configureStore from "./store";
import { SW_INIT, SW_UPDATE } from "./type";
import packageInfo from "../package.json";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://c3956750a0aa473bbb439439c40202d9@o1270530.ingest.sentry.io/6461474",
  integrations: [new BrowserTracing()],
  initialScope: {
    tags: { versionsnummer: packageInfo.version },
    //user: { id: 42, email: "john.doe@example.com" },
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppContextProvider>
        <AuthContextProvider>
          <MapComponentsProvider>
            <App />
          </MapComponentsProvider>
        </AuthContextProvider>
      </AppContextProvider>
    </Provider>
  </React.StrictMode>,

  document.getElementById("root")
);

//store.dispatch({ type: SW_INIT })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: (registration) =>
    store.dispatch({ type: SW_UPDATE, payload: registration }),
});

let emptyTransektAnlage = {
  transektWieGewohnt: false,
  transektVerkleinert: false,
  transektStoerungUrsache: "",
  transektGeschuetzt: false,
  schutzmaßnahme: "",
  schutzmaßnahmeID: "",
};

export default emptyTransektAnlage;

/*
Der zweite Baumplot orientiert sich an einem zweiten "SP" der aus Azimut des Transektes und Transektlänge berechnet wird.
*/

import React, { useState, useContext, useEffect, useMemo } from "react";
import Baumplot from "./Baumplot/SecondSP";
import { AppContext } from "./AppContext";
import * as turf from "@turf/turf";
import getCoordinates from "./Baumplot/util/getCoordinates";

export default function DrawSecondSP({ spData }) {
  const appContext = useContext(AppContext);

  const spGeojson = useMemo(() => {
    let features = [];
    if (
      spData !== "" &&
      spData !== undefined &&
      spData !== NaN &&
      appContext.currentSP.geometry.coordinates !== "" &&
      appContext.currentSP.geometry.coordinates !== undefined
    ) {
      features.push(
        turf.point(
          getCoordinates(
            appContext.currentSP.geometry.coordinates,
            2200,
            spData
          )
        )
      );
    }

    return {
      type: "FeatureCollection",
      features,
    };
  }, [spData]);

  appContext.setCurrentSecondSP(spGeojson); // für Berechnun BP 2

  return <>{spGeojson && <Baumplot geojson={spGeojson} />}</>;
}

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const AppContext = React.createContext({});

const AppStateProvider = AppContext.Provider;

const AppContextProvider = ({ children }) => {
  const API_URL = "/";

  // SPs
  const [currentSP, setCurrentSP] = useState(undefined);
  const [currentSecondSP, setCurrentSecondSP] = useState(undefined);
  const [currentAzimut, setCurrentAzimut] = useState(undefined);
  // Lose
  const [sortedHauptlose, setSortedHauptlose] = useState(undefined); // Array mit Hauptlosen
  const [sortedLose, setSortedLose] = useState(undefined); // JS Object mit allen SP's sortiert nach Unterlosen sortiert nach losen
  // Map
  //const [waldflaechen, setWaldflaechen] = useState(undefined); // g_wld.geojson
  //const [kreise, setKreise] = useState(undefined); // g_lkrs_brb.geojson
  const [rettungspunkte, setRettungspunkte] = useState(undefined); // rettungspunkte_brb.geojson
  const [rettungswege, setRettungswege] = useState(undefined); // rettungswege_brb.geojson
  const [lose, setLose] = useState(undefined); // v_los.geojson, nun über Backend
  // Aufsuchen des SP - SP-Info
  const [begehbar, setBegehbar] = useState(undefined); // begeh - Punkt nicht begehbar, s_begeh.json
  const [biotop, setBiotop] = useState(undefined); // biotop - Biotop liegt vor, biotop.json
  const [nichtwald, setNichtwald] = useState(undefined); // nichtwald - Punkt liegt eindeutig nicht im Wald und wäre auch nach Verlegen nicht im Wald: Erfassung des Grundes entsprechend Schlüsseltabelle, s_nichtwald.json
  const [permamarker, setPermamarker] = useState(undefined); // perma - der SP manchmal durch einen Permamarker (Eisen oder Magnet im Waldboden) permanent markiert werden - dann ist die Art des Permamarkers zu vermerken, s_perma.json
  const [verlegung, setVerlegung] = useState(undefined); // verlegg - Verlegung, Punkt liegt auf dauerhaft unbestockter Fläche (Straße, See, Lagerplatz) kann aber nach verlegen, um max. 50 m, zu sinnvoller Datenerhebung führen: Punktverlegung und Erfassung des Grundes entsprechend Schlüsseltabelle: sverlegg, s_verlegg.json
  // Baumplots und Transektanlage
  const [baumplotFeatures, setBaumplotFeatures] = useState(undefined); // Baumplot welches gerade im Formular bearbeitet wird und auf der Karte ausgegeben
  const [baumplottData, setBaumplottData] = useState(undefined); // Baumplottdata zum Einzeichnen in die Karte aus VORGÄNGERINVENTUR
  const [currentBaumplot, setCurrentBaumplot] = useState(undefined); // aktueller eingetragener Baumplot
  const [currentBaumplot2, setCurrentBaumplot2] = useState(undefined); // aktueller eingetragener Baumplot
  const [landmarkenData, setLandmarkenData] = useState(undefined); // Landmarkendata zum Einzeichnen in die Karte aus VORGÄNGERINVENTUR
  const [baumart, setBaumart] = useState(undefined); // ba - Baumart, s_ba.json
  const [hoehenstufe, setHoehenstufe] = useState(undefined); // hst - Hoehenstufe, s_hst.json
  const [indikatorpflanzen, setIndikatorpflanzen] = useState(undefined); // indikpfl - Indikatorpflanzen im Transekt, s_indikpfl.json
  const [transektVerkleinert, setTransektVerkleinert] = useState(false);
  const [triebverlust, setTriebverlust] = useState(undefined); // s_trverlu.json, durch was entstand Triebverlust
  const [triebverlustOrt, setTriebverlustOrt] = useState(undefined); // s_trverlwo.json, wo entstand Triebverlust
  // Bestandsbeschreibung
  const [betriebsart, setBetriebsart] = useState(undefined); // bea - Betriebsart, s_bea.json
  const [schichtigkeit, setSchichtigkeit] = useState(undefined); // bess - Schichtigkeit (einschichtig, zwei...), s_bess.json
  const [bodenvegetation, setBodenvegetation] = useState(undefined); //  bodenveggr - Detaillierte Angaben zur Bodenvegetation im Bestand, s_bodenveggr.json
  const [entstehungsart, setEntstehungsart] = useState(undefined); // entsart - Entstehungsart ???, s_entsart.json
  const [kronenschlussgrad, setKronenschlussgrad] = useState(undefined); // ksg - Kronenschlussgrad wird durch Ober- und Zwischenstandgebildet (Lichtdurchlässigkeit), s_ksg.json
  const [schicht, setSchicht] = useState(undefined); // s_schicht.json
  const [stoerung, setStoerung] = useState(undefined); // strg - Störungen erfassen z.B. Sturm, s_strg.json
  const [verteilung, setVerteilung] = useState(undefined); // vert - Verteilung Bestockung, s_vert_zuordb.json
  const [verteilungKraut, setVerteilungKraut] = useState(undefined); // vert - Verteilung Kraut, s_vert_zuordv.json
  const [altersstufen, setAltersstufen] = useState(undefined); // nas - natuerliche Altersstufen, s_nas.json
  const [heterogenitaetsgrad, setHeterogenitaetsgrad] = useState(undefined); // Heterogenitaetsgrad, s_heterogenitaetsgrad.json
  // kommt in mehreren Berreichen vor
  const [schutzmaßnahmen, setSchutzmaßnahmen] = useState(undefined); // sma - Schutzmaßnahmen z.B. Zaun, unter Transektanlage und Bestandsbeschreibung
  const [currentTab, setCurrentTab] = useState(undefined);
  const [currentSubTab, setCurrentSubTab] = useState(undefined);
  const [nextTabIndex, setNextTabIndex] = useState(1);
  // logische Prüfung
  const [formularBlockieren, setFormularBlockieren] = useState(false);
  const [formularBeenden, setFormularBeenden] = useState(false);
  const [showBaumplot1Alert, setShowBaumplot1Alert] = useState(false);
  const [showBaumplot2Alert, setShowBaumplot2Alert] = useState(false);
  const [spAufsucheCheck, setSpAufsucheCheck] = useState(false);
  const [showSPAufsucheAlert, setShowSPAufsucheAlert] = useState(false);
  const [baumplot1Check, setBaumplot1Check] = useState(false);
  const [baumplot1RowCountCheck, setBaumplot1RowCountCheck] = useState(false);
  const [baumplot1ZeroRowsCheck, setBaumplot1ZeroRowsCheck] = useState(false);
  const [baumplot2ZeroRowsCheck, setBaumplot2ZeroRowsCheck] = useState(false);
  const [baumplot1ZeroRows, setBaumplot1ZeroRows] = useState(false);
  const [baumplot2ZeroRows, setBaumplot2ZeroRows] = useState(false);
  const [baumplotLessThanFive, setBaumplotLessThanFive] = useState(false);
  const [baumplot2RowCountCheck, setBaumplot2RowCountCheck] = useState(false);
  const [baumplot2Check, setBaumplot2Check] = useState(false);
  const [baumplotLessThanFive2, setBaumplotLessThanFive2] = useState(false);
  const [spAufsucheNichtWald, setSpAufsucheNichtWald] = useState(false); // Resettet Dropdown bei Abbruch von Formular Beenden
  const [transektStoerung, setTransektStoerung] = useState(false);
  const [aufnahmeMoeglich, setAufnahmeMoeglich] = useState(false);
  const [transektinfoCheck, setTransektinfoCheck] = useState("undefined");
  const [verjuengungstransekCheck, setVerjuengungstransekCheck] =
    useState(false);
  const [verjuengungstransekZeroRow, setVerjuengungstransekZeroRow] =
    useState(false);
  const [verjuengungstransekZeroRowCheck, setVerjuengungstransekZeroRowCheck] =
    useState(false);
  const [showVerjuengungstransekAlert, setShowVerjuengungstransekAlert] =
    useState(false);
  const [showWeiserpflanzenAlert, setShowWeiserpflanzenAlert] = useState(false);
  const [weiserpflanzenCheck, setWeiserpflanzenCheck] = useState(false);
  const [weiserpflanzenKraut, setWeiserpflanzenKraut] = useState(false);
  const [weiserpflanzenGras, setWeiserpflanzenGras] = useState(false);
  const [weiserpflanzenBeerenstraeucher, setWeiserpflanzenBeerenstraeucher] =
    useState(false);
  const [weiserpflanzenGrossstraeucher, setWeiserpflanzenGrossstraeucher] =
    useState(false);
  const [nschicht, setNSchicht] = useState(undefined); // bess, aktuell eingetragener Wert muss für Kontrolle der Baumartenanteile in Bestockung gespeichert werden
  const [bestandsbeschreibungCheck, setBestandsbeschreibungCheck] =
    useState("undefined");
  const [showBestandsbeschreibungAlert, setShowBestandsbeschreibungAlert] =
    useState(false);
  const [bestockungCheck, setBestockungCheck] = useState(undefined);
  const [bestockungZeilenCheck, setBestockungZeilenCheck] = useState(undefined);
  const [showBestockungAlert, setShowBestockungAlert] = useState(false);
  const [startVerjuengungstransektCheck, setStartVerjuengungstransektCheck] =
    useState(false);
  const [showWeiterButton, setShowWeiterButton] = useState(false); //Verjuengungstransekt
  // Schichten
  const [schichtOMU, setSchichtOMU] = useState(undefined);
  const [schichtEin, setSchichtEin] = useState(undefined);
  const [schichtZwei, setSchichtZwei] = useState(undefined);
  const [schichtVerjuengung, setSchichtVerjuengung] = useState(undefined);
  const [schichtZweiU, setSchichtZweiU] = useState(undefined);
  const [schichtPlenter, setSchichtPlenter] = useState(undefined);
  const [schichtZweiUN, setSchichtZweiUN] = useState(undefined);
  // GNSS Daten angefordert
  const [gnssSPEinmessung, setGnssSPEinmessung] = useState(false);
  const [gnssMeasurementsDone, setGnssMeasurementsDone] = useState(0);
  const [averageGNSSLocation, setAverageGNSSLocation] = useState(undefined);
  const [openGnssPort, setOpenGnssPort] = useState(false);
  const [gnssLocation, setGnssLocation] = useState(undefined);
  const [gnssLocationProvider, setGnssLocationProvider] = useState(undefined);
  const [gnssAccuracy, setGnssAccuracy] = useState(undefined);
  const [portIsOpened, setPortIsOpened] = useState(false);
  const [gnssOption, setGnssOption] = useState(undefined);
  const [baudRate, setBaudRate] = useState(9600);

  const [updateSW, setUpdateSW] = useState(false);
  const [spInProgress, setSPinProgress] = useState(false);
  const [spClicked, setSPClicked] = useState(false);
  const [geom, setGeom] = useState(undefined);
  const [startEntry, setStartEntry] = useState(false);
  const [allEntriesHaveBeenSendToBackend, setAllEntriesHaveBeenSendToBackend] =
    useState(true);

  //Help
  const [showChangelog, setShowChangelog] = useState(false);
  const [showGPSHelp, setShowGPSHelp] = useState(false);
  const [showLocationSwitch, setShowLocationSwitch] = useState(false);
  const [showBearbeitungsstandSPsHelp, setShowBearbeitungsstandSPsHelp] =
    useState(false);
  const [showPWAAktualisierenHelp, setShowPWAAktualisierenHelp] =
    useState(false);

  const [spUploaded, setSPUolpaded] = useState([]);
  const [timestamp, setTimestamp] = useState(undefined);
  const [timeStampedLose, setTimeStampedLose] = useState(undefined);
  const [refreshSPs, setRefreshSPs] = useState(false);
  const [markInEditingLose, setMarkInEditingLose] = useState(false);


  const isServiceWorkerUpdated = useSelector((state) => state.serviceWorkerUpdated);

  useEffect(() => {
    let data = [
      [API_URL + "rettungspunkte_brb.geojson", setRettungspunkte],
      [API_URL + "rettungswege_brb.geojson", setRettungswege],
      //[API_URL + "g_wld.geojson", setWaldflaechen],
      //[API_URL + "g_lkrs_brb.geojson", setKreise],
      [API_URL + "s_ba.json", setBaumart],
      [API_URL + "s_bea.json", setBetriebsart],
      [API_URL + "s_begeh.json", setBegehbar],
      [API_URL + "s_bess.json", setSchichtigkeit],
      [API_URL + "s_biotop.json", setBiotop],
      [API_URL + "s_bodenveggr.json", setBodenvegetation],
      [API_URL + "s_entsart.json", setEntstehungsart],
      [API_URL + "s_hst.json", setHoehenstufe],
      [API_URL + "s_indikpfl.json", setIndikatorpflanzen],
      [API_URL + "s_ksg.json", setKronenschlussgrad],
      [API_URL + "s_nichtwald.json", setNichtwald],
      [API_URL + "s_perma.json", setPermamarker],
      [API_URL + "s_schicht.json", setSchicht],
      [API_URL + "s_sma.json", setSchutzmaßnahmen],
      [API_URL + "s_strg.json", setStoerung],
      [API_URL + "s_verlegg.json", setVerlegung],
      [API_URL + "s_vert_zuordb.json", setVerteilung],
      [API_URL + "s_vert_zuordv.json", setVerteilungKraut],
      //[API_URL + "v_los.geojson", setLose], //nun über Backend
      [API_URL + "s_nas.json", setAltersstufen],
      [API_URL + "s_heterogenitaetsgrad.json", setHeterogenitaetsgrad],
      [API_URL + "s_trverlu.json", setTriebverlust],
      [API_URL + "s_trverlwo.json", setTriebverlustOrt],
      [API_URL + "v_plot_baum.geojson", setBaumplottData],
      [API_URL + "v_plot_landmarke.geojson", setLandmarkenData],
    ];

    data.forEach((element) => {
      let file = element[0];
      let setter = element[1];
      fetch(file)
        .then((res) => res.json())
        .then((jsonData) => {
          setter(jsonData);
        });
    });
  }, []);

  const value = {
    //waldflaechen,
    //setWaldflaechen,
    rettungspunkte,
    setRettungspunkte,
    rettungswege,
    setRettungswege,
    //kreise,
    //setKreise,
    baumart,
    setBaumart,
    betriebsart,
    setBetriebsart,
    begehbar,
    setBegehbar,
    schichtigkeit,
    setSchichtigkeit,
    biotop,
    setBiotop,
    bodenvegetation,
    setBodenvegetation,
    entstehungsart,
    setEntstehungsart,
    baumplot2RowCountCheck,
    setBaumplot2RowCountCheck,
    hoehenstufe,
    setHoehenstufe,
    indikatorpflanzen,
    setIndikatorpflanzen,
    kronenschlussgrad,
    setKronenschlussgrad,
    nichtwald,
    setNichtwald,
    permamarker,
    setPermamarker,
    schicht,
    setSchicht,
    schutzmaßnahmen,
    setSchutzmaßnahmen,
    stoerung,
    setStoerung,
    verteilung,
    setVerteilung,
    verteilungKraut,
    setVerteilungKraut,
    verlegung,
    setVerlegung,
    lose,
    setLose,
    altersstufen,
    setAltersstufen,
    sortedHauptlose,
    setSortedHauptlose,
    sortedLose,
    setSortedLose,
    currentSP,
    setCurrentSP,
    currentTab,
    setCurrentTab,
    heterogenitaetsgrad,
    setTriebverlust,
    triebverlustOrt,
    setTriebverlustOrt,
    baumplottData,
    setBaumplottData,
    landmarkenData,
    setLandmarkenData,
    baumplotFeatures,
    setBaumplotFeatures,
    spAufsucheCheck,
    setSpAufsucheCheck,
    baumplot1Check,
    setBaumplot1Check,
    formularBeenden,
    setFormularBeenden,
    formularBlockieren,
    setFormularBlockieren,
    spAufsucheNichtWald,
    setSpAufsucheNichtWald,
    transektStoerung,
    setTransektStoerung,
    aufnahmeMoeglich,
    setAufnahmeMoeglich,
    baumplot2Check,
    setBaumplot2Check,
    nextTabIndex,
    setNextTabIndex,
    showBaumplot1Alert,
    setShowBaumplot1Alert,
    showBaumplot2Alert,
    setShowBaumplot2Alert,
    currentSubTab,
    setCurrentSubTab,
    baumplot1RowCountCheck,
    setBaumplot1RowCountCheck,
    baumplot2RowCountCheck,
    setBaumplot2RowCountCheck,
    showSPAufsucheAlert,
    setShowSPAufsucheAlert,
    verjuengungstransekCheck,
    setVerjuengungstransekCheck,
    showVerjuengungstransekAlert,
    setShowVerjuengungstransekAlert,
    baumplotLessThanFive,
    setBaumplotLessThanFive,
    baumplotLessThanFive2,
    setBaumplotLessThanFive2,
    transektVerkleinert,
    setTransektVerkleinert,
    showWeiserpflanzenAlert,
    setShowWeiserpflanzenAlert,
    weiserpflanzenCheck,
    setWeiserpflanzenCheck,
    weiserpflanzenKraut,
    setWeiserpflanzenKraut,
    weiserpflanzenGras,
    setWeiserpflanzenGras,
    weiserpflanzenBeerenstraeucher,
    setWeiserpflanzenBeerenstraeucher,
    weiserpflanzenGrossstraeucher,
    setWeiserpflanzenGrossstraeucher,
    baumplot1ZeroRowsCheck,
    setBaumplot1ZeroRowsCheck,
    baumplot2ZeroRowsCheck,
    setBaumplot2ZeroRowsCheck,
    baumplot1ZeroRows,
    setBaumplot1ZeroRows,
    baumplot2ZeroRows,
    setBaumplot2ZeroRows,
    verjuengungstransekZeroRow,
    setVerjuengungstransekZeroRow,
    verjuengungstransekZeroRowCheck,
    setVerjuengungstransekZeroRowCheck,
    nschicht,
    setNSchicht,
    bestockungCheck,
    setBestockungCheck,
    showBestockungAlert,
    setShowBestockungAlert,
    gnssSPEinmessung,
    setGnssSPEinmessung,
    gnssMeasurementsDone,
    setGnssMeasurementsDone,
    averageGNSSLocation,
    setAverageGNSSLocation,
    currentBaumplot,
    setCurrentBaumplot,
    currentBaumplot2,
    setCurrentBaumplot2,
    currentSecondSP,
    setCurrentSecondSP,
    openGnssPort,
    setOpenGnssPort,
    gnssLocationProvider,
    setGnssLocationProvider,
    gnssLocation,
    setGnssLocation,
    portIsOpened,
    setPortIsOpened,
    gnssOption,
    setGnssOption,
    bestandsbeschreibungCheck,
    setBestandsbeschreibungCheck,
    showBestandsbeschreibungAlert,
    setShowBestandsbeschreibungAlert,
    schichtOMU,
    setSchichtOMU,
    schichtEin,
    setSchichtEin,
    schichtZwei,
    setSchichtZwei,
    schichtVerjuengung,
    setSchichtVerjuengung,
    schichtZweiU,
    setSchichtZweiU,
    schichtPlenter,
    setSchichtPlenter,
    schichtZweiUN,
    setSchichtZweiUN,
    bestockungZeilenCheck,
    setBestockungZeilenCheck,
    baudRate,
    setBaudRate,
    currentAzimut,
    setCurrentAzimut,
    transektinfoCheck,
    setTransektinfoCheck,
    gnssAccuracy,
    setGnssAccuracy,
    isServiceWorkerUpdated,
    updateSW,
    setUpdateSW,
    spInProgress,
    setSPinProgress,
    spClicked,
    setSPClicked,
    geom,
    setGeom,
    startEntry,
    setStartEntry,
    allEntriesHaveBeenSendToBackend,
    setAllEntriesHaveBeenSendToBackend,
    startVerjuengungstransektCheck,
    setStartVerjuengungstransektCheck,
    showWeiterButton,
    setShowWeiterButton,
    spUploaded,
    setSPUolpaded,
    timestamp,
    setTimestamp,
    timeStampedLose,
    setTimeStampedLose,
    refreshSPs,
    setRefreshSPs,
    markInEditingLose,
    setMarkInEditingLose,
    showChangelog,
    setShowChangelog,
    showGPSHelp,
    setShowGPSHelp,
    showLocationSwitch,
    setShowLocationSwitch,
    showBearbeitungsstandSPsHelp,
    setShowBearbeitungsstandSPsHelp,
    showPWAAktualisierenHelp,
    setShowPWAAktualisierenHelp,
  };

  return <AppStateProvider value={value}>{children}</AppStateProvider>;
};

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppContext, AppContextProvider };

/*
Alerts Baumplot
*/
import React, { useContext, useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../AppContext";

function SPAufsucheAlert() {
  const appContext = useContext(AppContext);

  return (
    <>
      <Alert
        severity="warning"
        style={{
          flexWrap: "wrap",
          flexDirection: "row wrap",
          margin: "0",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          bottom: "40%",
          zIndex: "505",
        }}
      >
        <AlertTitle>
          Ihre Eingaben sind nicht vollständig.<br></br>
          <br></br>
          Bitte überprüfen Sie, ob die "Informationen zum Aufnahmetrupp"
          vollständig angegeben wurden.<br></br>
          <br></br>
          Zudem muss eine Einmessung erfolgt sein.
        </AlertTitle>
        <Button
          style={{ marginLeft: "10px" }}
          variant="outlined"
          onClick={() => {
            appContext.setShowSPAufsucheAlert(false);
            //appContext.setFormularBeenden(false);
            //appContext.setSpAufsucheNichtWald(true);
            //appContext.setTransektStoerung(true);
            //appContext.setAufnahmeMoeglich(true);
          }}
        >
          Ok
        </Button>
      </Alert>
    </>
  );
}
export default SPAufsucheAlert;

import React, { useState, useEffect, useContext } from "react";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { AppContext } from "../AppContext";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

export default function LocationSwitchHelp() {
  const appContext = useContext(AppContext);

  return (
    <Dialog open fullWidth maxWidth="lg" style={{ zIndex: 10011 }}>
      <Paper elevation={3}>
        <DialogTitle
          style={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            borderBottom: "1px solid black",
          }}
        >
          Standortdatenquelle auswählen
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "white" }}>
          <DialogContentText style={{ color: "black" }}>
            <br></br>
            <ul>
              <section>
                <b style={{ color: "red" }}>
                  WICHTIG! Für jegliche GPS/GNSS-Interaktion sollte dem Browser
                  Zugriff auf die Positionsdaten gewährt werden
                </b>
                <br></br>
                <p style={{ color: "red", display: "inline" }}>
                  {
                    " (oben rechts in der Adresszeile über das Positions-Symbol.)"
                  }
                </p>
              </section>
              <br></br>
              <br></br>
              <li>
                <b>
                  'Externe Standortdaten nutzen' aktiviert{" "}
                  {" (Schalter ist blau)"}
                </b>
              </li>
              <br></br>
              <ul>
                <li>
                  Mit dem Standortquellen-Schalter wird ausgewählt, welche
                  Positionsdaten für die im nächsten Punkt durchzuführende
                  Messung verwendet werden. Standardmäßig ist der Schalter
                  aktiviert und ist blau hervorgehoben.
                </li>
                <br></br>
                <li>
                  Befindet sich der Schalter in dieser Position werden zur
                  SP-Einmessung und der Messung bei der SP-Verschiebung
                  ausschließlich die Daten verwendet, die vom GNSS-Empfänger
                  kommen.
                </li>
                <br></br>
                <li>
                  Der Button 'Einmessung starten' ist in dieser Konfiguration
                  nur klickbar, wenn eine Verbindung zu einem externen
                  GNSS-Empfänger besteht.{" "}
                  {
                    " (Siehe Hilfe in der Navigation (In der Karte unten links))"
                  }
                </li>
                <br></br>
              </ul>
              <li>
                <b>
                  'Externe Standortdaten nutzen' deaktiviert{" "}
                  {" (Schalter ist grau)"}
                </b>
              </li>
              <br></br>
              <ul>
                <li>
                  Kann keine Verbindung zum GNSS-Gerät hergestellt werden, so
                  kann der Standortquellen-Schalter ausgeschalten werden.{" "}
                </li>
                <br></br>
                <li>
                  Ist dies der Fall, werden zur SP-Einmessung und der Messung
                  bei der SP-Verschiebung ausschließlich die Positionsdaten des
                  mobilen Gerätes verwendet.
                </li>
                <br></br>
                <li>
                  {" "}
                  Dadurch kann die Einmessung durchgeführt werden ohne eine
                  Verbindung zum GNSS-Empfänger herstellen zu müssen.
                </li>
              </ul>
            </ul>

            <br></br>
          </DialogContentText>
          <Button
            variant="contained"
            style={{
              height: "70%",
              marginTop: "10px",
              marginRight: "10px",
              width: "175px",
            }}
            onClick={() => {
              appContext.setShowLocationSwitch(false);
            }}
          >
            Zurück
          </Button>
        </DialogContent>
      </Paper>
    </Dialog>
  );
}

/* 
Tabelle ttransektinfo - erhobenen Grunddaten vom Transekt

In dieser Componente befinden sich Transektanlage und Transektinfo.
*/

import React, { useState, useContext, useEffect, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { AppContext } from "./AppContext";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Verjuengungstransekt from "./Verjuengungstransekt";
import Weiserpflanzen from "./Weiserpflanzen";
import { saveEntryToIDB, checkIfEntryExists } from "../swCommunication";
import emptyTransektAnlage from "../lib/emptyObjects/emptyTransektAnlage";
import emptyTransektinfo from "../lib/emptyObjects/emptyTransektinfo";
import DataFormCheck from "./logischePruefungFormular/DataFormCheck";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import sortArrayByProperty from "./util/sortArrayByProperty";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const channel = new BroadcastChannel("sw-messages");

export default function Transekt() {
  const appContext = useContext(AppContext);
  const [transektAnlage, setTransektAnlage] = useState(emptyTransektAnlage);
  const [transektInfo, setTransektInfo] = useState(emptyTransektinfo);
  const [saveAnlage, setSaveAnlage] = useState(false);
  const [saveInfo, setSaveInfo] = useState(false);

  useEffect(() => {
    if (saveAnlage) {
      saveEntryToIDB("transektanlage", transektAnlage, appContext.currentSP);
      setSaveAnlage(true);
    }
  }, [saveAnlage]);

  useEffect(() => {
    if (saveInfo) {
      saveEntryToIDB("transektInfo", transektInfo, appContext.currentSP);
      setSaveInfo(true);
    }
  }, [saveInfo]);

  useEffect(() => {
    if (
      appContext.currentSP !== undefined &&
      appContext.currentTab === "TRANSEKT" &&
      (appContext.currentSubTab === "TRANSEKTANLAGE" ||
        appContext.currentSubTab === "TRANSEKTINFO")
    ) {
      var checkExisting = {
        type: "GET_ENTRY_FROM_DB",
        id: appContext.currentSP.properties.los_id,
      };

      checkIfEntryExists(checkExisting);
    }
  }, [appContext.currentTab, appContext.currentSubTab, appContext.currentSP]);

  useEffect(() => {
    appContext.setTransektVerkleinert(false);
  }, [appContext.currentSP]);

  channel.onmessage = function (event) {
    if (
      event.data !== "no entry" &&
      appContext.currentTab === "TRANSEKT" &&
      (appContext.currentSubTab === "TRANSEKTANLAGE" ||
        appContext.currentSubTab === "TRANSEKTINFO") &&
      event.data?.properties?.los_id === appContext.currentSP.properties.los_id
    ) {
      if (typeof event.data.properties.transektanlage !== "undefined") {
        setTransektAnlage(event.data.properties.transektanlage);
      }
      if (typeof event.data.properties.transektInfo !== "undefined") {
        setTransektInfo(event.data.properties.transektInfo);
      }
    }
  };

  let schutzmaßnahmenArray = useMemo(() => {
    if (appContext.schutzmaßnahmen !== undefined) {
      let _existingLabels = [];
      let _schutz = [];
      Object.keys(appContext.schutzmaßnahmen).forEach(function (
        schutzmaßnahmenNummer,
        i
      ) {
        if (
          _existingLabels.indexOf(
            appContext.schutzmaßnahmen[schutzmaßnahmenNummer].smatxt.toString()
          ) !== -1 ||
          appContext.schutzmaßnahmen[schutzmaßnahmenNummer].smatxt ===
            "Angabe fehlt"
        ) {
          return;
        }
        _existingLabels.push(
          appContext.schutzmaßnahmen[schutzmaßnahmenNummer].smatxt.toString()
        );
        _schutz.push({
          label:
            appContext.schutzmaßnahmen[schutzmaßnahmenNummer].smatxt.toString(),
          id: appContext.schutzmaßnahmen[schutzmaßnahmenNummer].id_sma,
        });
      });
      sortArrayByProperty(_schutz, "id");
      return _schutz;
    }
  }, [appContext.schutzmaßnahmen]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    appContext.setCurrentSubTab(event.target.innerText);
    setValue(newValue);
  };

  const handleInputEvent = (objectEntry, value, form, setData) => {
    setData({
      ...form,
      [objectEntry]: value,
    });
  };

  useEffect(() => {
    if (appContext.currentSubTab === "TRANSEKTANLAGE") {
      setValue(0);
    }
    if (appContext.currentSubTab === "VERJÜNGUNGSTRANSEKT") {
      setValue(1);
    }
    if (appContext.currentSubTab === "WEISERPFLANZEN") {
      setValue(2);
    }
    if (appContext.currentSubTab === "TRANSEKTINFO") {
      setValue(3);
    }
  }, [appContext.currentSubTab]);

  useEffect(() => {
    if (appContext.transektStoerung === true) {
      setTransektAnlage({
        ...transektAnlage,
        transektStoerungUrsache: "",
      });
    }

    if (appContext.aufnahmeMoeglich) {
      setTransektAnlage({
        ...transektAnlage,
        transektGeschuetzt: false,
      });
      appContext.setAufnahmeMoeglich(false);
    }
    //saveEntryToIDB("spAufsuche", spAufsuche, appContext.currentSP);
  }, [appContext.transektStoerung, appContext.aufnahmeMoeglich]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab disabled label="Transektanlage" {...a11yProps(0)} />
          <Tab disabled label="Verjüngungstransekt" {...a11yProps(1)} />
          <Tab disabled label="Weiserpflanzen" {...a11yProps(2)} />
          <Tab disabled label="Transektinfo" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <br></br>
        Der Transekt kann <strong>wie gewohnt</strong> aufgenommen werden.
        <br></br>
        {/* <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, backgroundColor: "#f0f2f0" }}
            aria-label="simple table"
          >
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  Der angegebene <strong> Azimut </strong> des Transektes
                  beträgt: (toDo: hier anzeigen)
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer> */}
        <div
          style={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            margin: "10px 0px 0px 0px",
            alignItems: "left",
            justifyContent: "center",
          }}
        ></div>
        <font color="#0033cc ">
          <h4>ODER</h4>
        </font>
        Der Transekt <strong>muss verkleinert</strong> werden:
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
            aria-label="simple table"
          >
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <strong>Homogen </strong> - Über den ganzen Transekt besteht
                  sehr dichte Verjüngung, eine Verkleinerung der Aufnahmefläche
                  auf 2 m x 2 m erfolgt
                </TableCell>
                <TableCell align="left">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={transektAnlage.transektVerkleinert || false}
                          onChange={(event) => {
                            console.log(event.target.checked);
                            console.log(event);
                            if (event.target.checked === true) {
                              appContext.setTransektVerkleinert(true);
                            } else {
                              appContext.setTransektVerkleinert(false);
                            }
                            console.log(appContext.transektVerkleinert);
                            handleInputEvent(
                              "transektVerkleinert",
                              event.target.checked,
                              transektAnlage,
                              setTransektAnlage
                            );

                            setSaveAnlage(true);
                          }}
                        />
                      }
                      label="ja, die Aufnahmefläche wird verkleinert"
                    />
                  </FormGroup>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            margin: "10px 0px 0px 0px",
            alignItems: "left",
            justifyContent: "center",
          }}
        ></div>
        <font color="#0033cc ">
          <h4>ODER</h4>
        </font>
        Der Transekt kann <strong>nicht oder nur erschwert</strong> aufgenommen
        werden:
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
            aria-label="simple table"
          >
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <strong>Schutzmaßnahmen </strong> - Der ganze Transekt ist
                  geschützt
                </TableCell>
                <TableCell align="left">
                  <FormGroup>
                    <Autocomplete
                      onFocus={() => {
                        document.activeElement.blur();
                      }}
                      onChange={(event, value) => {
                        if (value !== null) {
                          setTransektAnlage({
                            ...transektAnlage,
                            schutzmaßnahmeID: value.id,
                            schutzmaßnahme: value.label,
                          });
                        } else {
                          setTransektAnlage({
                            ...transektAnlage,
                            schutzmaßnahmeID: "",
                            schutzmaßnahme: "",
                          });
                        }
                        setSaveAnlage(true);
                      }}
                      disablePortal
                      value={
                        schutzmaßnahmenArray.find(
                          (element) =>
                            element.id === transektAnlage.schutzmaßnahmeID
                        ) ?? null
                      }
                      options={schutzmaßnahmenArray}
                      getOptionLabel={(option) => option.label || ""}
                      sx={{
                        width: "100%",
                        "& button[title='Clear']": {
                          visibility: "visible",
                        },
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.label}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Schutzmaßnahme" />
                      )}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={transektAnlage.transektGeschuetzt}
                          onChange={(event) => {
                            handleInputEvent(
                              "transektGeschuetzt",
                              !transektAnlage.transektGeschuetzt,
                              transektAnlage,
                              setTransektAnlage
                            );

                            setSaveAnlage(true);
                            appContext.setFormularBeenden(true);
                          }}
                        />
                      }
                      label="eine Aufnahme ist nicht möglich"
                    />
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <strong>Temporäre Störung </strong> - Es liegt eine Störung
                  vor, die eine Aufnahme unmöglich macht (z.B. Totholz)
                </TableCell>
                <TableCell align="left">
                  <TextField
                    sx={{ width: "100%" }}
                    id="tempstoerung"
                    label="Ursache:"
                    value={transektAnlage.transektStoerungUrsache}
                    onSelect={() => {
                      appContext.setTransektStoerung(false);
                    }}
                    onBlur={() => {
                      saveEntryToIDB(
                        "transektanlage",
                        transektAnlage,
                        appContext.currentSP
                      );
                      appContext.setFormularBeenden(true);
                    }}
                    onChange={(event) => {
                      handleInputEvent(
                        "transektStoerungUrsache",
                        event.target.value,
                        transektAnlage,
                        setTransektAnlage
                      );
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            margin: "10px 0px 0px 0px",
            alignItems: "left",
            justifyContent: "center",
          }}
        ></div>
        <div>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={() => {
              saveEntryToIDB(
                "transektanlage",
                transektAnlage,
                appContext.currentSP
              );
              appContext.setNextTabIndex(2);
              appContext.setCurrentTab("BAUMPLOT");
              appContext.setCurrentSubTab("BAUMPLOT 2");
            }}
          >
            Zurück
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={() => {
              saveEntryToIDB(
                "transektanlage",
                transektAnlage,
                appContext.currentSP
              );
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={() => {
              saveEntryToIDB(
                "transektanlage",
                transektAnlage,
                appContext.currentSP
              );
              appContext.setCurrentSubTab("VERJÜNGUNGSTRANSEKT");
            }}
          >
            Weiter
          </Button>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Der Verjüngungstransekt dient der{" "}
        <strong>Erfassung aller in ihm vorkommenden jungen Bäume</strong> und
        ihres Zustandes.
        <Verjuengungstransekt></Verjuengungstransekt>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <strong>Weiserpflanzen im Transekt</strong>
        <Weiserpflanzen></Weiserpflanzen>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <DataFormCheck
          checkData={{ transektinfo: transektInfo }}
        ></DataFormCheck>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: "98%", backgroundColor: "#f0f2f0" }}
            aria-label="simple table"
          >
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <strong>Signifikanter Fraß </strong> - Durch eines dieser
                  Tiere:
                </TableCell>
                <TableCell align="left">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={transektInfo.transektFrassHase || false}
                          onChange={(event) => {
                            handleInputEvent(
                              "transektFrassHase",
                              !transektInfo.transektFrassHase,
                              transektInfo,
                              setTransektInfo
                            );

                            setSaveInfo(true);
                          }}
                        />
                      }
                      label="Hase"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={transektInfo.transektFrassMaus || false}
                          onChange={(event) => {
                            handleInputEvent(
                              "transektFrassMaus",
                              !transektInfo.transektFrassMaus,
                              transektInfo,
                              setTransektInfo
                            );

                            setSaveInfo(true);
                          }}
                        />
                      }
                      label="Maus"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={transektInfo.transektFrassBieber || false}
                          onChange={(event) => {
                            handleInputEvent(
                              "transektFrassBieber",
                              !transektInfo.transektFrassBieber,
                              transektInfo,
                              setTransektInfo
                            );

                            setSaveInfo(true);
                          }}
                        />
                      }
                      label="Biber"
                    />
                  </FormGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <strong>Krautanteil </strong> - Wie viel Prozent des
                  Transektes ist mit Bodenpflanzen bedeckt? (Nicht die Summe der
                  Weiserpflanzen.)
                </TableCell>
                <TableCell align="left">
                  <TextField
                    id="krautanteil"
                    label="Krautanteil [%]"
                    error={
                      !appContext.transektinfoCheck &&
                      isNaN(transektInfo.transektKrautanteil) === true
                    }
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={String(transektInfo.transektKrautanteil)}
                    onBlur={(event) => {
                      saveEntryToIDB(
                        "transektInfo",
                        transektInfo,
                        appContext.currentSP
                      );
                    }}
                    onChange={(event) => {
                      const min = 0;
                      const max = 100;

                      var value = Number(event.target.value);

                      if (value.toString()[0] === "0") {
                        value = value.toString(8);
                      }
                      value = String(value);

                      if (value.length >= 3 && value > max) {
                        value = value.substring(0, 2);
                      } else if (value > max) {
                        value = max;
                      } else if (value < min) value = min;

                      setTransektInfo({
                        ...transektInfo,
                        transektKrautanteil: value,
                      });
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            margin: "10px 0px 0px 0px",
            alignItems: "left",
            justifyContent: "center",
          }}
        />
        {(typeof appContext.transektinfoCheck === "undefined" ||
          appContext.transektinfoCheck) && <br></br>}
        {!appContext.transektinfoCheck && (
          <p
            style={{
              color: "red",
            }}
          >
            Fehlende Eingaben sind rot makiert.
          </p>
        )}
        <div>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={() => {
              saveEntryToIDB(
                "transektInfo",
                transektInfo,
                appContext.currentSP
              );
              appContext.setCurrentSubTab("WEISERPFLANZEN");
            }}
          >
            Zurück
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={() => {
              saveEntryToIDB(
                "transektInfo",
                transektInfo,
                appContext.currentSP
              );
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: "10px", width: "100px" }}
            onClick={() => {
              saveEntryToIDB(
                "transektInfo",
                transektInfo,
                appContext.currentSP
              );
              if (appContext.transektinfoCheck === true) {
                appContext.setNextTabIndex(4);
                appContext.setCurrentTab("BESTANDSBESCHREIBUNGEN");
                appContext.setCurrentSubTab("ALLG. INFORMATIONEN");
              } else {
                appContext.setTransektinfoCheck(false);
              }
            }}
          >
            Weiter
          </Button>
        </div>
      </TabPanel>
    </>
  );
}

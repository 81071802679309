let emptyBestandsbeschreibungen = {
  bestandBetriebsart: "",
  bestandBetriebsartID: "",
  bestandKronenschlussgrad: "",
  bestandKronenschlussgradID: "",
  bestandSchutzmassnahmen: "",
  bestandSchutzmassnahmenID: "",
  bestandNschichtig: "",
  bestandNschichtigID: "",
  bestandBiotop: "",
  bestandBiotopID: "",
  bestandBedeckungsgradUnterstand: NaN,
  bestandBedeckungsgradGraeser: NaN,
  bestandHeterogenitaetsgrad: "",
  bestandHeterogenitaetsgradID: "",
};
export default emptyBestandsbeschreibungen;

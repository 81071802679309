import React, { useContext, useEffect } from "react";
import { AppContext } from "./AppContext";

/* 
In dieser Componente werden die Unterlose und SPs zum jeweiligen "Haupt"-Los zugeordnet und als js-Objekt ausgegeben.
*/

export default function Lose() {
  const appContext = useContext(AppContext);

  useEffect(() => {
    // es gibt dieses Jahr 4 "Haupt"-Lose
    let hauptlose = []; // "Haupt"-lose

    if (appContext.lose !== undefined) {
      for (let i = 0; i < appContext.lose.features.length; i++) {
        let hauptlos = appContext.lose.features[i].properties.losnr;
        hauptlose.push(hauptlos);
      }

      // sortieren und Duplikate löschen
      const uniqueArray = (value, index, self) => {
        return self.indexOf(value) === index;
      };

      appContext.setSortedHauptlose(hauptlose.filter(uniqueArray).sort()); // muss noch "richtig" sortiert werden
    }
  }, [appContext.lose]);

  useEffect(() => {
    let spPunkt = {};
    let spPunktTemp = {};
    let unterlos = {};
    let hauptlos = {};
    if (typeof appContext.sortedHauptlose !== "undefined") {
      appContext.sortedHauptlose.forEach(function (valueLose) {
        appContext.lose.features.forEach(function (value, i) {
          spPunkt[value.properties.sp_schluessel] = value;
          if (value.properties.losnr === valueLose) {
            unterlos[value.properties.unterlosnr] = spPunktTemp;
            hauptlos[valueLose] = unterlos;
          }
        });
      });

      Object.keys(unterlos).forEach(function (key) {
        Object.keys(spPunkt).forEach(function (sp) {
          if (
            typeof spPunkt[sp].properties.unterlosnr === "undefined" ||
            spPunkt[sp].properties.unterlosnr === null
          ) {
            spPunkt[sp].properties.unterlosnr = 1;
          }
          if (spPunkt[sp].properties.unterlosnr.toString() === key.toString()) {
            spPunktTemp[spPunkt[sp].properties.sp_schluessel] = spPunkt[sp];
          }
        });
        unterlos[key] = spPunktTemp;
        spPunktTemp = {};
      });
    }

    appContext.setSortedLose(hauptlos);
  }, [appContext.sortedHauptlose]);

  return <></>;
}

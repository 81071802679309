import React, { useState, useContext, useEffect } from "react";
import Baumplot from "./Baumplot";
import Transekt from "./Transekt";
import Bestandsbeschreibung from "./Bestandsbeschreibung";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { AppContext } from "./AppContext";
import SPAufsuche from "./SPAufsuche";
import Status from "./Status";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DataForm(props) {
  //console.log(appContext.currentSP); // soll Fehler verursachen, zum testen von https://sentry.io/, verursacht Blackscreen beim Öffnen des Formulars
  const appContext = useContext(AppContext);
  const [isMinimized, setIsMinimized] = useState(false);
  const [value, setValue] = React.useState(1);

  const [currentHauptLos, setCurrentHauptLos] = useState("undefined");
  const [currentUnterLos, setCurrentUnterLos] = useState("undefined");
  const [currentSp, setCurrentSp] = useState("SP");
  const [cancelClicked, setCancelClicked] = useState(false);

  const tabArray = [
    "",
    "SP-AUFSUCHE",
    "BAUMPLOT",
    "TRANSEKT",
    "BESTANDSBESCHREIBUNGEN",
    "STATUS",
  ];

  useEffect(() => {
    setCurrentHauptLos(appContext.currentSP.properties.losnr);
    setCurrentUnterLos(appContext.currentSP.properties.unterlosnr);
    setCurrentSp(appContext.currentSP.properties.los_id);
    appContext.setFormularBlockieren(false);
    appContext.setCurrentSubTab("");
  }, [appContext.currentSP]);

  useEffect(() => {
    if (appContext.nextTabIndex > 0) {
      appContext.setCurrentTab(tabArray[appContext.nextTabIndex]);
      setValue(appContext.nextTabIndex);
    }
  }, [appContext.nextTabIndex]);

  const toggleMinMax = () => {
    setIsMinimized(!isMinimized);
  };

  useEffect(() => {
    setIsMinimized(false);
  }, [appContext.startEntry]);

  const handleChange = (event, newValue) => {
    switch (event.target.innerText) {
      case "BAUMPLOT":
        appContext.setCurrentSubTab("BAUMPLOT 1");
        break;
      case "TRANSEKT":
        appContext.setCurrentSubTab("TRANSEKTANLAGE");
        break;
      case "BESTANDSBESCHREIBUNGEN":
        appContext.setCurrentSubTab("ALLG. INFORMATIONEN");
        break;
    }
    console.log(newValue);
    appContext.setNextTabIndex(newValue);
  };

  const [dataFormClose, setDataFormClose] = useState(false);

  const handleDataFormClose = () => {
    setDataFormClose(true);
    props.handleToggle();
  };

  const tabStyle = { display: isMinimized ? "none" : "inline-flex" };

  return (
    <div
      style={{
        display: dataFormClose ? "none" : "table",
        top: 0,
        position: "absolute",
        zIndex: 10000,
        height: isMinimized ? "0%" : "100%",
        width: isMinimized ? "0%" : "100%",
        right: "0",
      }}
    >
      {cancelClicked && (
        <Dialog open>
          <DialogTitle
            style={{
              backgroundColor: "gray",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Wollen Sie die Eingabe abbrechen?
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "gray" }}>
            <DialogContentText style={{ color: "white" }}>
              Wollen Sie die Eingabe für SP{" "}
              {appContext.currentSP.properties.sp_schluessel} wirklich
              abbrechen? <br></br>
            </DialogContentText>
            <p style={{ fontSize: "12px", color: "white" }}>
              (Bisher eingegebene Daten gehen dadurch NICHT verloren.)
            </p>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                style={{
                  height: "70%",
                  marginTop: "10px",
                  marginRight: "10px",
                  width: "175px",
                }}
                onClick={() => {
                  setCancelClicked(false);
                }}
              >
                Zurück
              </Button>
              <Button
                variant="contained"
                style={{
                  height: "70%",
                  marginTop: "10px",
                  marginRight: "10px",
                  width: "175px",
                }}
                onClick={() => {
                  setCancelClicked(false);
                  appContext.setSPinProgress(false);
                  appContext.setNextTabIndex(5);
                }}
              >
                Bestätigen
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
      <Box
        sx={{
          height: isMinimized ? "0%" : "100%",
          width: isMinimized ? "0%" : "100%",
        }}
        style={{
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          margin: 0,
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        <Paper
          elevation={5}
          sx={{
            height: isMinimized ? "0%" : "98%",
            width: isMinimized ? "0%" : "98%",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              style={
                {
                  /*visibility: isMinimized ? "hidden" : "visible" */
                }
              }
            >
              <Tab
                style={tabStyle}
                label={
                  <div>
                    <Typography align="left" variant="Hauptlos">
                      <strong>
                        Losnr.: {currentHauptLos}, UL: {currentUnterLos}
                      </strong>
                    </Typography>

                    <Typography align="left" variant="SP">
                      <pre>
                        <strong>
                          SP: {appContext.currentSP.properties.sp_schluessel}
                        </strong>
                      </pre>
                    </Typography>
                  </div>
                }
                disabled
                {...a11yProps(0)}
              />
              <Tab
                style={tabStyle}
                label="SP-Aufsuche"
                {...a11yProps(1)}
                disabled
                //disabled={appContext.formularBlockieren}
              />
              <Tab
                style={tabStyle}
                label="Baumplot"
                {...a11yProps(2)}
                disabled
                //disabled={appContext.formularBlockieren}
              />
              <Tab
                style={tabStyle}
                label="Transekt"
                {...a11yProps(3)}
                disabled
                //disabled={appContext.formularBlockieren}
              />
              <Tab
                //disabled={appContext.formularBlockieren}
                style={tabStyle}
                label="Bestandsbeschreibungen"
                {...a11yProps(4)}
                disabled
              />
              <Tab
                //disabled={!appContext.formularBlockieren}
                style={tabStyle}
                label="Status"
                {...a11yProps(5)}
                disabled
              />
            </Tabs>
            <div
              style={{
                marginLeft: "auto",
              }}
            >
              {!isMinimized && (
                <Button
                  style={{ height: "70%", margin: "10px" }}
                  onClick={toggleMinMax}
                  variant="contained"
                  size="small"
                >
                  Karte anzeigen
                </Button>
              )}
              {/*!isMinimized && (
                <IconButton onClick={handleDataFormClose}>
                  <ClearIcon></ClearIcon>
                </IconButton>
              )*/}
            </div>
          </Box>
          <div style={{ display: isMinimized ? "none" : "block" }}>
            <TabPanel value={value} index={0}></TabPanel>
            <TabPanel value={value} index={1}>
              <SPAufsuche />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Baumplot />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Transekt />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Bestandsbeschreibung />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Status></Status>
            </TabPanel>
          </div>
        </Paper>
      </Box>
      {appContext.spInProgress && (
        <Button
          color="error"
          style={{
            height: "70%",
            marginTop: "10px",
            // marginTop: appContext.spInProgress ? "10px" : "60px",
            marginRight: "10px",
            width: "175px",
            display: isMinimized ? "block" : "none",
          }}
          onClick={() => {
            setCancelClicked(true);
          }}
          variant="contained"
          size="small"
        >
          Eingabe abbrechen
        </Button>
      )}
      {appContext.spInProgress && (
        <Button
          style={{
            height: "70%",
            marginTop: "10px",
            marginRight: "10px",
            width: "175px",
            display: isMinimized ? "block" : "none",
          }}
          onClick={() => {
            toggleMinMax();
            appContext.setSPinProgress(true);
          }}
          variant="contained"
          size="small"
        >
          Eingabe fortsetzen
        </Button>
      )}
    </div>
  );
}
